@import "~styles/colors";

.calendar {
	width: 234px;
	padding: 0 16px 8px;
	user-select: none;
}

.month {
	height: 48px;

	& > span {
		font-size: 15px;
		text-transform: capitalize;
	}

	md-icon {
		margin: 0;

		&:hover {
			background: transparentize($grey, 0.85);
			cursor: pointer;
		}
	}
}

.week-days {
	font-size: 13px;
	height: 24px;

	& > div {
		width: 24px;
		height: 24px;
		margin: 8px;
		opacity: 0.4;
	}
}

.day {
	margin: 4px 0;

	& > div {
		width: 24px;
		height: 24px;
		min-height: 24px;
		min-width: 24px;
		font-size: 14px;
		border-radius: 24px;
	}

	&.single-active {
		& > div {
			background: var(--menu-color-01);
		}
	}

	&.active {
		background: var(--menu-color-01);

		&.start:not(.end) {
			border-radius: 24px 0 0 24px;
		}

		&.end:not(.start) {
			border-radius: 0 24px 24px 0;
		}

		&.start.end {
			background: none;
			> div {
				background: var(--menu-color-01);
			}
		}
	}

	&:hover {
		cursor: pointer;

		& > div {
			background: var(--menu-color-01);
		}
	}

	&.not-current-month {
		color: transparentize($black, 0.6);
	}

	&.today {
		& > div {
			color: var(--link-color);
			border: 1px solid var(--link-color);
		}

		&.active {
			& > div {
				background: $white;
			}
		}
	}
}

.reset {
	color: #666;
	font-size: 12px;
	border-bottom: 1px solid rgba(102, 102, 102, 0.2);
	line-height: 16px;

	&:hover {
		cursor: pointer;
	}
}
