.ezd-accordion .header {
  cursor: pointer;
  position: relative;
}
.ezd-accordion .header .expand-column {
  position: absolute;
  top: 9px;
  left: 3px;
}
.ezd-accordion .header .expand-column .expand-icon {
  transition: transform 0.3s ease;
}
.ezd-accordion .header .expand-column .expand-icon md-icon {
  color: #666;
}
.ezd-accordion .header .expand-column .expand-icon md-icon svg {
  fill: #666;
}
.ezd-accordion .content {
  position: relative;
  overflow: hidden;
  height: 0px;
}
.ezd-accordion.opened .content {
  height: auto;
}
.ezd-accordion.opened .expand-icon {
  transform: rotate(180deg);
}