@import "~styles/colors";

.support-form-open-link {
	color: #fff;
	font-size: 12px;
	display: block;
	text-align: center;
	cursor: pointer;
	margin: 16px 0;
}

.black-text {
	color: $black;
}
