@import "~styles/colors";
$arrow-btns-color: #95b1be;

.old_date {
  height: 159px;
  background: var(--side-bar-color1);
  position: relative;

  & span:nth-of-type(1) {
	color: #fff;
	font-size: 46px;
	line-height: 44px;
	font-weight: bold;
	margin-top: 42px;
	margin-bottom: 23px;
  }

  & span:nth-of-type(2) {
	text-transform: capitalize;
	color: var(--font-color2);
	font-size: 16px;
	font-weight: 400;
	text-align: center;
  }

  & span:nth-of-type(3) {
	text-transform: capitalize;
	color: #fff;
	font-size: 15px;
	font-weight: 400;
	text-align: center;
  }

  .go_back__btn,
  .menu-closer {
	position: absolute;
	top: 10px;
	cursor: pointer;

	&:active, &:focus {
	  outline: none;
	}

	md-icon {
	  min-height: 0;
	  min-width: 0;
	  width: 20px;
	  height: 20px;
	  color: $arrow-btns-color;
	}
  }

  .go_back__btn {
	left: 5px;
  }

  .menu-closer {
	right: 5px;
  }

  .menu-closer {
	position: absolute;
	top: 10px;
	right: 5px;
	cursor: pointer;

	&:active, &:focus {
	  outline: none;
	}

	md-icon {
	  min-height: 0;
	  min-width: 0;
	  width: 20px;
	  height: 20px;
	  color: #95b1be;
	}

  }
}

:global(.closed-menu) {
  .old_date {
	height: 159px;
	background: #4c626c;
	position: relative;

	& span:nth-of-type(1) {

	}

	& span:nth-of-type(2) {
	  font-size: 11px;
	}

	& span:nth-of-type(3) {

	}
  }
}

.current-date {
  width: 128px;
  color: $white;
	background: var(--side-bar-color1);
  padding: 16px 8px;

  .date {
	font-size: 48px;
	font-weight:500;
	line-height: 48px;
  }
  .month-and-day {
	font-size: 14px;
	font-weight:400;
  }
}
