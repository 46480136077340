@import "~styles/colors";

.ezd-multiple-select {
	//width: 400px;

	.ezd-multiple-select__search {
		position: relative;
		width: 100%;

		& > label {
			position: absolute;
			top: 50%;
			left: 12px;
			display: block;
			width: 16px;
			height: 16px;
			background-image: url('./icons/serch_icon.svg');
			background-repeat: no-repeat;
			transform: translateY(-50%);
		}

		input:global(.ezd-input) {
			width: 100%;
			padding-left: 40px;
		}
	}

	.ezd-multiple-select__list-wrapper {
		//height: 330px;
		max-height: 610px;
		min-height: 250px;
		margin-top: -1px;
		padding: 16px;
		border: 1px solid $grey-border;
		border-top: none;
		border-bottom-left-radius: 2px;
		border-bottom-right-radius: 2px;

		.ezd-multiple-select__list {
			height: 100%;

			.repeated-item {
				box-sizing: border-box;
				margin-bottom: 10px;
			}

			&::-webkit-scrollbar {
				width: 5px;
				border-radius: 2px;
			}

			ezd-checkbox {
				padding: 0;

				& > div {
					display: block;

					input[type=checkbox] + label span {
						white-space: nowrap;
						//max-width: calc(100% - 100px);
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}

				&:not(:first-child) > div {
					margin-top: 8px;
				}

				& label {
					font-size: 12px;
				}
			}

		}

	}
}

.check-all {
	padding-left: 17px;
	padding-top: 8px;
	padding-right: 17px;
	margin-bottom: -40px;
	z-index: 1;

	ezd-checkbox {
		padding: 0;
		font-size: 12px;
	}
}
