.dots-menu {
  padding: 0 6px;
  border: 1px solid rgba(153, 153, 153, 0.4);
  border-radius: 3px;
}
.dots-menu:hover {
  cursor: pointer;
}

.menu-content {
  min-height: 72px;
}

.menu-item {
  min-height: 24px;
  height: 33px;
}
.menu-item .script-link {
  color: #333;
  text-decoration: none;
}

.last-menu-item {
  margin-top: 2px;
  border-top: 1px solid #999;
}
.last-menu-item .delete-script-btn {
  text-align: center;
}