@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.wrapper {
  padding: 20px;
  border-radius: 2px;
  background-color: #f7f7f7;
  box-shadow: inset 0 1px 2px 0 rgba(57, 103, 109, 0.1);
  border: dashed 1px #e6e6e6;
  cursor: pointer;
}
.wrapper:hover, .wrapper.dragover {
  background-color: #f2f2f2;
}
.wrapper .message {
  color: #666;
}
.wrapper .message md-icon {
  min-height: initial !important;
  min-width: initial !important;
  width: 11px !important;
  height: 18px !important;
  margin-right: 11px;
}
.wrapper .size-label {
  color: #666;
  font-size: 12px;
}