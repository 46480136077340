@import "~styles/colors";
@import "~styles/mixins";

.wrapper {
	padding: 20px;
	border-radius: 2px;
	background-color: $super-light-grey;
	box-shadow: inset 0 1px 2px 0 rgba(57, 103, 109, 0.1);
	border: dashed 1px $light-border;
	cursor: pointer;

	&:hover,
	&.dragover {
		background-color: adjust_color($super-light-grey, $red: -5, $green: -5, $blue: -5);
	}

	.message {
		color: $grey;

		md-icon {
			@include  resetMinWidthHeight();
			@include  rectSize(11px, 18px);
			margin-right: 11px;
		}
	}

	.size-label {
		color: $grey;
		font-size: 12px;
	}
}
