input[type=radio]:global(.ezd-radio-buttons-item) {
  display: none;
}
input[type=radio]:global(.ezd-radio-buttons-item) + label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
  color: #333;
  cursor: pointer;
  line-height: 1.13;
  user-select: none;
}
input[type=radio]:global(.ezd-radio-buttons-item) + label::before {
  content: "";
  background: url("../icons/radio_button_unchecked.svg") no-repeat;
  margin-right: 8px;
  width: 1em;
  height: 1em;
  min-height: 1em;
  min-width: 1em;
}
input[type=radio]:global(.ezd-radio-buttons-item):checked + label::before {
  background: url("../icons/radio_button_checked.svg") no-repeat;
  filter: hue-rotate(var(--icon-hue-rotate));
}
input[type=radio]:global(.ezd-radio-buttons-item):disabled + label {
  opacity: 0.5;
}