@import "~styles/colors";
@import "~styles/mixins";

.ezd-confirm {
	width: 400px;

	header {
		padding: 5px 13px;

		span {
			color: $grey;
		}

		md-icon {
			color: $grey;
			cursor: pointer;
			margin: 0;

			& > svg {
				fill: $grey;
			}
		}
	}

	main {
		.main-icon {
			padding: 35px;

			img {
				max-height: 100px;
				max-width: 100px;
				filter: hue-rotate(var(--icon-hue-rotate));
			}
		}

		.main-header {
			padding: 0 56px;
			padding-bottom: 15px;
			font-size: 24px;
			text-align: center;
		}

		.main-text {
			padding: 0 56px;
			text-align: center;

			.admin-row {
				display: flex;
				text-align: left;
				padding-top: 15px;
			}
		}

		.controls {
			padding: 32px 0;

			:global(.md-button).confirm {
				@include ezd-button($orange);
				color: $white;
				min-height: 32px;
				height: 32px;
				line-height: 32px;
				font-size: 14px;
			}

			:global(.md-button).reject {
				@include ezd-button($white);
				color: $black;
				min-height: 32px;
				height: 32px;
				line-height: 32px;
				font-size: 14px;
				box-shadow: none;
			}

			.btn-yellow {
				width: 100%;
				display: inline-block;

				label, span {
					width: 100%;
					display: inline-block;
				}
			}
		}
	}
}
