label.upload-link__wrapper {
  display: inline-block;
  cursor: pointer;
}

input[type=file].upload-link {
  display: none;
}

.upload-error {
	color: red;
	min-width: max-content;
	pointer-events: none;
	height: 0px;
}
