md-slider.ezd-range :global(.md-slider-wrapper) {
  position: relative;
  height: 54px;
  cursor: pointer;
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-track-container) {
  height: 1px;
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-track-container) :global(.md-track) {
  background-color: #b3b3b3;
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-track-container) :global(.md-track):global(.md-track-fill) {
  display: none;
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-thumb-container) {
  z-index: 10;
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-thumb-container) :global(.md-thumb) {
  transform: none !important;
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-thumb-container) :global(.md-thumb)::after {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-thumb-container) :global(.md-thumb):hover {
  transform: none !important;
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-thumb-container) :global(.md-thumb):hover::after {
  border-color: var(--menu-color);
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-thumb-container) :global(.md-focus-ring) {
  display: none;
  transform: none !important;
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-thumb-container) .thumb-input {
  position: absolute;
  top: -26px;
  left: 50%;
  display: block;
  width: 40px;
  height: 24px;
  color: #333;
  user-select: none;
  transform: translateX(-55%);
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-thumb-container) .thumb-input * {
  user-select: none;
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-thumb-container) .thumb-input > span {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-thumb-container) .thumb-input > input[type=number] {
  width: 100%;
  height: 100%;
  padding: 0 2px 0 6px;
  font-size: 14px;
  position: relative;
  top: -2px;
}
md-slider.ezd-range :global(.md-slider-wrapper) :global(.md-thumb-container) .wide-type > input[type=number] {
  width: 60px;
  margin-left: -8px;
}
md-slider.ezd-range:not([md-discrete]):not([disabled]):global(.md-active) :global(.md-slider-wrapper) :global(.md-thumb-container) :global(.md-thumb) {
  transform: none !important;
}
md-slider.ezd-range:not([md-discrete]):not([disabled]):global(.md-active) :global(.md-slider-wrapper) :global(.md-thumb-container) :global(.md-thumb)::after {
  border-color: var(--menu-color);
}
md-slider.ezd-range:not([md-discrete]):not([disabled]) :global(.md-slider-wrapper):global(.md-focused) :global(.md-focus-ring) {
  display: none;
  transform: none !important;
}
md-slider.ezd-range .min-value,
md-slider.ezd-range .max-value {
  position: absolute;
  bottom: 0;
  display: block;
  color: #333;
  line-height: 1;
  user-select: none;
}
md-slider.ezd-range .min-value {
  left: 0;
}
md-slider.ezd-range .max-value {
  right: 0;
}