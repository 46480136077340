@import "~styles/colors";

input[type=radio]:global(.ezd-radio-buttons-item) {
	display: none;

	& + label {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 32px;
		color: $black;
		cursor: pointer;
		line-height: 1.13;
		user-select: none;

		&::before {
			content: "";
			background: url("../icons/radio_button_unchecked.svg") no-repeat;
			margin-right: 8px;
			width: 1em;
			height: 1em;
			min-height: 1em;
			min-width: 1em;
		}
	}

	&:checked + label::before {
		background: url("../icons/radio_button_checked.svg") no-repeat;
		filter: hue-rotate(var(--icon-hue-rotate));
	}

	&:disabled + label {
		opacity: 0.5;
	}
}
