.checkbox {
  display: inline-block;
}
.checkbox input[type=checkbox] {
  display: none;
}
.checkbox input[type=checkbox] + label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
}
.checkbox input[type=checkbox] + label span {
  width: auto;
  height: auto;
  background: none;
}
.checkbox input[type=checkbox] + label::before {
  content: "";
  background-image: linear-gradient(to bottom, #ffffff, #f3f8fb);
  border: solid 1px #ccd6da;
  border-radius: 2px;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  min-height: calc(1em + 2px);
  min-width: calc(1em + 2px);
  margin-right: 8px;
}
.checkbox input[type=checkbox] + label:hover::before {
  box-shadow: 0 0 0 2px rgba(0, 174, 197, 0.1);
}
.checkbox input[type=checkbox]:checked + label::before {
  background: var(--color-button-5) url("./check.svg") no-repeat center;
}
.checkbox input[type=checkbox]:checked + label span {
  background: none;
}
.checkbox input[type=checkbox]:disabled + label span {
  opacity: 0.5;
}
.checkbox input[type=checkbox]:disabled + label:hover::before {
  box-shadow: none;
}
.checkbox input[type=checkbox]:not(:checked):disabled + label::before {
  background: #e8e8e8;
  border: solid 1px #ccd6da;
}
.checkbox input[type=checkbox]:checked:disabled + label::before {
  opacity: 0.5;
}
.checkbox input[type=checkbox]:invalid + label::before {
  background-image: linear-gradient(to bottom, #ffffff, #f3f8fb);
  border: solid 1px #ff501d;
}

.checkbox:global(.small) input[type=checkbox] + label {
  font-size: 12px;
  color: #666;
}
.checkbox:global(.small) input[type=checkbox] + label::before {
  width: 13px;
  height: 13px;
}

.checkbox:global(.thin) input[type=checkbox] + label::before {
  background: transparent;
}
.checkbox:global(.thin) input[type=checkbox]:checked + label {
  color: var(--link-color);
}
.checkbox:global(.thin) input[type=checkbox]:checked + label::before {
  background-color: var(--link-color);
  -webkit-mask: url("images/icons/i-check-thin.svg") no-repeat 50% 50%;
  mask: url("images/icons/i-check-thin.svg") no-repeat 50% 50%;
}
.checkbox:global(.thin) input[type=checkbox]:checked:disabled + label::before {
  opacity: 0.5;
}

.checkbox:global(.thin_dis) input[type=checkbox] + label::before {
  background-image: none;
  background-color: #666;
  -webkit-mask: url("../ezd_checkbox/check.svg") no-repeat 50% 50%;
  mask: url("../ezd_checkbox/check.svg") no-repeat 50% 50%;
}
.checkbox:global(.thin_dis) input[type=checkbox]:checked + label {
  color: var(--link-color);
}
.checkbox:global(.thin_dis) input[type=checkbox]:checked + label::before {
  background-color: var(--link-color);
  -webkit-mask: url("images/icons/i-check-thin.svg") no-repeat 50% 50%;
  mask: url("images/icons/i-check-thin.svg") no-repeat 50% 50%;
}