@import "~styles/colors";

$vertical-indention: 56px;
$horizontal-indention: 28px;
$inner-indention: 24px;
$paper-color: $header-bg-light;

.paper {
	background-color: $paper-color;
	margin: $vertical-indention $horizontal-indention;
	padding: $inner-indention;
}
