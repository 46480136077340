@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.wrapper {
  min-height: 123px;
  border-top: #e6e6e6 1px solid;
  border-bottom: #e6e6e6 1px solid;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}
.wrapper .titles {
  z-index: 3;
  background: #fafafa;
  width: 198px;
}
.wrapper .titles .title {
  font-size: 12px;
  font-weight: bold;
  padding: 9px 6px;
  box-sizing: border-box;
}
.wrapper .titles .sub-title {
  font-size: 20px;
  padding: 6px;
}
.wrapper .button {
  display: flex;
  align-items: center;
  border-left: #e6e6e6 1px solid;
  border-right: #e6e6e6 1px solid;
  cursor: pointer;
  width: 32px;
  box-shadow: 2px 0px 18px -7px rgba(0, 0, 0, 0.35);
}
.wrapper .button:last-child {
  box-shadow: 2px 0px 18px -7px rgba(0, 0, 0, 0.35);
}
.wrapper .title, .wrapper .button {
  position: relative;
  z-index: 3;
  background: #fafafa;
}
.wrapper .title md-icon svg, .wrapper .button md-icon svg {
  fill: #c6c6c6;
}
.wrapper .content {
  position: relative;
  z-index: 2;
  transition: left 0.3s;
}
.wrapper .content > div {
  position: relative;
  min-height: 100%;
}