.ezd-radio-buttons {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ezd-radio-buttons * {
  box-sizing: inherit;
}
.ezd-radio-buttons .ezd-radio-button {
  margin-top: 8px;
}
.ezd-radio-buttons .ezd-radio-button input[type=radio] {
  display: none;
}
.ezd-radio-buttons .ezd-radio-button input[type=radio] + label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #333;
  cursor: pointer;
  line-height: 1.13;
  position: relative;
}
.ezd-radio-buttons .ezd-radio-button input[type=radio] + label::before {
  box-sizing: border-box;
  content: "";
  border-radius: 50%;
  background: linear-gradient(to bottom, #ffffff, #f3f8fb);
  border: solid 1px #ccd6da;
  margin-right: 8px;
  width: 1.429em;
  height: 1.429em;
  min-height: 1.429em;
  min-width: 1.429em;
}
.ezd-radio-buttons .ezd-radio-button input[type=radio] + label::after {
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: calc(50% - 0.285em);
  left: 0.4295em;
  content: "";
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: 0.57em;
  height: 0.57em;
  min-height: 0.57em;
  min-width: 0.57em;
}
.ezd-radio-buttons .ezd-radio-button input[type=radio] + label:hover::before {
  box-shadow: 0 0 0 2px rgba(0, 174, 197, 0.1);
}
.ezd-radio-buttons .ezd-radio-button input[type=radio]:checked + label::before {
  background: var(--menu-color);
}
.ezd-radio-buttons .ezd-radio-button input[type=radio]:checked + label::after {
  display: block;
}
.ezd-radio-buttons .ezd-radio-button input[type=radio]:disabled + label {
  opacity: 0.5;
}
.ezd-radio-buttons .ezd-radio-button input[type=radio]:disabled + label::before {
  background: #e8e8e8;
  border: solid 1px #ccd6da;
  opacity: 0.5;
}
.ezd-radio-buttons .ezd-radio-button input[type=radio]:disabled + label:hover::before {
  box-shadow: none;
}
.ezd-radio-buttons.buttons-style {
  display: flex;
  flex-direction: row;
  border-radius: 2px;
}
.ezd-radio-buttons.buttons-style label::before,
.ezd-radio-buttons.buttons-style label::after {
  display: none !important;
}
.ezd-radio-buttons.buttons-style .ezd-radio-button {
  margin-top: 0;
  padding: 7px 16px;
  font-size: 15px;
  border: 1px #e6e6e6 solid;
}
.ezd-radio-buttons.buttons-style .ezd-radio-button, .ezd-radio-buttons.buttons-style .ezd-radio-button label {
  cursor: pointer;
}
.ezd-radio-buttons.buttons-style .ezd-radio-button:not(:last-child) {
  border-right: 1px #e6e6e6 solid;
}
.ezd-radio-buttons.buttons-style .ezd-radio-button:not(:first-child) {
  border-left-color: transparent;
}
.ezd-radio-buttons.buttons-style .ezd-radio-button:first-child {
  border-radius: 2px 0 0 2px;
}
.ezd-radio-buttons.buttons-style .ezd-radio-button:last-child {
  border-radius: 0 2px 2px 0;
}
.ezd-radio-buttons.buttons-style .ezd-radio-button.active {
  background: var(--color-button-5);
  border-color: var(--color-button-5);
}
.ezd-radio-buttons.buttons-style .ezd-radio-button.active label {
  color: #fff !important;
}