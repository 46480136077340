.academic-year-select {
  height: 100%;
  width: 128px;
  background-color: var(--menu-color);
}
.academic-year-select md-select:not([disabled]):focus :global(._md-select-value) {
  border: none;
}
.academic-year-select md-select:not([disabled]):focus :global(.md-select-value) {
  border: none;
}
.academic-year-select md-select {
  color: #fff;
  margin: 0 8px 0 16px;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
}
.academic-year-select md-select :global(._md-select-value) :global(._md-select-icon) {
  margin: 0 0px;
  width: 10px;
}
.academic-year-select md-select :global(.md-select-value) :global(.md-select-icon) {
  margin: 0 0px;
  width: 10px;
}
.academic-year-select md-select md-select-value {
  border: none;
}

:global(._md-select-menu-container) {
  z-index: 150;
}

.for-admin-security {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}