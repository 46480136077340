.ezd-autocomplete {
  position: relative;
  min-width: 90px;
}
.ezd-autocomplete .ezd-autocomplete-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  height: 24px;
  padding: 4px 8px;
  border-radius: 2px;
  border: 1px solid #ccd6da;
  box-shadow: inset 0 1px 2px 0 rgba(57, 103, 109, 0.2);
  outline: none;
}
.ezd-autocomplete .ezd-autocomplete-header.disabled {
  background: #ddd;
}
.ezd-autocomplete .ezd-autocomplete-header.invalid-model {
  border: solid 1px #ff4848;
}
.ezd-autocomplete .ezd-autocomplete-header input {
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  border: none;
  background: transparent;
}
.ezd-autocomplete .ezd-autocomplete-header:not(.disabled):focus, .ezd-autocomplete .ezd-autocomplete-header:not(.disabled):active, .ezd-autocomplete .ezd-autocomplete-header:not(.disabled):global(.active), .ezd-autocomplete .ezd-autocomplete-header:not(.disabled):hover {
  border: solid 1px var(--menu-color);
  box-shadow: 0 0 1px 1px var(--menu-color-02);
}
.ezd-autocomplete .ezd-autocomplete-header md-icon {
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
}
.ezd-autocomplete .ezd-autocomplete-header md-icon[find] {
  margin: 0 8px 0 0;
}
.ezd-autocomplete .ezd-autocomplete-header md-icon[clear] {
  margin: 0 0 0 8px;
}
.ezd-autocomplete .ezd-autocomplete-header md-icon[clear]:hover {
  cursor: pointer;
}
.ezd-autocomplete .ezd-autocomplete-header md-icon[clear] svg {
  fill: currentColor;
}
.ezd-autocomplete .ezd-autocomplete-options-list {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 8px 0;
  z-index: 2;
  border: 1px solid #e6e6e6;
  width: 100%;
  background: #fff;
  min-height: 150px;
  max-height: 400px;
  overflow: auto;
}
.ezd-autocomplete .ezd-autocomplete-options-list :global(.item) {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 24px;
  font-size: 14px;
  font-weight: 400;
  padding: 0 8px;
  margin: 8px 0;
}
.ezd-autocomplete .ezd-autocomplete-options-list :global(.item):hover {
  background: #fafafa;
}