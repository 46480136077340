@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.insert-table-dialog-wrapper {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 0 24px;
}
.insert-table-dialog-wrapper .dialog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  font-size: 18px;
  color: #333;
  padding: 12px 0;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-topright: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.insert-table-dialog-wrapper .dialog-header .close-icon {
  background: url("images/icons/removeNew.svg") 0 0 no-repeat;
  background-size: cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.insert-table-dialog-wrapper > div {
  margin-bottom: 15px;
}
.insert-table-dialog-wrapper > div .param-title {
  font-size: 14px;
  color: #666;
  width: 150px;
}
.insert-table-dialog-wrapper > div .input-field input::-webkit-outer-spin-button,
.insert-table-dialog-wrapper > div .input-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.insert-table-dialog-wrapper .form-actions {
  margin: 15px 0;
}
.insert-table-dialog-wrapper .form-actions .button {
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  height: 40px;
  min-width: 112px;
  letter-spacing: normal;
  padding: 0 16px;
  background-blend-mode: overlay;
  min-height: 32px;
  line-height: 32px;
  height: 32px;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  margin: 0;
}
.insert-table-dialog-wrapper .form-actions .button md-icon {
  color: #ff501d;
}
.insert-table-dialog-wrapper .form-actions .button:not(:global(.md-raised)) {
  color: #ff501d;
}
.insert-table-dialog-wrapper .form-actions .button:global(.md-raised) {
  background-color: #ff501d;
}
.insert-table-dialog-wrapper .form-actions .button[disabled] {
  background-color: rgba(179, 179, 179, 0.5);
}
.insert-table-dialog-wrapper .form-actions .button[disabled]:global(.md-raised) {
  background-color: #b3b3b3;
}
.insert-table-dialog-wrapper .form-actions .button:hover:global(.md-raised), .insert-table-dialog-wrapper .form-actions .button:active:global(.md-raised), .insert-table-dialog-wrapper .form-actions .button:focus:global(.md-raised) {
  filter: brightness(85%);
  background-color: #ff501d;
}
.insert-table-dialog-wrapper .form-actions .button:hover:global(.md-raised)[disabled], .insert-table-dialog-wrapper .form-actions .button:active:global(.md-raised)[disabled], .insert-table-dialog-wrapper .form-actions .button:focus:global(.md-raised)[disabled] {
  background-color: #b3b3b3;
}
.insert-table-dialog-wrapper .form-actions .button md-icon {
  min-height: 13px;
  min-width: 13px;
  width: 13px;
  height: 13px;
}