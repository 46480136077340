@import "~styles/colors";

md-slider.ezd-range {

	& :global(.md-slider-wrapper) {
		position: relative;
		height: 54px;
		cursor: pointer;

		:global(.md-track-container) {
			height: 1px;

			:global(.md-track) {
				background-color: $light-grey;

				&:global(.md-track-fill) {
					display: none;
				}
			}
		}

		:global(.md-thumb-container) {
			z-index: 10;

			:global(.md-thumb) {
				transform: none !important;

				&::after {
					width: 18px;
					height: 18px;
					background-color: #ffffff;
					border: 1px solid $light-border;
					box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
				}

				&:hover {
					transform: none !important;
					&::after {
						border-color: var(--menu-color);
					}
				}
			}

			:global(.md-focus-ring) {
				display: none;
				transform: none !important;
			}

			.thumb-input {
				position: absolute;
				top: -26px;
				left: 50%;
				display: block;
				width: 40px;
				height: 24px;
				color: $black;
				user-select: none;
				transform: translateX(-55%);

				& * {
					user-select: none;
				}

				& > span {
					display: inline-block;
					width: 100%;
					height: 100%;
					text-align: center;
					line-height: 24px;
					font-size: 14px;
				}

				& > input[type=number] {
					width: 100%;
					height: 100%;
					padding: 0 2px 0 6px;
					font-size: 14px;
					position: relative;
					top: -2px;
				}
			}

			.wide-type {
				& > input[type=number] {
					width: 60px;
					margin-left: -8px;
				}
			}
		}

	}

	&:not([md-discrete]):not([disabled]) {

		&:global(.md-active) {
			:global(.md-slider-wrapper) {
				:global(.md-thumb-container) {
					:global(.md-thumb) {
						transform: none !important;
						&::after {
							border-color: var(--menu-color);
						}
					}
				}
			}
		}

		:global(.md-slider-wrapper) {
			&:global(.md-focused) {
				:global(.md-focus-ring) {
					display: none;
					transform: none !important;
				}
			}
		}

	}

	.min-value,
	.max-value {
		position: absolute;
		bottom: 0;
		display: block;
		color: $black;
		line-height: 1;
		user-select: none;
	}

	.min-value {
		left: 0;
	}

	.max-value {
		right: 0;
	}
}
