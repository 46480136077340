@import "~styles/colors";

.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: $breadcrumbs-bg;
  height: 32px;
  color: $grey;
  font-size: 12px;
  padding: 0 24px;

  & > span {
	flex: 1;
  }

  a {
	color: $grey;
  }

  .help-link {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	&::after {
	  content: "";
	  background: url("images/icons/question_mark.svg") no-repeat;
	  width: 14px;
	  height: 14px;
	  margin-left: 8px;
	}
  }
}