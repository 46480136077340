@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
assignment-checkbox {
  display: block;
  height: 24px;
}
assignment-checkbox[small=true] {
  height: 16px;
  font-size: 12px;
  line-height: 16px;
}

.checkbox {
  display: inline-block;
}
.checkbox input[type=checkbox] {
  display: none;
}
.checkbox input[type=checkbox] + label {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.checkbox input[type=checkbox] + label::before {
  content: "";
  display: inline-block;
  background: #F3F3F3;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  box-shadow: inset 0 1px 1px 0 rgba(189, 189, 189, 0.5);
}
.checkbox input[type=checkbox] + label span {
  background: none;
  width: auto;
  height: auto;
  margin-left: 3px;
}
.checkbox input[type=checkbox]:checked + label::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  background: var(--menu-color);
  border-radius: 50%;
  left: 5px;
  top: 5px;
  display: inline-block;
}
.checkbox input[type=checkbox]:checked + label span {
  background: none;
  width: auto;
  height: auto;
}
.checkbox input[type=checkbox]:disabled + label {
  opacity: 0.5;
}

.checkbox:global(.small) input[type=checkbox] + label::before {
  width: 16px;
  height: 16px;
}
.checkbox:global(.small) input[type=checkbox]:checked + label::after {
  width: 8px;
  height: 8px;
  top: 4px;
  left: 4px;
}