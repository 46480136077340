@import "~styles/colors";

.dots-menu {
	padding: 0 6px;
	border: 1px solid rgba(153, 153, 153, .4);
	border-radius: 3px;

	&:hover {
		cursor: pointer;
	}
}

.menu-content {
	min-height: 72px;
}

.menu-item {
	min-height: 24px;
	height: 33px;

	.script-link {
		color: $black;
		text-decoration: none;
	}
}

.last-menu-item {
	margin-top: 2px;
	border-top: 1px solid $grey-text;

	.delete-script-btn {
		text-align: center;
	}
}
