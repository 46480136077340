.user-account {
	height: 100%;
	padding-right: 10px;

	.cabinet-link {
		color: #303030;
		text-decoration: none;
	}

	ng-transclude {
		display: flex;
	}

	h4 {
		font-size: 14px;
		color: #303030;
	}

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		float: left;
		margin-right: 16px;
	}

	& > div {
		position: relative;
	}

	a:hover {
		cursor: pointer;
	}

	:global(.md-button.md-icon-button) {
		min-height: 0;
		width: 36px;
		height: 36px;
		padding: 0;
		margin: 0 8px 0 0;
		overflow: visible;
	}

	:global(.md-button.md-wide-button) {
		min-width: 0;
		min-height: 0;
		height: 36px;
		padding: 0;
		margin: 0 8px 0 0;
		overflow: visible;

		img {
			width: 76px;
			height: 16px;
			border-radius: 0;
			float: none;
			margin: 0 4px 0 4px;
		}
	}

	md-menu {
		padding: 0;
		width: 36px;
		height: 36px;
		margin-right: 8px;

		md-menu-item {
			height: 32px;
		}
	}

	md-icon {
		color: #d0d0d0;
		min-width: 0;
		min-height: 0;
		width: 36px;
		height: 36px;

		svg {
			width: 20px;
			height: 20px;
			padding: 8px;
			fill: inherit;
		}

		&:hover {
			cursor: pointer;
			background: #f6f6f6;
		}

		&[aria-label=avatar] {
			width: 37px;
			height: 37px;
			border-radius: 50%;

			svg {
				padding: 0;
				width: 100%;
				height: 100%;
			}

		}

		&[aria-label=mail] svg {
			width: 22px;
			height: 22px;
			padding: 5px 7px 7px 7px;
		}

		&[aria-label=decisions] svg {
			width: 22px;
			height: 22px;
			padding: 7px;
		}

	}

	.bell {
		svg {
			width: 24px;
			height: 24px;
			padding: 6px;
		}
	}

}

.notification {
	position: absolute;
	bottom: -3px;
	left: 19px;
	width: 19px;
	height: 19px;
	border-radius: 50%;
	border: 2px solid #ffffff;
	font-size: 12px;
	line-height: 21px;
	color: #fff;
	text-align: center;

	&:global(.red) {
		background: #ff3f03;
	}
}

.pin {

	ng-transclude {
		margin: 0;
		padding: 0;
	}

	:global(.md-button) {
		width: 100%;
		margin: 0;
		padding: 7px 16px;
		text-align: left;
	}
}

.role_message {
	color: #999;
	text-align: center;
}

md-dialog-content.popup-limited{
	display: flex;
	flex-direction: column;
	height: 475px;
	width: 600px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
.popup-limited-top{
	display: flex;
	justify-content: flex-end;
}
.popup-limited-close{
	display: flex;
	justify-content: flex-end;
	padding: 10px;
	font-size: 50px;
	font-weight: lighter;
}

.popup-limited-close:hover{
	cursor: pointer;
}

.popup-limited-img_container{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;
	width: 500px;
	padding-left: 50px;
	padding-right: 50px;
	height: 109px;
}

.popup-limited-message_img{
	width: 100%;
	height: 100%;
}

.popup-limited-title{
	display: flex;
	justify-content: center;
	font-size: 24px;
	color: #0a0a0a;
	font-weight: bold;
	margin-top: 50px;
	margin-bottom: 20px;
}

.popup-limited-content{
	display: flex;
	padding-left: 45px;
	padding-right: 45px;
	font-size: 15px;
	line-height: 21px;
	text-align: center;
	margin-bottom: 20px;
}

.popup-limited-know_more{
	display: flex;
	justify-content: center;
}

.popup-limited-know_moreBtn {
	width: 200px;
	height: 32px;
	background-color: #ff501d;
	color: #fff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: rgba(0,0,0,.1);
	-moz-box-shadow: rgba(0,0,0,.1);
	box-shadow: rgba(0,0,0,.1);
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.popup-limited-know_moreBtn:hover{
	color: #0a0a0a;
}
