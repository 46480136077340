.ezd-run-scripts__wrapper {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.ezd-run-scripts__wrapper * {
  box-sizing: border-box;
}
.ezd-run-scripts__wrapper a.ezd-run-scripts__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin: 0;
  padding: 0;
}
.ezd-run-scripts__wrapper a.ezd-run-scripts__btn md-icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}