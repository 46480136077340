@import "~styles/colors";

@mixin flex-row {
	display: inline-flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.footer-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 18px 14px;
	background: $bg-block;
	height: 128px;
	border-top: 1px solid $footer-border;
	color: $color-extra;
	font-family: inherit;
	font-size: 14px;
	box-sizing: border-box;

	& a {
		text-decoration: none;
		color: $color-extra;

		&:hover, &:focus, &:active, &:visited {
			text-decoration: none;
			cursor: pointer;
			color: $footer-text-hover;
			outline: none;
		}

		&:focus, &:visited {
			color: $color-extra;
		}

	}

	.footer-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.footer-menu, .footer-contacts {
			@include flex-row;
		}

		.footer-menu-item {
			display: none;
			padding: 0 14px;
		}

		.footer-contact, .footer-soc-icons, .footer-btn {
			padding: 0 14px;
		}

		.footer-soc-icons {
			@include flex-row;

			& a {
				margin: 0 8px;
				opacity: 0.64;

				& .footer-soc-icon {
					min-height: 20px;
					min-width: 20px;
					height: 20px;
					width: 20px;
					line-height: 0;
					color: $color-extra;
				}

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				&:hover {
					opacity: 1;
				}
			}
		}

		.footer-btn button {
			border: 1px solid $footer-light-text;
			padding: 8px 20px;
			line-height: 14px;
			border-radius: 3px;
			background: $bg-block;
			color: $color-extra;
			outline: none;
			cursor: pointer;

			&:hover {
				color: $footer-text-hover;
				border: 1px solid $color-border-hover;
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
			}
		}

	}

	.footer-copyright {
		@include flex-row;
		justify-content: flex-end;
		width: calc(100% - 14px);
		padding-top:18px;
		opacity: 0.71;
		font-size: 13px;
	}

}
