@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.wrapper {
  box-sizing: border-box;
  height: 48px;
  border-bottom: #e6e6e6 1px solid;
  overflow: hidden;
  position: relative;
}
.wrapper .student-info {
  position: relative;
  z-index: 2;
  background: #fff;
  padding: 9px 6px;
  width: 232px;
  height: 100%;
  border-right: #e6e6e6 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .student-info .student-name {
  color: var(--link-color);
}
.wrapper .student-info .student-class-unit {
  color: #b3b3b3;
  text-align: right;
  width: 61px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wrapper .groups-assignments-wrapper {
  position: relative;
  height: 100%;
}
.wrapper .groups-assignments-wrapper > div {
  height: 100%;
  position: absolute;
  z-index: 1;
  transition: left 0.3s;
}
.wrapper .groups-assignments-wrapper > div .groups-assignment {
  height: 100%;
  width: 100px;
  max-width: 100px;
  border-right: #e6e6e6 1px solid;
}
.wrapper .groups-assignments-wrapper > div .groups-assignment.has-changes {
  background-color: rgba(255, 243, 0, 0.1);
}
.wrapper .groups-assignments-wrapper > div .groups-assignment.is-disabled {
  background-color: #f7f7f7;
}
.wrapper .spacer {
  position: absolute;
  background: #fff;
  border-left: #e6e6e6 1px solid;
  right: 0;
  width: 34px;
  height: 100%;
  z-index: 2;
}