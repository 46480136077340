.ezd-pure-select {
  position: relative;
  min-width: 100%;
  display: block;
}
.ezd-pure-select .origin-field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
.ezd-pure-select md-icon {
  margin: 0 0 0 auto;
  height: 4px;
  width: 8px;
  min-height: 0;
  min-width: 0;
}
.ezd-pure-select .ezd-pure-select-header, .ezd-pure-select .ezd-pure-select-header-disabled {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  background: linear-gradient(to bottom, #ffffff, #f3f8fb);
  border: 1px solid #ccd6da;
  border-radius: 1px;
  outline: none;
}
.ezd-pure-select .ezd-pure-select-header:hover, .ezd-pure-select .ezd-pure-select-header-disabled:hover {
  border: solid 1px var(--menu-color);
}
.ezd-pure-select .ezd-pure-select-header:focus, .ezd-pure-select .ezd-pure-select-header-disabled:focus, .ezd-pure-select .ezd-pure-select-header:active, .ezd-pure-select .ezd-pure-select-header-disabled:active, .ezd-pure-select .ezd-pure-select-header[aria-expanded=true], .ezd-pure-select [aria-expanded=true].ezd-pure-select-header-disabled {
  border: solid 1px var(--menu-color);
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.ezd-pure-select .ezd-pure-select-header__line {
  cursor: pointer;
  font-size: 14px;
  height: auto;
  width: auto;
  background: none;
  border: none;
  outline: none;
}
.ezd-pure-select .ezd-pure-select-header__line > md-icon {
  display: none;
}
.ezd-pure-select .ezd-pure-select-header__line .placeholder {
  color: #00acc1;
}
.ezd-pure-select .ezd-pure-select-header__line .origin-field {
  border-bottom: 1px dashed #ccd6da;
}
.ezd-pure-select .ezd-pure-select-header__line:hover {
  border: none;
}
.ezd-pure-select .ezd-pure-select-header__line:focus, .ezd-pure-select .ezd-pure-select-header__line:active, .ezd-pure-select .ezd-pure-select-header__line[aria-expanded=true] {
  border: none;
  box-shadow: none;
}
.ezd-pure-select .ezd-pure-select-header-disabled {
  cursor: default;
  user-select: none;
  background: #dddddd;
  border-color: #ccd6da;
  opacity: 0.5;
}
.ezd-pure-select .ezd-pure-select-header-disabled:focus, .ezd-pure-select .ezd-pure-select-header-disabled:active, .ezd-pure-select .ezd-pure-select-header-disabled:hover {
  border: 1px solid #ccd6da;
  box-shadow: none;
}

.ezd-pure-select-options-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 80;
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #ccd6da;
  border-radius: 1px;
  min-width: 200px;
  min-height: 32px;
  max-height: 250px;
  overflow-y: auto;
  padding: 8px 0;
}
.ezd-pure-select-options-list .list-item {
  text-overflow: ellipsis;
  align-items: center;
  cursor: pointer;
  min-height: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 15px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  transition: none !important;
  transition-delay: 0ms !important;
}
.ezd-pure-select-options-list .list-item:global(.selected) {
  background: var(--menu-color-02);
  color: #4e7281;
}
.ezd-pure-select-options-list .list-item:hover {
  color: #fff;
  background: var(--menu-color);
}
.ezd-pure-select-options-list .list-item > * {
  padding: 0 8px;
}