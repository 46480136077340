@import "~styles/colors";
@import "~styles/mixins";

.editor__wrapper {
	padding: 16px;
	min-height: 300px;
	border: 1px solid $light-border;
	border-radius: 2px;

	.image__wrapper {
		& > img {
			width: auto;
			height: 100%;
		}
	}

	.buttons__wrapper {
		margin-top: 16px;

		button.btn-save {
			@include ezd-button($orange);
			width: 120px;
			height: 32px;
			color: $white;
		}
		button.btn-cancel {
			@include ezd-button($grey);
			width: 120px;
			height: 32px;
		}
	}
}
