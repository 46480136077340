@charset "UTF-8";
/**
 * Добавляем цвет для выделенной вкладки
 */
.ezd-button-group-item > input[type=radio] {
  display: none;
  width: 0;
  height: 0;
}
.ezd-button-group-item > input[type=radio] + label {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  font-size: 15px;
}
.ezd-button-group-item > input[type=radio]:not(:disabled) + label:hover {
  cursor: pointer;
}
.ezd-button-group-item > input[type=radio]:checked + label, .ezd-button-group-item > input[type=radio][checked] + label, .ezd-button-group-item > input[type=radio][checked=checked] + label {
  color: #fff;
  font-weight: 500;
}
.ezd-button-group-item > input[type=radio]:checked:disabled + label, .ezd-button-group-item > input[type=radio][checked]:disabled + label, .ezd-button-group-item > input[type=radio][checked=checked]:disabled + label {
  opacity: 0.5;
}
.ezd-button-group-item > input[type=radio]:checked:not(:disabled) + label:hover, .ezd-button-group-item > input[type=radio][checked]:not(:disabled) + label:hover, .ezd-button-group-item > input[type=radio][checked=checked]:not(:disabled) + label:hover {
  opacity: 0.85;
}
.ezd-button-group-item > input[type=radio]:not(:checked):not(:disabled) + label:hover, .ezd-button-group-item > input[type=radio]:not([checked]):not(:disabled) + label:hover, .ezd-button-group-item > input[type=radio]:not([checked=checked]):not(:disabled) + label:hover {
  background-color: #fff;
}

.ezd-button-group {
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: nowrap;
  height: 36px;
  max-height: 36px;
  padding: 2px;
  border-radius: 3px;
  background-color: #f5f5f5;
}
.ezd-button-group.ezd-button-group-green .ezd-button-group-item > input[type=radio]:checked:not(disabled) + label, .ezd-button-group.ezd-button-group-green .ezd-button-group-item > input[type=radio][checked]:not(disabled) + label, .ezd-button-group.ezd-button-group-green .ezd-button-group-item > input[type=radio][checked=checked]:not(disabled) + label {
  background-color: var(--menu-color);
}
.ezd-button-group.ezd-button-group-grey .ezd-button-group-item > input[type=radio]:checked:not(disabled) + label, .ezd-button-group.ezd-button-group-grey .ezd-button-group-item > input[type=radio][checked]:not(disabled) + label, .ezd-button-group.ezd-button-group-grey .ezd-button-group-item > input[type=radio][checked=checked]:not(disabled) + label {
  background-color: #738fa0;
}