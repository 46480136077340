.wysiwyg-wrapper {
  position: relative;
}
.wysiwyg-wrapper .upload-file {
  border-bottom: none;
}
.wysiwyg-wrapper .upload-file label {
  position: absolute;
  top: 0;
  width: 44px;
  height: 32px;
  left: 273px;
  z-index: 999;
}
.wysiwyg-wrapper .attachments-list {
  margin-top: 15px;
}
.wysiwyg-wrapper .attachments-list a {
  color: var(--font-color2);
}
.wysiwyg-wrapper .attachments-list md-icon {
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
}