@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.copy-timetable {
  width: 470px;
}
.copy-timetable header {
  padding: 5px 13px;
}
.copy-timetable header span {
  color: #666;
}
.copy-timetable header md-icon {
  color: #666;
  cursor: pointer;
  margin: 0;
}
.copy-timetable header md-icon > svg {
  fill: #666;
}
.copy-timetable main .copy-icon {
  height: 160px;
}
.copy-timetable main .copy-icon img {
  max-height: 100px;
  max-width: 100px;
}
.copy-timetable main .warn-banner {
  background-color: #FAF0D4;
  font-size: 12px;
  color: #333;
  margin-top: 24px;
}
.copy-timetable main .warn-banner > div {
  padding: 19px 22px;
}
.copy-timetable main .warn-banner img {
  margin-right: 8px;
}
.copy-timetable main .input-section {
  padding: 0 30px 32px;
}
.copy-timetable main .input-section > span {
  padding: 12px 0 24px;
}
.copy-timetable main .input-section .main-input {
  width: 100%;
}
.copy-timetable main .input-section .main-input .input-title {
  padding-right: 54px;
}
.copy-timetable main .input-section .main-input .text-input {
  width: 290px;
}
.copy-timetable main .input-section .error {
  font-size: 12px;
  color: #ff4747;
  padding: 16px 16px 0;
}
.copy-timetable main .controls {
  padding: 24px 30px;
  border-top: 1px solid #e6e6e6;
}
.copy-timetable main .controls :global(.md-button).save {
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  height: 40px;
  min-width: 112px;
  letter-spacing: normal;
  padding: 0 16px;
  background-blend-mode: overlay;
  color: #fff;
  min-height: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
}
.copy-timetable main .controls :global(.md-button).save md-icon {
  color: #fd6a2a;
}
.copy-timetable main .controls :global(.md-button).save:not(:global(.md-raised)) {
  color: #fd6a2a;
}
.copy-timetable main .controls :global(.md-button).save:global(.md-raised) {
  background-color: #fd6a2a;
}
.copy-timetable main .controls :global(.md-button).save[disabled] {
  background-color: rgba(179, 179, 179, 0.5);
}
.copy-timetable main .controls :global(.md-button).save[disabled]:global(.md-raised) {
  background-color: #b3b3b3;
}
.copy-timetable main .controls :global(.md-button).save:hover:global(.md-raised), .copy-timetable main .controls :global(.md-button).save:active:global(.md-raised), .copy-timetable main .controls :global(.md-button).save:focus:global(.md-raised) {
  filter: brightness(85%);
  background-color: #fd6a2a;
}
.copy-timetable main .controls :global(.md-button).save:hover:global(.md-raised)[disabled], .copy-timetable main .controls :global(.md-button).save:active:global(.md-raised)[disabled], .copy-timetable main .controls :global(.md-button).save:focus:global(.md-raised)[disabled] {
  background-color: #b3b3b3;
}
.copy-timetable main .controls :global(.md-button).hide {
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  height: 40px;
  min-width: 112px;
  letter-spacing: normal;
  padding: 0 16px;
  background-blend-mode: overlay;
  color: #333;
  min-height: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  box-shadow: none;
}
.copy-timetable main .controls :global(.md-button).hide md-icon {
  color: #fff;
}
.copy-timetable main .controls :global(.md-button).hide:not(:global(.md-raised)) {
  color: #fff;
}
.copy-timetable main .controls :global(.md-button).hide:global(.md-raised) {
  background-color: #fff;
}
.copy-timetable main .controls :global(.md-button).hide[disabled] {
  background-color: rgba(179, 179, 179, 0.5);
}
.copy-timetable main .controls :global(.md-button).hide[disabled]:global(.md-raised) {
  background-color: #b3b3b3;
}
.copy-timetable main .controls :global(.md-button).hide:hover:global(.md-raised), .copy-timetable main .controls :global(.md-button).hide:active:global(.md-raised), .copy-timetable main .controls :global(.md-button).hide:focus:global(.md-raised) {
  filter: brightness(85%);
  background-color: #fff;
}
.copy-timetable main .controls :global(.md-button).hide:hover:global(.md-raised)[disabled], .copy-timetable main .controls :global(.md-button).hide:active:global(.md-raised)[disabled], .copy-timetable main .controls :global(.md-button).hide:focus:global(.md-raised)[disabled] {
  background-color: #b3b3b3;
}