#modal-overlay-wrap {
    position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1002; /* перебиваем cg-busy */
	font-family: "Open Sans", ​sans-serif;
	color: #6c8796;
}

#modal-overlay {
	background-color: #fff;
	margin-top: 100px;
	width: 600px;
	padding: 20px;
	border-radius: 10px;
	position: fixed;
	left: 50%;
	transform: translate(-50%, 0);
	max-height: 750px;
	overflow-y: scroll;
}

#modal-overlay h1 {
	text-align: center;
	padding-bottom: 20px;
}

#modal-overlay p {
	padding: 23px;
	font-size: 13pt;
	text-align: center;
	line-height: 26px;
}

#modal-close-button {
	cursor: pointer;
	float: none;
	text-align: right;
}

#modal-overlay-wrap table {
	margin: 10px 0;
	border: 1px solid #cfd4d7;
	width: 600px;
}

.modal-report-missing td:nth-child(1) {
	width: 150px;
}

.modal-report-missing td:nth-child(2) {
	padding-left: 20px;
}

#modal-overlay-wrap td {
	border: 1px solid #cfd4d7;
	padding: 5px;
}