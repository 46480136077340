.mask-date-picker .ezd-input-container {
  top: 0;
  left: 0;
  display: flex;
  position: relative;
  max-width: 160px;
}
.mask-date-picker .ezd-input-container > div {
  border: 1px solid #ccd6da;
  border-radius: 2px;
}
.mask-date-picker .ezd-input-container > div:invalid:not(.ng-untouched), .mask-date-picker .ezd-input-container > div.ng-invalid:not(.ng-untouched) {
  border: 1px solid #ff4747;
}
.mask-date-picker .ezd-input-container > div:not(:disabled):hover:not(.invalid-date), .mask-date-picker .ezd-input-container > div:not(:disabled):focus {
  border: 1px solid var(--menu-color);
}
.mask-date-picker .ezd-input-container .invalid-date {
  border: 1px solid #ff4747 !important;
}
.mask-date-picker .ezd-input-container .selected-element {
  border: 1px solid var(--menu-color);
}
.mask-date-picker .ezd-input-container md-icon {
  position: absolute;
  right: 8px;
  top: 18%;
  height: 60%;
  display: flex;
  align-items: center;
  z-index: 0;
  cursor: text;
}
.mask-date-picker .ezd-input {
  border-radius: 2px;
  box-shadow: inset 0 1px 2px 0 rgba(57, 103, 109, 0.2);
  border: none;
  box-sizing: border-box;
  height: 32px;
  font-size: 15px;
  padding: 0 8px;
  width: 160px;
  max-width: 160px;
}

.calendar-container {
  max-height: 350px;
  padding: 1px 1px 3px;
}