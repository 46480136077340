.top-menu {
  height: 48px;
  background: #00838f;
}
.top-menu .for-medal {
  color: #fff !important;
  font-size: 16px;
  line-height: 20px;
  padding: 14px;
  height: 48px;
  outline: none;
  text-decoration: none;
}
.top-menu .for-medal:hover {
  cursor: pointer;
  background: rgba(0, 93, 102, 0.2);
}
.top-menu > div {
  margin: 0 auto;
}
.top-menu :global(.md-menu) {
  height: 48px;
}
.top-menu :global(.md-menu) > button {
  color: #fff;
  font-size: 16px;
  height: 48px;
}

.menu-toolbar {
  position: relative;
  min-height: 48px;
  z-index: 0;
}
.menu-toolbar md-menu-bar {
  padding-left: 8px;
}
.menu-toolbar md-menu-bar button {
  padding: 0 8px;
}
.menu-toolbar md-menu-bar button md-icon {
  color: #fff !important;
  min-height: 18px;
  min-width: 18px;
  height: 18px;
  width: 18px;
}
.menu-toolbar .home-icon, .menu-toolbar .back-icon {
  color: #fff !important;
  padding: 12px;
  margin: 0 !important;
  width: 48px;
  height: 48px;
  outline: none;
}
.menu-toolbar .back-icon {
  margin-right: 18px !important;
}

.overflowing {
  overflow: auto;
  max-height: 400px !important;
}
.overflowing > md-menu-item {
  min-height: 32px !important;
}

.top-level-item {
  color: #fff;
  font-size: 16px;
  height: 48px;
  margin-left: -5px;
}
.top-level-item:hover {
  background-color: rgba(238, 238, 238, 0.2);
}

.link-item {
  text-decoration: none;
}

.menu-item {
  position: relative;
  margin-right: 30px;
  box-sizing: border-box;
  background: none !important;
  cursor: pointer;
}
.menu-item:hover, .menu-item.active {
  opacity: 1;
}
.menu-item:hover:after, .menu-item.active:after {
  border-bottom: solid 3px var(--menu-item-highlight-color);
}
.menu-item.active {
  font-weight: 500;
}
.menu-item:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.mesh-library {
  position: absolute;
  top: 7px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  padding: 8px 15px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  z-index: 10;
}
.mesh-library:hover {
  cursor: pointer;
  background-color: #fff;
  color: var(--font-color2);
}
.mesh-library:hover .link-arrow {
  color: var(--font-color2) !important;
}

.link-arrow {
  position: absolute;
  top: 2px;
  right: 4px;
  min-height: 7px;
  min-width: 8px;
  height: 7px;
  width: 8px;
  color: #fff !important;
}