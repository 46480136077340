ezd-tooltip {
  position: absolute;
  display: block;
  z-index: -10000;
  opacity: 0;
}

ezd-tooltip-el {
  position: absolute;
  display: block;
  max-width: 400px;
  z-index: 10000;
  top: 0;
  left: 0;
  padding-bottom: 9px;
}
ezd-tooltip-el :global(.tooltip-title) {
  font-size: 12px;
  line-height: 1.5;
  color: #999999;
  margin-bottom: 8px;
}
ezd-tooltip-el .wrapper {
  border: 1px solid #e2e2e2;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 7px -4px rgba(0, 0, 0, 0.26);
  font-size: 14px;
  line-height: 1.29;
  color: #333;
  padding: 18px 15px;
}
ezd-tooltip-el :after {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #fff;
  left: 50%;
  bottom: 1px;
  transform: translateX(-50%) rotate(45deg);
  box-shadow: 1px 1px 5px -4px rgba(0, 0, 0, 0.26);
  border-width: 1px;
  border-style: solid;
  border-color: transparent #e2e2e2 #e2e2e2 transparent;
}