@import "~styles/colors";
@import "~styles/mixins";

.header {
	background: #fff;
	width: 100%;

	& > div {
		margin: 0 auto;
		min-width: 1127px;

		& .contact-link {
			padding: 24px;
			a {
				font-size: 14px;
				font-weight: 400;
				color: #999;
				text-decoration: none;
				line-height: 1.3;
			}
		}
	}

	:global(.md-icon-button) {
		min-height: 0;
		width: 32px;
		height: 32px;
		padding: 5px 0 0 0;
		margin: 0 8px 0 15px;
	}

	.demo-link {
		height: 32px;
		min-width: 250px;
		background: $light-gradient;
		color: $white !important;
		text-transform: none;
		letter-spacing: normal;
		font-weight: 400;
		padding: 0 16px;

		&:hover {
			background: $dark-gradient;
		}
	}
}

.demo-description {
	height: auto;

	h4 {
		font-size: 16px;
		padding: 16px;
		width: auto;
	}

	p {
		width: auto;
		padding: 16px;
	}

	:global(.md-button) {
		margin: 16px;
		width: 135px;
		background: $orange !important;
		color: $white !important;
		text-align: center;

		&:hover {
			background: darken($orange, 15%) !important;
		}
	}
}

.check-child-ready {
	height: 64px;
	background: #009cd9;
	text-decoration: none;
	color: $black;
	cursor: pointer;

	&:visited {
		color: $black;
	}

	md-icon {
		height: 64px;
		min-height: 64px;
		width: 100%;
		color: $black;
	}
}

