ul.grade {
	list-style: none;
	color: #000;
	font-weight: normal;
	float: left;
	margin-left: 10px;
}

ul.grade li span {
	color: #6c8796;
	font-weight: bold;
}

ul.grade-crit,
ul.grade-test,
ul.grade-abc {
	background: #eee;
}

ul.grade li i {
	left: 0;
	top: 5px;
}
