@import "~styles/colors";
@import "~styles/mixins";

.copy-timetable {
	width: 470px;

	header {
		padding: 5px 13px;

		span {
			color: $grey;
		}

		md-icon {
			color: $grey;
			cursor: pointer;
			margin: 0;

			& > svg {
				fill: $grey;
			}
		}
	}

	main {
		.copy-icon {
			height: 160px;

			img {
				max-height: 100px;
				max-width: 100px;
			}
		}

		.warn-banner {
			background-color: #FAF0D4;
			font-size: 12px;
			color: $black;
			margin-top: 24px;

			& > div {
				padding: 19px 22px;
			}

			img {
				margin-right: 8px;
			}
		}

		.input-section {
			padding: 0 30px 32px;

			& > span {
				padding: 12px 0 24px;
			}

			.main-input {
				width: 100%;

				.input-title {
					padding-right: 54px;
				}

				.text-input {
					width: 290px;
				}
			}

			.error {
				font-size: 12px;
				color: $red;
				padding: 16px 16px 0;
			}
		}

		.controls {
			padding: 24px 30px;
			border-top: 1px solid $light-border;

			:global(.md-button).save {
				@include ezd-button($orange);
				color: $white;
				min-height: 32px;
				height: 32px;
				line-height: 32px;
				font-size: 14px;
			}

			:global(.md-button).hide {
				@include ezd-button($white);
				color: $black;
				min-height: 32px;
				height: 32px;
				line-height: 32px;
				font-size: 14px;
				box-shadow: none;
			}
		}
	}
}
