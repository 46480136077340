form:global(.ezd-radio-buttons) {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100%;

	& * {
		box-sizing: inherit;
	}

	//& > ezd-radio-buttons-item:not(:last-child) {
	//  margin-bottom: 8px;
	//}
}
