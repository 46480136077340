@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.header {
  background: #fff;
  width: 100%;
}
.header > div {
  margin: 0 auto;
  min-width: 1127px;
}
.header > div .contact-link {
  padding: 24px;
}
.header > div .contact-link a {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  text-decoration: none;
  line-height: 1.3;
}
.header :global(.md-icon-button) {
  min-height: 0;
  width: 32px;
  height: 32px;
  padding: 5px 0 0 0;
  margin: 0 8px 0 15px;
}
.header .demo-link {
  height: 32px;
  min-width: 250px;
  background: linear-gradient(0deg, var(--light-gradient-start), var(--light-gradient-end));
  color: #fff !important;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400;
  padding: 0 16px;
}
.header .demo-link:hover {
  background: linear-gradient(0deg, var(--light-gradient-start), var(--light-gradient-end));
}

.demo-description {
  height: auto;
}
.demo-description h4 {
  font-size: 16px;
  padding: 16px;
  width: auto;
}
.demo-description p {
  width: auto;
  padding: 16px;
}
.demo-description :global(.md-button) {
  margin: 16px;
  width: 135px;
  background: #fd6a2a !important;
  color: #fff !important;
  text-align: center;
}
.demo-description :global(.md-button):hover {
  background: #d84302 !important;
}

.check-child-ready {
  height: 64px;
  background: #009cd9;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}
.check-child-ready:visited {
  color: #333;
}
.check-child-ready md-icon {
  height: 64px;
  min-height: 64px;
  width: 100%;
  color: #333;
}