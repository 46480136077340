/*-------------------------------*/

.b-ktp__table-head__columns {
	background: #ebf2f5;
}

.b-ktp__table-head__column {
	display: table-cell;
	border-right: 1px solid #cfd4d7;
	height: 40px;
	vertical-align: middle;
	padding: 0 5px;
	line-height: 12px;
}

.b-ktp__table-head__column span {
	font-size: 10px;
	font-weight: bold;
	color: #6c8796;
}

.b-ktp__table-head__column:nth-child(1) {
	width: 288px;
}

.b-ktp__table-head__column:nth-child(2) {
	width: 217px;
}

.b-ktp__table-head__column:nth-child(3) {
	width: 53px;

}

.b-ktp__table-head__column:nth-child(4) {
	width: 56px;
	/* font-size: 10px; */
}

.b-ktp__table-head__column:nth-child(5) {
	width: 62px;
}

.b-ktp__table-head__column:nth-child(6) {
	width: 196px;
}

.b-ktp__table-head__column:last-child {
	width: 102px;
	border: none;
}

.b-ktp__table-head__column:nth-child(8) {
	width: 67px;
	/* border-right: none; */
}

/*---*/
.b-ktp__table-body {
	border-bottom: 1px solid #cfd4d7;
}

.b-ktp__table-body__column {
	display: table-cell;
	border-right: 1px solid #cfd4d7;
	vertical-align: middle;
	padding: 10px 5px;
	/* min-height: 94px; */
	line-height: 12px;
	position: relative;
}

.b-ktp__table-body__column span {
	font-size: 12px;
	color: #6c8796;
}

.b-ktp__table-body__column:nth-child(1) {
	width: 288px;
}

.b-ktp__table-body__column:nth-child(2) {
	width: 217px;
}

.b-ktp__table-body__column:nth-child(3) {
	width: 53px;

}

.b-ktp__table-body__column:nth-child(4) {
	width: 56px;
}

.b-ktp__table-body__column:nth-child(5) {
	width: 62px;
}

.b-ktp__table-body__column:nth-child(6) {
	width: 196px;
}

.b-ktp__table-body__column:last-child {
	width: 90px;
	border: none;
}

.b-ktp__table-body__column:nth-child(8) {
	width: 72px;
	/* border-right: none; */
}

.b-ktp__table-body {
	border-bottom: 1px solid #cfd4d7;
}

.b-ktp__table-body__column .b-icon__remove {
	padding: 0px;
	background-position: 0px 0px;
	display: inline-block;
	/* position: absolute; */
	float: right;
	width: 20px;
	height: 20px;
	top: 1px;
	left: 50px;
}

.b-ktp__table-body__column .b-icon__edit {
	padding: 0px;
	background-position: 0px 0px;
	display: inline-block;
	/* position: absolute; */
	width: 20px;
	height: 20px;
	top: 1px;
	left: 28px;
}

.b-ktp__table-body__column .i-icon__copy-sm {
	/* position: absolute; */
	width: 20px;
	height: 20px;
	top: 1px;
	left: 5px;
}

.i-icon__copy-sm {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODQ3OTFERjIxODFGMTFFNUI0N0NGRjY3QjgzNDk4NTciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODQ3OTFERjMxODFGMTFFNUI0N0NGRjY3QjgzNDk4NTciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4NDc5MURGMDE4MUYxMUU1QjQ3Q0ZGNjdCODM0OTg1NyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4NDc5MURGMTE4MUYxMUU1QjQ3Q0ZGNjdCODM0OTg1NyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pjkri2IAAAE7SURBVHjarJRBbsIwEEVNE8qCSr0A+yoJxwCpa25Bu+IirMJdkKpeooWIPUcA0cIm/SP9SIM7sYPESE9KnPG3nRn/Xl3X7q4hghaIHCxBBX5JxbG8dZ4hVIASbMECZGBAMo5tmVMEBREz8APeQRo4WMocyZ2ZgtzZCUz8v+KxByN+m1C0sARLruoMQR1z8AWe+S5zyitBFkD+S9JhV83in3xOODfXglK5hWqh2K6SJodzpFBLLSjtkAUE/V05T1CqX2lB6bFBRDDxxrXgIzhrwbMMRgRdQPAJHG49ckhwDL7l+YEf1+C16201FpyCD6dWsNrGdehJu22Mxq4D+Nf1f2NHrl6rQwWvnmcOb2IAAWtLmdNuDipZKrZS9vUC+mDIUzT2JTnjqB8aBrsDF3AEm5jB9u7h+Dr+BBgAIi2VUMYxyToAAAAASUVORK5CYII=) no-repeat;
	background-position: 0px 0px;
	display: inline-block;
	width: 20px;
	height: 20px;
	cursor: pointer;
}

/*ÃÅ¡ÃÅ¾ÃÂÃâ€¢ÃÂ¦ ÃÂ¡ÃÅ¸ÃËœÃÂ¡ÃÅ¡ÃÂ ÃÅ¡ÃÂ¢ÃÅ¸*/
/*-------------------------------*/

.b-ktp__table-head {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
}

.b-ktp-table-head_column {
	display: inline-block;
	padding: 5px 0px;
}

.b-ktp-table-head_column:nth-child(1) {
	width: 438px;
	border-right: 2px solid #cfd4d7;
}

.b-ktp-table-head_column:nth-child(2) {
	width: 657px;
	padding-left: 10px;
}

.b-ktp-table-head_column:nth-child(2) .b-ktp__title-module {
	width: 110px;
}

.b-ktp__title-module {
	width: 138px;
}

.b-ktp-table-head_column > div.b-ktp__title-module,
.b-ktp-table-head_column > div.b-ktp__title-module_list {
	display: inline-block;
	vertical-align: top;
}

.b-ktp__title-module_list {
	width: 300px;
	line-height: 13px;
	padding: 3px 0px;
}

.b-ktp__title-module span {
	font-size: 11px;
	font-weight: bold;
	color: #6c8796;
}

.b-ktp__title-module_list span {
	font-size: 11px;
	font-weight: bold;
	color: #010101;
}

span.b-ktp__count-thems {
	font-size: 11px;
	font-weight: bold;
	color: #39b3ae;
}

.b-ktp__remove-module {
	background-position: 1px 3px;
}

/*-----------------------------*/

.b-ktp-table-h-body_column span {
	font-size: 11px;
	font-weight: bold;
	color: #6c8796;
}

.b-ktp-table-h-body_column {
	display: table-cell;
	border-bottom: 2px solid #cfd4d7;
	border-right: 2px solid #cfd4d7;
	line-height: 11px;
	text-align: center;
	padding: 10px 2px;
	vertical-align: middle;
}

.b-ktp-table-h-body_column:last-of-type {
	border-right: none;
}

.b-ktp-table-h-body_column:nth-child(1) {
	width: 137px;
}

.b-ktp-table-h-body_column:nth-child(2) {
	width: 47px;
}

.b-ktp-table-h-body_column:nth-child(3) {
	width: 68px;
}

.b-ktp-table-h-body_column:nth-child(4) {
	width: 74px;
}

.b-ktp-table-h-body_column:nth-child(5) {
	width: 160px;
	padding: 0px 0px;
}

.b-ktp-table-h-body_column:nth-child(6) {
	width: 216px;
}

.b-ktp-table-h-body_column:nth-child(7) {
	width: 197px;
}

.b-ktp-table-h-body_column:nth-child(8) {
	width: 74px;
}

.b-ktp-table-h-body_column:nth-child(9) {
	width: 110px;
}

.b-ktp-table-ht-body_title-column {
	border-bottom: 2px solid #cfd4d7;
}

.b-ktp-table-ht-body_title-column:first-of-type {
	padding-top: 5px;
	padding-bottom: 5px;
}

.b-ktp-table-ht-body_title-column:last-of-type {
	border-bottom: none;
}

.b-ktp-table-ht__duble {
	display: table-cell;
	width: 78px;
	text-align: center;
	padding: 3px 0px 2px 0px;
	height: 15px;
}

.b-ktp-table-ht__duble:first-of-type {
	border-right: 2px solid #cfd4d7;
}

/*-----check-------------*/
.b-ktp-t-control-classroom input[type="checkbox"] {
	display: none;
}

.b-ktp-t-control-classroom input[type="checkbox"] + label {
	cursor: pointer;

}

.b-ktp-t-control-classroom input[type="checkbox"] + label span {
	display: inline-block;
	width: 70px;
	height: 20px;
	vertical-align: middle;
	cursor: pointer;
}

.b-ktp-t-control-classroom input[type="checkbox"] + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODYzODY3QjZGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODYzODY3QjdGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4NjM4NjdCNEYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4NjM4NjdCNUYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuirhYcAAAEZSURBVHja7NQ9S0JRHMdxlVxEglwcjBuBSCC4CQpNQTVI6JCBi74EfRU6ubg6Ci4OSS0KRQ8GNvsS7isQBcEHuH4vHEHilPd6lRzOgQ8H7oXfef67DcNwOW0e1w7a4YQcJdstRwH9TPZ/l3OKV4S3DTnHJ65Q3SbkAj0R9I2C3ZAY3hES/Q2GdkLieEMQHaQw/rmx5jT7OJMEXOIFATwijYnsdCpIiA2LrP2/RhfHaOIBs9+O2NygL2j4QBR3eIYPdeSxkN5Y0Y9wi7YY3TwBP7yooQTDymWbiNGfcCICzGUW/wpYn8mqTXGPBgYoW3qAkm9z5DaNbuXt2Cp3blUe91RjdV13FKBpmqqxqsaqGqtqrKqx8rYUYAAMRWAd03EUCAAAAABJRU5ErkJggg==) top no-repeat;
	background-position: 27px -17px;
}

.b-ktp-t-control-classroom input[type="checkbox"]:checked + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODYzODY3QjZGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODYzODY3QjdGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4NjM4NjdCNEYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4NjM4NjdCNUYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuirhYcAAAEZSURBVHja7NQ9S0JRHMdxlVxEglwcjBuBSCC4CQpNQTVI6JCBi74EfRU6ubg6Ci4OSS0KRQ8GNvsS7isQBcEHuH4vHEHilPd6lRzOgQ8H7oXfef67DcNwOW0e1w7a4YQcJdstRwH9TPZ/l3OKV4S3DTnHJ65Q3SbkAj0R9I2C3ZAY3hES/Q2GdkLieEMQHaQw/rmx5jT7OJMEXOIFATwijYnsdCpIiA2LrP2/RhfHaOIBs9+O2NygL2j4QBR3eIYPdeSxkN5Y0Y9wi7YY3TwBP7yooQTDymWbiNGfcCICzGUW/wpYn8mqTXGPBgYoW3qAkm9z5DaNbuXt2Cp3blUe91RjdV13FKBpmqqxqsaqGqtqrKqx8rYUYAAMRWAd03EUCAAAAABJRU5ErkJggg==) top no-repeat;
	background-position: 27px 4px;
}

/*------end check---------*/
/*-----------------------------*/

/*--------------2------------------*/
.b-ktp-tb-columns {
	border-bottom: 1px solid #cfd4d7;
}

.b-ktp-tb-columns:last-of-type {
	border-bottom: none;
}

.b-ktp-table-body-column,
.b-ktp-table-h-body_column {
	display: table-cell;
	border-bottom: 2px solid #cfd4d7;
	border-right: 2px solid #cfd4d7;
	line-height: 11px;
	text-align: center;
	vertical-align: middle;
}

.b-ktp-table-body-column:last-of-type {
	border-right: none;
}

.b-ktp-table-body-column:nth-child(1) {
	width: 125px;
	text-align: left;
	border-left: 5px solid #96e5ff;
	padding-left: 5px;
}

.b-ktp-table-body-column:nth-child(2) {
	width: 51px;
}

.b-ktp-table-body-column:nth-child(3) {
	width: 918px;
	border-bottom: 2px solid #cfd4d7;
}

.b-ktp-table-body-column span {
	font-size: 12px;
	line-height: 11px;
	color: #454856;
	padding: 5px 0px;
	display: block;
}

.b-ktp-table__date {
	padding: 7px 0px;
	text-align: center;
	border-bottom: 1px solid #cfd4d7;
}

.b-ktp-table__metagroup,
.b-ktp-table__date:last-of-type {
	border-bottom: none;
}

.b-ktp-table__metagroup {
	display: block;
	height: 32px;
	position: relative;
}

.b-ktp-table__metagroup:hover,
.b-ktp-table__metagroup-active {
	background: #d7f0ef;
	cursor: pointer;
}

.b-ktp-table_thems-name {
	text-align: left;
	padding: 0px 5px;
}

.b-ktp-table__metagroup-list {
	width: 220px;
	position: absolute;
	left: 80px;
	top: 32px;
	background: #fff;
	border-top: 5px solid #39b3ae;
	box-shadow: 0px 0px 5px #cfd4d7;
	z-index: 1;
}

.b-ktp-table__home-work-list {
	width: 165px;
	position: absolute;
	left: 57px;
	top: 32px;
	background: #fff;
	border-top: 5px solid #39b3ae;
	box-shadow: 0px 0px 5px #cfd4d7;
	z-index: 1;
}

.b-ktp-table__metagroup-list .i_icon_close-big {
	position: relative;
	left: 90px;
	top: 2px;
}

.b-ktp-home-work-list,
.b-ktp-metagroup-list {
	text-align: left
}

.b-ktp-home-work-list dt,
.b-ktp-metagroup-list dt {
	padding: 10px 10px;
	font-size: 14px;
	font-weight: bold;
}

.b-ktp-home-work-list dd,
.b-ktp-metagroup-list dd {
	border-top: 1px solid #cfd4d7;
	padding: 10px 10px;
	color: #454856;
	font-size: 12px;
}

/*-----2-----*/
.b-ktp-tb-column {
	display: table-cell;
	vertical-align: middle;
	border-right: 2px solid #cfd4d7;
	height: 32px;
}

.b-ktp-tb-column:nth-child(1) {
	width: 71px;
}

.b-ktp-tb-column:nth-child(2) {
	width: 77px;
}

.b-ktp-tb-column:nth-child(3) {
	width: 157px;
}

.b-ktp-tb-column:nth-child(4) {
	width: 215px;
}

.b-ktp-tb-column:nth-child(5) {
	width: 195px;
}

.b-ktp-tb-column:nth-child(6) {
	width: 78px;
}

.b-ktp-tb-column:nth-child(7) {
	width: 113px;
	border-right: none;
}

.b-ktp-t-control-classroom {
	display: table-cell;
	vertical-align: middle;
	width: 79px;
	height: 32px;
	border-right: 1px solid #cfd4d7;
}

.b-ktp-t-control-classroom:last-of-type {
	border-right: none;
}

/*---end 2----*/

.b-ktp-tb__home-work {
	display: table-cell;
	vertical-align: middle;
	width: 79px;
	height: 32px;
	border-right: 1px solid #cfd4d7;
	position: relative;
}

.b-ktp-tb__home-work:last-of-type {
	border-right: none;
}

.b-ktp-tb__home-work span {
	display: inline-block;
	text-decoration: underline;
	font-size: 11px;
	font-weight: bold;
}

.b-ktp-tb__home-work .i-icon__home-work {
	position: relative;
	top: 4px;
}

.b-ktp-tb__home-work:hover,
.b-ktp-tb-hw__active {
	background: #d7f0ef;
}

.b-ktp-tb-hw__activity span {
	color: #39b3ae;
}

.b-ktp-tb-hw__activity .i-icon__home-work {
	background-position: -6px -38px;
}

.b-ktp-table-body-footer {
	background: #f8f9fa;
}

.b-ktp__count,
.b-ktp__adding-modules {
	display: inline-block;
	padding: 5px 10px 10px 10px;
}

.b-ktp__adding-modules {
	width: 370px;
}

.b-ktp__adding-modules .b-icon__add {
	position: relative;
	top: 5px;
}

.b-ktp__add-mod {
	color: #39b3ae;
	font-size: 14px;
	text-decoration: none;
	border-bottom: 1px dashed #39b3ae;
	cursor: pointer;
}

/***/
.i_icon_close-big {
	cursor: pointer;
	display: inline-block;
	height: 16px;
	width: 16px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDBGMzQzQjVFRjMxMTFFNEJENzZDQ0QzQUM5MjgxNEMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDBGMzQzQjZFRjMxMTFFNEJENzZDQ0QzQUM5MjgxNEMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMEYzNDNCM0VGMzExMUU0QkQ3NkNDRDNBQzkyODE0QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMEYzNDNCNEVGMzExMUU0QkQ3NkNDRDNBQzkyODE0QyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgjL8iIAAACFSURBVHjapFNbDoAgDJvcgZ2Iy8uvfHgbRDN0xgGNLOkPaxv2InpiITw+3FCwFnhAfHKiaK5w8pALtoHJmUvCjfonrBItEy1OFoc7hKG4ZwKLLZNdAIu1SRVWI1PsGgaZJmKqBKuJPDMBJIcRehy9yqM6ubXKQR482OTXMf0553v8hwADACvtQRAxi4mUAAAAAElFTkSuQmCC) no-repeat;
}

.i-icon__metagroup {
	display: block;
	height: 20px;
	width: 22px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTEwODk2NDVFRjRCMTFFNEIwQTE4M0VFOTY0QzMxNjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTEwODk2NDZFRjRCMTFFNEIwQTE4M0VFOTY0QzMxNjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxMTA4OTY0M0VGNEIxMUU0QjBBMTgzRUU5NjRDMzE2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxMTA4OTY0NEVGNEIxMUU0QjBBMTgzRUU5NjRDMzE2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtoyAk0AAAGXSURBVHjatJW9LwRBFMAt5+suhJBIfBQoFBQSkrMhVKKTU2j8ASrhEtHoaSnIRYlQUFjRXSPR3F/gIxJRKYjvSiG3fi95Yu3d7o2cm+SXNzNv3tuZ997MWq7rlpWiWSaObeewD3EG1TCVSUynC9mUG25gBBohChMmBqaOrwP6RTs+hQ/4hJ1/cUx8uxEpqIIIrDLXUVTycBDVo7f5VA/QQxJfg2wjBT4sCduHLmjWOXH2BO3az99kx/kYOjqwwA7RxyAepA+L8SJsEI6KAP0ApLXGzWLMYhtxAnHieBOSgyRiHgZZ92xSFXUwG+ZUGvp1xDY0he6YHcjCXtjEyDGpV2wmEXNwCUns3F9VwYJOxIIOG8AxvDxLeuXHYQsu/KF4lyLx9E3bi6f/lhNjjiC1eaXD3T84PlZ5jo+7HMeEYlhDIK2fca1BfFsRozqs13D+JI+JZforPrt7sWUXt+j36Mc8p0swJ0nOaAV9t6wkEn0q4nlvxVGLjuUlsyABa3qtK30fHoNHqFFdVmM8I4+WVapf05cAAwDW37tHAginrgAAAABJRU5ErkJggg==) no-repeat;
}

.i-icon__metagroup-position {
	position: relative;
	top: 5px;
	left: 25px;
}

.i-icon__sc-lesson {
	cursor: pointer;
	display: inline-block;
	height: 20px;
	width: 20px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAABjCAYAAAD+Q9ILAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDRFMkUzODJGMkY4MTFFNDgyMTZGNTY5OTJEMjQ0NTgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDRFMkUzODNGMkY4MTFFNDgyMTZGNTY5OTJEMjQ0NTgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpENEUyRTM4MEYyRjgxMUU0ODIxNkY1Njk5MkQyNDQ1OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpENEUyRTM4MUYyRjgxMUU0ODIxNkY1Njk5MkQyNDQ1OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pu5QHFQAAAIZSURBVHja7JjNS0JBFMWzb1pE7SOwTYsWFbUq6A8oF/UPuDdw0yapaJsQtJGQ2lXLFoUJbYuoIKKvjdAmEZdFpNAHRq8zMMJTHjpSM++J58JxVK7Ob+6MF+f4LMtq8kI0N3kkCEIQghDkr9GqmhiOxoMYdmr47nwsEur+dxDEkhz9Crl90JmWiiAGxQNWmVaonr6tcZhMfDYEtdje3gPoi9HDigm/MZxCJzbltB9Wh4qIRfSULaYNKhgFQfigXjkWox16Nw3yA6XLQD6Mb408pMNlW5OBvnSDFOQZsB/WXTda/KcnWnyxX+DXYrkN0gltQ2sKufvQtS4QsY2H1Vo8KiYqNwYFdYHcQ5OYKKWQm4WmoJQOkEXoCFpWzF+HtpS7I296BCEIQQhCEIJ41Q2YSB7U7AZcBOYawA3AKtMK1TN3wcJkjm4AQM26AZjQG24AKkI3gG5ARTdAHla6AeLXQjegxA2o1uJRMTNuACaiG8C/igQhCEEIQhCCEKQhQGq6YIWj8TcMlcyXS2gmFgm96q6IgFiARh10Aw1B5wDuN3HlfMKK7xyqlcdwDA2IiyBeTyPvwa0zMg49yudXbh3WDSgJPUO3UIcpN6AksA0JDAm5TbMYAkZAMJnwRTahLtvbKwDKGvVHMKG4EM270kcUKrIKwExdV6Q+W7wMP7ZlpFqObpCc7BequcpBN4AgBCEIQQjSMCC/AgwATkWtYPNighgAAAAASUVORK5CYII=) no-repeat;
	background-position: -8px -69px;
}

.i-icon__home-work {
	cursor: pointer;
	display: inline-block;
	height: 20px;
	width: 20px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAABjCAYAAAD+Q9ILAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDRFMkUzODJGMkY4MTFFNDgyMTZGNTY5OTJEMjQ0NTgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDRFMkUzODNGMkY4MTFFNDgyMTZGNTY5OTJEMjQ0NTgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpENEUyRTM4MEYyRjgxMUU0ODIxNkY1Njk5MkQyNDQ1OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpENEUyRTM4MUYyRjgxMUU0ODIxNkY1Njk5MkQyNDQ1OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pu5QHFQAAAIZSURBVHja7JjNS0JBFMWzb1pE7SOwTYsWFbUq6A8oF/UPuDdw0yapaJsQtJGQ2lXLFoUJbYuoIKKvjdAmEZdFpNAHRq8zMMJTHjpSM++J58JxVK7Ob+6MF+f4LMtq8kI0N3kkCEIQghDkr9GqmhiOxoMYdmr47nwsEur+dxDEkhz9Crl90JmWiiAGxQNWmVaonr6tcZhMfDYEtdje3gPoi9HDigm/MZxCJzbltB9Wh4qIRfSULaYNKhgFQfigXjkWox16Nw3yA6XLQD6Mb408pMNlW5OBvnSDFOQZsB/WXTda/KcnWnyxX+DXYrkN0gltQ2sKufvQtS4QsY2H1Vo8KiYqNwYFdYHcQ5OYKKWQm4WmoJQOkEXoCFpWzF+HtpS7I296BCEIQQhCEIJ41Q2YSB7U7AZcBOYawA3AKtMK1TN3wcJkjm4AQM26AZjQG24AKkI3gG5ARTdAHla6AeLXQjegxA2o1uJRMTNuACaiG8C/igQhCEEIQhCCEKQhQGq6YIWj8TcMlcyXS2gmFgm96q6IgFiARh10Aw1B5wDuN3HlfMKK7xyqlcdwDA2IiyBeTyPvwa0zMg49yudXbh3WDSgJPUO3UIcpN6AksA0JDAm5TbMYAkZAMJnwRTahLtvbKwDKGvVHMKG4EM270kcUKrIKwExdV6Q+W7wMP7ZlpFqObpCc7BequcpBN4AgBCEIQQjSMCC/AgwATkWtYPNighgAAAAASUVORK5CYII=) no-repeat;
	background-position: -6px -8px;
}

/*right panel*/
.b-ktp__btns-fields:first-of-type {
	/* margin-top: 25px; */
}

.b-ktp__btn {
	cursor: pointer;
	margin-top: 10px;
	text-align: center;
	padding: 15px 8px 15px 8px;
}

/*end right panel*/

.b-djs__body-columns {
	border-bottom: 1px solid #cfd4d7
}

.b-djs__body-col-title span {
	margin-left: 5px;
}

.b-djs__body-column {
	border-right: 1px solid #cfd4d7
}

.b-djs__body-columns:last-of-type {
	border-bottom: none;
}

.b-djs__body-column:nth-child(1) {
	width: 200px;
}

.b-djs__body-column:nth-child(2) {
	width: 191px;
}

.b-djs__body-column:nth-child(3) {
	width: 168px;

}

.b-djs__body-column:nth-child(5), .b-djs__body-column:nth-child(6), .b-djs__body-column:nth-child(7) {
	padding: 0;
	width: 88px;
	height: 31px;
	/*padding: 0px 5px;*/
}

.b-djs__body-column:nth-child(7) {
	width: 32px;
	/* border-right: none
 */
}

.b-djs__body-column {
	vertical-align: top;
	display: table-cell;
	height: 32px
}

.b-djs__head-column {
	vertical-align: top;
	display: table-cell;
	padding: 5px;
	height: 49px;
}

.b-djs-th__conteiner-column {
	padding: 5px 5px;
	border-bottom: 1px solid #cfd4d7;
	height: 43px;
	min-width: 88px;
	overflow-y: scroll;
	overflow-x: hidden;
	/* position: relative; */
}

.b-djs-th__conteiner-column:last-of-type {
	border-bottom: none;
}

.b-ktp__table-head__column:nth-child(9) {
	width: 32px;
	border-right: none;
}

.b-ktp__table-body__column:nth-child(9) {
	width: 46px;
	border: none;
}

.lesson-plans-list-item {
	border-bottom: 1px solid #cfd4d7;
	padding: 8px 0px;
	min-height: 21px;
}

.lesson-plans-list-item:last-child {
	border: none;
}

.ktp_group_select {
	/* margin-bottom: 10px; */
	padding-right: 25px;
	height: 34px;
	cursor: pointer;
	width: 203px;
	border: none;
	text-indent: 0.01px;
	text-overflow: '';
	-ms-appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none !important;
	background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAjCAYAAABy3+FcAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQyREZEMDRCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQyREZEMDVCREIwMTFFNEFFMjc5RDRERjNCMDFCMDAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDJERkQwMkJEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDJERkQwM0JEQjAxMUU0QUUyNzlENERGM0IwMUIwMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsUpwQgAAAGCSURBVHja7NbNKwRhHMDxmTFeEq1yd1dcHMQWKQcXF4SkHJCXFOWyB2pLWUWKk0JKRC6EzVty9W+Iv8BBeW18f/Wbmqadl9297lOf3dqZ/e6zu9PzjNl+ff5oGEYZevFhxB/yniO0WTzcowu3qMkjcIIR3ElkA8vowE2MkBsYwj7mLD2wGjMkgVMN7GEKjuU5QUJLISE3MKiBaQnIAct3YiYgFBjIFckVSoQFZNgB3z2jJ8rzC+qwixl/ICwiY80TDAzIMLnYoq6JBrwFBaJm4o7XqBNMx3GMYkcpUoqUIoUNO5m9KDpiRRyv18WootCIBJ6wo/uMnW9E1tQHNOMZAzjWVT9WJKFbawsWddU/xDAOcoX8U6zVbaIVKWzp6xOowhh+Meldc72RamSRRBrrnmN/GNVZjOMHs27I9gSu0IkV5R9uqFw3sC8suL9JJWTf6NZPT4f8Y9+6mcttyDw23ZmcoUe/fyrGtSWhflzqD/8uM2nEtr4Qd3yiT25w0PQvwACzO3vwoQqrrAAAAABJRU5ErkJggg==) no-repeat 94% 14px;
}

.ktp_group_select_box {
	position: absolute;
	top: -165px;
	width: 203px;
	height: 34px;
	right: 9px;
	-webkit-box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.3);
	box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.3);
}

.ktp-slider-wrapper {
	width: 350px;
	overflow: hidden;
	vertical-align: top;
}

.ktp-slider {
	width: 10000px;
	position: relative;
	transition: left 0.2s ease-in-out;
}

.group_lesson_block {
	display: inline-block;
	width: 174px;
	vertical-align: top;
	border-right: 1px solid #cfd4d7;
}

.ktp-slider-wrapper .controls {
	position: absolute;
	background-color: #d5d5d5;
	padding: 20px 0;
}

.ktp-slider-wrapper .controls a {
	text-decoration: none;
}

.controls.left {
	left: 727px;
}

.controls.right {
	right: 0;
}

.teacher-select {
	margin: 20px 0 0 0 !important;
	padding: 0;
}

.attension {
	color: crimson;
	font-size: 120%;
	margin: 20px 0;
}

.b-ktp__table-body__column:nth-child(7) {
	width: 67px;
}

.kp-controls {
	position: absolute;
	top: -11px;
	width: 23px;
	height: 53px;
}

.kp-controls-global {
	left: -30px;
}

.kp-controls-local {
	right: -35px;
}

.kp-controls .up,
.kp-controls .down {
	cursor: pointer;
	display: block;
	text-align: center;
	position: absolute;
	top: 4px;
}

.kp-controls .down {
	/* margin-bottom: 10px; */
	bottom: 0;
	top: 34px;
}

.on-dragging .kp-controls {
	display: none;
}

.on-drag-hover {
	background: #e0e0e0;
}

.on-drag-enter {
	background: #eeeeee;
}

.btn-default {
	border: 1px solid #ededed;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	display: block;
	margin: 5px;
	padding: 5px;
	color: #39B3AE;
}

.btn-default:hover {
	background: #D3DBDE;
}
