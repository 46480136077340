.help__btn {
  position: absolute;
}

.square {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  border: 1px solid #333;
  border-radius: 4px;
}

.square--remote {
  background-color: #8FBCDB;
}

.square--electronic {
  background-color: #F8E4CC;
}
