@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.ezd-alert-dialog {
  min-width: 400px;
  max-width: 600px;
}
.ezd-alert-dialog header {
  padding: 5px 13px;
}
.ezd-alert-dialog header span {
  color: #666;
}
.ezd-alert-dialog header md-icon {
  color: #666;
  cursor: pointer;
  margin: 0;
}
.ezd-alert-dialog header md-icon > svg {
  fill: #666;
}
.ezd-alert-dialog main .main-icon {
  padding: 38px;
}
.ezd-alert-dialog main .main-icon img {
  max-height: 100px;
  max-width: 100px;
}
.ezd-alert-dialog main .main-text {
  padding: 0 56px;
  text-align: center;
}
.ezd-alert-dialog main .cause {
  padding-top: 18px;
}
.ezd-alert-dialog main .cause .cause-wrapper {
  padding: 20px 0 25px;
  width: 312px;
  background-color: #fafafa;
}
.ezd-alert-dialog main .cause .cause-wrapper .cause-title {
  color: #E00000;
  padding-bottom: 18px;
}
.ezd-alert-dialog main .controls {
  padding: 32px 0 40px;
}
.ezd-alert-dialog main .controls :global(.md-button),
.ezd-alert-dialog main .controls :global(.md-button:hover) {
  text-transform: none;
  color: #fff;
  min-height: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  margin: 0px 20px 0px 20px;
}