.old_date {
  height: 159px;
  background: var(--side-bar-color1);
  position: relative;
}
.old_date span:nth-of-type(1) {
  color: #fff;
  font-size: 46px;
  line-height: 44px;
  font-weight: bold;
  margin-top: 42px;
  margin-bottom: 23px;
}
.old_date span:nth-of-type(2) {
  text-transform: capitalize;
  color: var(--font-color2);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.old_date span:nth-of-type(3) {
  text-transform: capitalize;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
}
.old_date .go_back__btn,
.old_date .menu-closer {
  position: absolute;
  top: 10px;
  cursor: pointer;
}
.old_date .go_back__btn:active, .old_date .go_back__btn:focus,
.old_date .menu-closer:active,
.old_date .menu-closer:focus {
  outline: none;
}
.old_date .go_back__btn md-icon,
.old_date .menu-closer md-icon {
  min-height: 0;
  min-width: 0;
  width: 20px;
  height: 20px;
  color: #95b1be;
}
.old_date .go_back__btn {
  left: 5px;
}
.old_date .menu-closer {
  right: 5px;
}
.old_date .menu-closer {
  position: absolute;
  top: 10px;
  right: 5px;
  cursor: pointer;
}
.old_date .menu-closer:active, .old_date .menu-closer:focus {
  outline: none;
}
.old_date .menu-closer md-icon {
  min-height: 0;
  min-width: 0;
  width: 20px;
  height: 20px;
  color: #95b1be;
}

:global(.closed-menu) .old_date {
  height: 159px;
  background: #4c626c;
  position: relative;
}
:global(.closed-menu) .old_date span:nth-of-type(2) {
  font-size: 11px;
}
.current-date {
  width: 128px;
  color: #fff;
  background: var(--side-bar-color1);
  padding: 16px 8px;
}
.current-date .date {
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
}
.current-date .month-and-day {
  font-size: 14px;
  font-weight: 400;
}