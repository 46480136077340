.ezd-square-radio-buttons-container {
  display: flex;
  flex-wrap: nowrap;
  background-color: #f5f5f5;
  border-radius: 3px;
  height: 36px;
}
.ezd-square-radio-buttons-container .ezd-square-radio-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 18px;
  border-radius: 3px;
}
.ezd-square-radio-buttons-container .ezd-square-radio-button:hover {
  cursor: pointer;
}
.ezd-square-radio-buttons-container .ezd-square-radio-button span {
  color: #333;
  font-size: 15px;
}
.ezd-square-radio-buttons-container .is-active {
  background-color: var(--color-button-5);
}
.ezd-square-radio-buttons-container .is-active span {
  color: #fff;
}