ezd-button {
  display: block;
}

.button, .transparent, .default, .green, .blue, .white, .orange {
  position: relative;
  font-size: 14px;
  padding: 0 20px;
  height: 32px;
  max-height: 32px;
  border-radius: 2px;
  border: 1px solid transparent;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  user-select: none;
  line-height: 30px;
  text-decoration: none;
  display: inline-block;
}
.button:hover, .transparent:hover, .default:hover, .green:hover, .blue:hover, .white:hover, .orange:hover {
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.49);
}
.button:active, .transparent:active, .default:active, .green:active, .blue:active, .white:active, .orange:active {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
}
.button:disabled, .transparent:disabled, .default:disabled, .green:disabled, .blue:disabled, .white:disabled, .orange:disabled {
  opacity: 0.5;
  box-shadow: none;
  pointer-events: none;
}
.button md-icon, .transparent md-icon, .default md-icon, .green md-icon, .blue md-icon, .white md-icon, .orange md-icon {
  color: currentColor;
  min-width: 1em;
  min-height: 1em;
  height: 1em;
  width: 1em;
  margin-right: 8px;
  margin-top: -2px;
}

.orange {
  color: #fff;
  background: #fd6a2a;
}
.orange:hover {
  border-color: #b23a18;
  background: #e04619;
}
.orange:active {
  border-color: #b84321;
  background: #c53112;
}

.white {
  color: #333;
  background: #fff;
}
.white:hover {
  border-color: #e6e6e6;
  background: #fafafa;
}
.white:active {
  border-color: #e6e6e6;
  background: #fafafa;
}

.blue {
  color: #fff;
  background: #738fa0;
}
.blue:hover {
  border-color: #467a99;
  background: #58788c;
}
.blue:active {
  border-color: #28485c;
  background: #3e667e;
}

.green {
  color: #fff;
  background: #00838f;
}
.green:hover {
  border-color: #008491;
  background: #008491;
}
.green:active {
  border-color: #005e66;
  background: #007580;
}

.default {
  background: #fff;
  color: #333;
  border: 1px solid #bbbbbb;
  box-shadow: none;
}
.default:hover {
  border-color: var(--link-color);
  color: var(--link-color);
  box-shadow: none;
}
.default:active {
  box-shadow: none;
}
.default:disabled {
  color: #999;
  background-color: #eeeeee;
}

.transparent {
  background: none;
  color: #333;
  box-shadow: none;
}
.transparent:hover {
  background: #e6e6e6;
  box-shadow: none;
}
.transparent:active {
  box-shadow: none;
}
.transparent:disabled {
  color: #999;
  background-color: #eeeeee;
}