.calendar {
  padding: 0 27px 8px 27px;
  text-align: center;
}
.calendar md-icon {
  min-width: 0;
  min-height: 0;
  width: 14px;
  height: 14px;
}
.calendar :global(.head) {
  border-bottom: 1px solid rgba(238, 238, 238, 0.5);
}
.calendar :global(.head) :global(.md-icon-button) {
  margin: 0;
}
.calendar :global(.head) > div {
  width: 35px;
  height: 22px;
  border-right: 1px solid rgba(238, 238, 238, 0.5);
  color: var(--link-color);
  font-size: 13px;
  font-weight: 700;
}
.calendar :global(.head) > div:last-of-type {
  color: #ff5126;
  border-right: none;
}
.calendar :global(.body) {
  height: 55px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.5);
}
.calendar :global(.body):last-of-type, .calendar :global(.body):last-of-type > div {
  border-bottom: none;
}
.calendar :global(.body) > div {
  position: relative;
  width: 35px;
  border-right: 1px solid rgba(238, 238, 238, 0.5);
}
.calendar :global(.body) > div:last-of-type:not(:first-of-type) {
  border-right: none;
}
.calendar :global(.body) > div :global(.md-button) {
  min-width: 0;
  min-height: 0;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  line-height: 27px;
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 400;
  color: #546e7a;
}
.calendar :global(.body) > div :global(.md-button.active) {
  background: #00838f;
  color: #fff;
}
.calendar :global(.body) > div :global(.icons-container) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 13px;
}
.calendar :global(.body) > div :global(.b-i-invisible) {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: 2px;
  border-radius: 50%;
}
.calendar :global(.controls) {
  margin-bottom: 29px;
  text-transform: capitalize;
}

.month {
  color: #434343;
  font-size: 17px;
  font-weight: 700;
}

.test-lesson-icon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  font-weight: 500;
  color: #ff5126;
}