@import "~styles/colors";

pagination {
  display: flex;
  position: static;
  justify-content: center;
  align-items: center;

  :global(.md-button.md-icon-button) {
    padding-top: 8px !important;
    margin: 0;
    flex: 0 0 auto;

    svg {
      fill: $white;
    }

    &:disabled {
      svg {
        fill: $light-grey;
      }
    }
  }

  .page-num {
    font-size: 17px;
    color: $white;
    text-align: center;

    :global(.small-input) {
      width: 20px;
      border: none;
      text-align: center;
      font-size: 1em;
      font-family: 'Open Snas', sans-serif;
    }
  }

}

