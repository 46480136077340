@import "~styles/colors";

.criterion-input-container {
	.criterion-input {
		width: 100%;
		text-align: center;
		box-sizing: border-box;
		margin-left: -4px;
		margin-top: -4px;
	}

	.span-model {
		border-bottom: 1px dotted $grey;
		cursor: pointer;
	}

	.not-a-number {
		line-height: 22px;
		margin: 5px;
	}
}
