.popup-container {
	background: rgba(174, 174, 174, .1);
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	display: flex;
	z-index: 10000;
}

.popup-container .b-pop-up__title {
	width: calc(100% - 50px);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
