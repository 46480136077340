@import "~styles/colors";

ezd-button {
	display: block;
}

.button {
	position: relative;
	font-size: 14px;
	padding: 0 20px;
	height: 32px;
	max-height: 32px;
	border-radius: 2px;
	border: 1px solid transparent;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
	user-select: none;
	line-height: 30px;
	text-decoration: none;
	display: inline-block;

	&:hover {
		cursor: pointer;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.49);
	}

	&:active {
		box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
	}

	&:disabled {
		opacity: 0.5;
		box-shadow: none;
		pointer-events: none;
	}

	md-icon {
		color: currentColor;

		min-width: 1em;
		min-height: 1em;
		height: 1em;
		width: 1em;

		margin-right: 8px;
		margin-top: -2px;
	}
}

.orange {
	@extend .button;

	color: $white;
	background: $orange;

	&:hover {
		border-color: #b23a18;
		background: #e04619;
	}

	&:active {
		border-color: #b84321;
		background: #c53112;
	}
}

.white {
	@extend .button;

	color: $black;
	background: $white;

	&:hover {
		border-color: $light-border;
		background: $light-bg;
	}

	&:active {
		border-color: $light-border;
		background: $light-bg;
	}
}

.blue {
	@extend .button;

	color: $white;
	background: #738fa0;

	&:hover {
		border-color: #467a99;
		background: #58788c;
	}

	&:active {
		border-color: #28485c;
		background: #3e667e;
	}
}

.green {
	@extend .button;

	color: $white;
	background: $green;

	&:hover {
		border-color: $light-green;
		background: $light-green;
	}

	&:active {
		border-color: #005e66;
		background: #007580;
	}
}

.default {
	@extend .button;

	background: $white;
	color: $black;
	border: 1px solid #bbbbbb;
	box-shadow: none;

	&:hover {
		border-color: var(--link-color);
		color: var(--link-color);
		box-shadow: none;
	}

	&:active {
		box-shadow: none;
	}

	&:disabled {
		color: #999;
		background-color: #eeeeee;
	}
}

.transparent {
	@extend .button;

	background: none;
	color: $black;
	box-shadow: none;

	&:hover {
		background: $light-border;
		box-shadow: none;
	}

	&:active {
		box-shadow: none;
	}

	&:disabled {
		color: #999;
		background-color: #eeeeee;
	}
}
