@import "~styles/colors";

.mask-date-picker {

	.ezd-input-container {
		top: 0;
		left: 0;
		display: flex;
		position: relative;
		max-width: 160px;

		& > div {
			border: 1px solid $disabled-border;
			border-radius: 2px;

			&:invalid:not(.ng-untouched), &.ng-invalid:not(.ng-untouched) {
				border: 1px solid $red;
			}

			&:not(:disabled) {
				&:hover:not(.invalid-date), &:focus {
					border: 1px solid var(--menu-color);
				}
			}
		}

		.invalid-date {
			border: 1px solid $red !important;
		}

		.selected-element {
			border: 1px solid var(--menu-color);
		}

		md-icon {
			position: absolute;
			right: 8px;
			top: 18%;
			height: 60%;
			display: flex;
			align-items: center;
			z-index: 0;
			cursor: text;
		}
	}

	.ezd-input {
		border-radius: 2px;
		box-shadow: inset 0 1px 2px 0 rgba(57, 103, 109, 0.2);
		border: none;
		box-sizing: border-box;
		height: 32px;
		font-size: 15px;
		padding: 0 8px;
		width: 160px;
		max-width: 160px;
	}
}

.calendar-container {
	max-height: 350px;
	padding: 1px 1px 3px;
}
