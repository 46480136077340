@import "~styles/colors";

.checkbox {
	display: inline-block;

	input[type=checkbox] {
		display: none;

		& + label {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			user-select: none;

			span {
				width: auto;
				height: auto;
				background: none;
			}

			&::before {
				content: "";
				background-image: linear-gradient(to bottom, #ffffff, #f3f8fb);
				border: solid 1px #ccd6da;
				border-radius: 2px;
				width: calc(1em + 2px);
				height: calc(1em + 2px);
				min-height: calc(1em + 2px);
				min-width: calc(1em + 2px);
				margin-right: 8px;
			}

			&:hover {
				&::before {
					box-shadow: 0 0 0 2px rgba(0, 174, 197, 0.1);
				}
			}
		}

		&:checked + label {
			&::before {
				background: var(--color-button-5) url("./check.svg") no-repeat center;
			}
			span {
				background: none;
			}
		}

		&:disabled + label {
			span {
				opacity: 0.5;
			}

			&:hover::before {
				box-shadow: none;
			}
		}

		&:not(:checked):disabled + label::before {
			background: #e8e8e8;
			border: solid 1px #ccd6da;
		}

		&:checked:disabled + label::before {
			opacity: 0.5;
		}

		&:invalid + label::before {
			background-image: linear-gradient(to bottom, #ffffff, #f3f8fb);
			border: solid 1px #ff501d;
		}
	}
}

.checkbox:global(.small) {
	input[type=checkbox] {
		& + label {
			font-size: 12px;
			color: $grey;

			&::before {
				width: 13px;
				height: 13px;
			}
		}
	}
}

.checkbox:global(.thin) {
	input[type=checkbox] {
		& + label {
			&::before {
				background: transparent;
			}
		}

		&:checked + label {
			color: var(--link-color);

			&::before {
				background-color: var(--link-color);
				-webkit-mask: url("images/icons/i-check-thin.svg") no-repeat 50% 50%;
				mask: url("images/icons/i-check-thin.svg") no-repeat 50% 50%;
			}
		}

		&:checked:disabled + label::before {
			opacity: 0.5;
		}
	}
}

.checkbox:global(.thin_dis) {
	input[type=checkbox] {
		& + label {
			&::before {
				background-image: none;
				background-color: $grey;
				-webkit-mask: url("../ezd_checkbox/check.svg") no-repeat 50% 50%;
				mask: url("../ezd_checkbox/check.svg") no-repeat 50% 50%;
			}
		}

		&:checked + label {
			color: var(--link-color);

			&::before {
				background-color: var(--link-color);
				-webkit-mask: url("images/icons/i-check-thin.svg") no-repeat 50% 50%;
				mask: url("images/icons/i-check-thin.svg") no-repeat 50% 50%;
			}
		}
	}
}

