@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.map-popup {
  box-sizing: border-box;
}
.map-popup * {
  box-sizing: border-box;
}
.map-popup header {
  color: #fff;
  height: 32px;
  background: linear-gradient(0deg, var(--light-gradient-start), var(--light-gradient-end));
  font-size: 15px;
  padding-left: 16px;
}
.map-popup header > button.popup__close-btn {
  padding: 0 6px;
}
.map-popup header > button.popup__close-btn md-icon {
  color: #fff;
}
.map-popup main.map-popup-main .popup__content .content__map-viewport {
  height: 300px;
  background-color: #b3b3b3;
}
.map-popup main.map-popup-main .popup__content .content__map-address {
  padding: 32px;
}
.map-popup main.map-popup-main .popup__content .content__map-address p.address__hint {
  color: #666;
}
.map-popup main.map-popup-main .popup__content .content__map-address .address__content .content__title {
  min-width: 40px;
  margin-right: 32px;
  color: #666;
}
.map-popup main.map-popup-main .popup__content .content__map-address .address__content .content .btn__wrapper {
  position: relative;
  width: 52px;
}
.map-popup main.map-popup-main .popup__content .content__map-address .address__content .content .btn__wrapper button:global(.md-button).content__remove-btn {
  position: absolute;
  left: 6px;
  top: 50%;
  min-width: 40px;
  max-height: 40px;
  padding: 0 6px;
  transform: translateY(-50%);
}
.map-popup main.map-popup-main .popup__content .content__map-address .address__content .content .btn__wrapper button:global(.md-button).content__remove-btn md-icon {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  color: #fd6a2a;
}
.map-popup main.map-popup-main .buttons__row {
  padding: 14px 24px;
  border-top: 1px solid #e6e6e6;
}
.map-popup main.map-popup-main .buttons__row button.btn-save {
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  height: 40px;
  min-width: 112px;
  letter-spacing: normal;
  padding: 0 16px;
  background-blend-mode: overlay;
  width: 120px;
  height: 32px;
  color: #fff;
}
.map-popup main.map-popup-main .buttons__row button.btn-save md-icon {
  color: #fd6a2a;
}
.map-popup main.map-popup-main .buttons__row button.btn-save:not(:global(.md-raised)) {
  color: #fd6a2a;
}
.map-popup main.map-popup-main .buttons__row button.btn-save:global(.md-raised) {
  background-color: #fd6a2a;
}
.map-popup main.map-popup-main .buttons__row button.btn-save[disabled] {
  background-color: rgba(179, 179, 179, 0.5);
}
.map-popup main.map-popup-main .buttons__row button.btn-save[disabled]:global(.md-raised) {
  background-color: #b3b3b3;
}
.map-popup main.map-popup-main .buttons__row button.btn-save:hover:global(.md-raised), .map-popup main.map-popup-main .buttons__row button.btn-save:active:global(.md-raised), .map-popup main.map-popup-main .buttons__row button.btn-save:focus:global(.md-raised) {
  filter: brightness(85%);
  background-color: #fd6a2a;
}
.map-popup main.map-popup-main .buttons__row button.btn-save:hover:global(.md-raised)[disabled], .map-popup main.map-popup-main .buttons__row button.btn-save:active:global(.md-raised)[disabled], .map-popup main.map-popup-main .buttons__row button.btn-save:focus:global(.md-raised)[disabled] {
  background-color: #b3b3b3;
}
.map-popup main.map-popup-main .buttons__row button.btn-cancel {
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  height: 40px;
  min-width: 112px;
  letter-spacing: normal;
  padding: 0 16px;
  background-blend-mode: overlay;
  width: 120px;
  height: 32px;
}
.map-popup main.map-popup-main .buttons__row button.btn-cancel md-icon {
  color: #666;
}
.map-popup main.map-popup-main .buttons__row button.btn-cancel:not(:global(.md-raised)) {
  color: #666;
}
.map-popup main.map-popup-main .buttons__row button.btn-cancel:global(.md-raised) {
  background-color: #666;
}
.map-popup main.map-popup-main .buttons__row button.btn-cancel[disabled] {
  background-color: rgba(179, 179, 179, 0.5);
}
.map-popup main.map-popup-main .buttons__row button.btn-cancel[disabled]:global(.md-raised) {
  background-color: #b3b3b3;
}
.map-popup main.map-popup-main .buttons__row button.btn-cancel:hover:global(.md-raised), .map-popup main.map-popup-main .buttons__row button.btn-cancel:active:global(.md-raised), .map-popup main.map-popup-main .buttons__row button.btn-cancel:focus:global(.md-raised) {
  filter: brightness(85%);
  background-color: #666;
}
.map-popup main.map-popup-main .buttons__row button.btn-cancel:hover:global(.md-raised)[disabled], .map-popup main.map-popup-main .buttons__row button.btn-cancel:active:global(.md-raised)[disabled], .map-popup main.map-popup-main .buttons__row button.btn-cancel:focus:global(.md-raised)[disabled] {
  background-color: #b3b3b3;
}