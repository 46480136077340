.b-gi__control-works {
	display: inline-block;
	margin-bottom: 30px;
	width: 405px;
	padding: 0 30px 0 0;
	position: relative;
	vertical-align: top;
}

.b-gi-cw__left {
	float: left;
	width: 80px;
	cursor: pointer;
}

.b-gi-cw__right {
	width: 320px;
	padding: 10px 0;
	float: left;
	cursor: pointer;
}

.b-gi-cw__count-lessons {
	font-weight: bold;
	font-size: 30px;
	background: #dbe5ec;
	text-align: center;
	height: 45px;
	width: 45px;
	padding: 10px 6px 10px 12px;
	border: 2px solid #fff;
	border-radius: 50%;
	box-shadow: 0 0 3px #76ACD2;

}

.b-gi-cw__green {
	background: #97CBC9;
	border: 2px solid #fff;
	border-radius: 50%;
	box-shadow: 0 0 3px #00918B;
}

.b-gi-cw__count-lessons span {
	font-weight: normal;
	font-size: 14px;
	color: #06131b;
}

.b-gi-cw__title {
	font-size: 17px;
	font-weight: bold;
	color: #000;
	margin-bottom: 5px;
}

.b-gi-cw__title span {
	color: #53a8ad;
}

.b-gi-cw__location {
	font-size: 12px;
	font-weight: normal;
	color: #818387;
}

.b-gi-cw__location span {
	font-weight: bold;
}

.b-gi-cw__description {
	position: absolute;
	z-index: 1;
	background: #ecf4f8;
	padding: 10px 10px 10px 10px;
	width: 405px;
	margin-top: 25px;
	margin-left: -2px;
	font-size: 12px;
	color: #000;
	line-height: normal;
	cursor: pointer;
}

.b-gi-cw__description span {
	color: #6f6f6f;
}
