.ezd-switch {
  margin: 0;
}

.ezd-switch .md-thumb {
  width: 12px;
  height: 12px;
  top: 4px;
  left: 4px;
  background-color: #fff !important;
}
.ezd-switch .md-ink-ripple {
  color: #fff;
}
.ezd-switch .md-bar {
  width: 35px;
  height: 18px;
  border-radius: 9px;
  top: 3px;
}
.ezd-switch.ezd-switch-default .md-bar {
  background-color: #008491;
}
.ezd-switch.ezd-switch-checkbox .md-bar {
  background-color: #999999;
}

.ezd-switch.ezd-switch-default.md-checked .md-thumb,
.ezd-switch.ezd-switch-checkbox.md-checked .md-thumb {
  background-color: #fff;
}
.ezd-switch.ezd-switch-default.md-checked .md-bar,
.ezd-switch.ezd-switch-checkbox.md-checked .md-bar {
  background-color: #008491;
}