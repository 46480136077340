.footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 18px 14px;
  background: #f4f6f6;
  height: 128px;
  border-top: 1px solid rgba(193, 202, 209, 0.35);
  color: #7b7b7b;
  font-family: inherit;
  font-size: 14px;
  box-sizing: border-box;
}
.footer-wrapper a {
  text-decoration: none;
  color: #7b7b7b;
}
.footer-wrapper a:hover, .footer-wrapper a:focus, .footer-wrapper a:active, .footer-wrapper a:visited {
  text-decoration: none;
  cursor: pointer;
  color: #52656C;
  outline: none;
}
.footer-wrapper a:focus, .footer-wrapper a:visited {
  color: #7b7b7b;
}
.footer-wrapper .footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.footer-wrapper .footer-row .footer-menu, .footer-wrapper .footer-row .footer-contacts {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.footer-wrapper .footer-row .footer-menu-item {
  display: none;
  padding: 0 14px;
}
.footer-wrapper .footer-row .footer-contact, .footer-wrapper .footer-row .footer-soc-icons, .footer-wrapper .footer-row .footer-btn {
  padding: 0 14px;
}
.footer-wrapper .footer-row .footer-soc-icons {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.footer-wrapper .footer-row .footer-soc-icons a {
  margin: 0 8px;
  opacity: 0.64;
}
.footer-wrapper .footer-row .footer-soc-icons a .footer-soc-icon {
  min-height: 20px;
  min-width: 20px;
  height: 20px;
  width: 20px;
  line-height: 0;
  color: #7b7b7b;
}
.footer-wrapper .footer-row .footer-soc-icons a:first-child {
  margin-left: 0;
}
.footer-wrapper .footer-row .footer-soc-icons a:last-child {
  margin-right: 0;
}
.footer-wrapper .footer-row .footer-soc-icons a:hover {
  opacity: 1;
}
.footer-wrapper .footer-row .footer-btn button {
  border: 1px solid rgba(147, 167, 175, 0.36);
  padding: 8px 20px;
  line-height: 14px;
  border-radius: 3px;
  background: #f4f6f6;
  color: #7b7b7b;
  outline: none;
  cursor: pointer;
}
.footer-wrapper .footer-row .footer-btn button:hover {
  color: #52656C;
  border: 1px solid #a3b2bd;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}
.footer-wrapper .footer-copyright {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 14px);
  padding-top: 18px;
  opacity: 0.71;
  font-size: 13px;
}