.icon {
	stroke: currentColor;
	cursor: pointer;
	padding: 0 6px;
	background: #fff;
	margin: 0;
}

.wrapper__password-input {
	display: inline-block;
}
