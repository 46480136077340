.ezd-demo-screen-switch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  background-color: #008ab4;
  color: #fff;
  font-size: 15px;
}

.main-text a {
  color: #fff;
}

.hide-icon {
  position: absolute;
  top: 6px;
  right: 25px;
}
.hide-icon svg {
  fill: #fff;
}
.hide-icon:hover {
  cursor: pointer;
}