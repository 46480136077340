.criterion-input-container .criterion-input {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin-left: -4px;
  margin-top: -4px;
}
.criterion-input-container .span-model {
  border-bottom: 1px dotted #666;
  cursor: pointer;
}
.criterion-input-container .not-a-number {
  line-height: 22px;
  margin: 5px;
}