@import "~styles/colors";

.ezd-radio-buttons {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100%;

	& * {
		box-sizing: inherit;
	}

	.ezd-radio-button {
		margin-top: 8px;

		input[type=radio] {
			display: none;

			& + label {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				color: $black;
				cursor: pointer;
				line-height: 1.13;

				position: relative;

				&::before {
					box-sizing: border-box;
					content: "";
					border-radius: 50%;
					background: linear-gradient(to bottom, #ffffff, #f3f8fb);
					border: solid 1px #ccd6da;
					margin-right: 8px;
					width: 1.429em;
					height: 1.429em;
					min-height: 1.429em;
					min-width: 1.429em;
				}

				&::after {
					box-sizing: border-box;
					display: none;
					position: absolute;
					top: calc(50% - 0.285em);
					left: 0.4295em;
					content: "";
					border-radius: 50%;
					background: #fff;
					box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
					width: 0.57em;
					height: 0.57em;
					min-height: 0.57em;
					min-width: 0.57em;
				}

				&:hover {
					&::before {
						box-shadow: 0 0 0 2px rgba(0, 174, 197, 0.1);
					}
				}
			}

			&:checked + label {
				&::before {
					background: var(--menu-color);
				}

				&::after {
					display: block;
				}
			}

			&:disabled + label {
				opacity: 0.5;

				&::before {
					background: #e8e8e8;
					border: solid 1px #ccd6da;
					opacity: 0.5;
				}

				&:hover {
					&::before {
						box-shadow: none;
					}
				}
			}
		}
	}

	&.buttons-style {
		display: flex;
		flex-direction: row;

		border-radius: 2px;

		label::before,
		label::after {
			display: none !important;
		}

		.ezd-radio-button {
			margin-top: 0;
			padding: 7px 16px;
			font-size: 15px;
			border: 1px $light-border solid;

			&, label {
				cursor: pointer;
			}

			&:not(:last-child) {
				border-right: 1px $light-border solid;
			}

			&:not(:first-child) {
				border-left-color: transparent;
			}

			&:first-child {
				border-radius: 2px 0 0 2px;
			}

			&:last-child {
				border-radius: 0 2px 2px 0;
			}

			&.active {
				background: var(--color-button-5);
				border-color: var(--color-button-5);

				label {
					color: $white !important;
				}
			}
		}
	}
}
