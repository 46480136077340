@import "~styles/colors";
@import "~styles/mixins";

$default-size: 24px;
$small-size: 16px;

assignment-checkbox {
	display: block;
	height: $default-size;

	&[small="true"] {
		height: $small-size;
		font-size: 12px;
		line-height: $small-size;
	}
}

.checkbox {
	display: inline-block;

	input[type=checkbox] {
		display: none;
		& + label {
			position: relative;
			display: flex;
			align-items: center;
			white-space: nowrap;

			&::before {
				content: "";
				display: inline-block;
				background: #F3F3F3;
				border-radius: 50%;
				width: $default-size;
				height: $default-size;
				box-shadow: inset 0 1px 1px 0 rgba(189, 189, 189, 0.5);
			}

			span {
				background: none;
				width: auto;
				height: auto;
				margin-left: 3px;
			}
		}

		&:checked + label {
			&::after {
				content: "";
				position: absolute;
				width: $default-size - 10px;
				height: $default-size - 10px;
				background: var(--menu-color);
				border-radius: 50%;
				left: 5px;
				top: 5px;
				display: inline-block;
			}

			span {
				background: none;
				width: auto;
				height: auto;
			}
		}

		&:disabled + label {
			opacity: 0.5;
		}
	}
}

.checkbox:global(.small) {
	input[type=checkbox] {
		& + label::before {
			width: $small-size;
			height: $small-size;
		}

		&:checked + label {
			&::after {
				width: $small-size - 8px;
				height: $small-size - 8px;
				top: 4px;
				left: 4px;
			}
		}
	}
}
