@import "~styles/colors";
@import "~styles/mixins";

.ezd-alert-dialog {
	min-width: 400px;
	max-width: 600px;

  header {
    padding: 5px 13px;

    span {
      color: $grey;
    }

    md-icon {
      color: $grey;
      cursor: pointer;
      margin: 0;

      & > svg {
        fill: $grey;
      }
    }
  }

  main {
    .main-icon {
      padding: 38px;

      img {
        max-height: 100px;
        max-width: 100px;
      }
    }

    .main-text {
      padding: 0 56px;
      text-align: center;
    }

    .cause {
      padding-top: 18px;

      .cause-wrapper {
        padding: 20px 0 25px;
        width: 312px;
        background-color: $light-bg;

        .cause-title {
          color: #E00000;
          padding-bottom: 18px;
        }
      }
    }

    .controls {
      padding: 32px 0 40px;

      :global(.md-button),
      :global(.md-button:hover) {
        //@include ezd-button($orange);
        text-transform: none;
        color: $white;
        min-height: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        margin: 0px 20px 0px 20px;
      }
    }
  }
}
