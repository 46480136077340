.ezd-paginator {
  padding: 24px 32px;
  filter: hue-rotate(var(--icon-hue-rotate));
}
.ezd-paginator * {
  box-sizing: content-box !important;
}
.ezd-paginator .per-page-switch {
  width: 67px;
  height: 23px;
  padding-right: 20px;
}
.ezd-paginator .per-page-switch ezd-select md-menu > div {
  height: 23px;
  padding: 0 10px;
}
.ezd-paginator .page-switch span {
  cursor: pointer;
  padding: 2px 0;
  user-select: none;
}
.ezd-paginator .page-switch span.first {
  padding-right: 22px;
}
.ezd-paginator .page-switch span.last {
  padding-left: 22px;
}
.ezd-paginator .page-switch span:hover {
  color: var(--link-color);
}
.ezd-paginator .page-switch .last-arrow {
  padding-left: 16px;
}
.ezd-paginator .page-switch .pages {
  padding: 2px 8px;
}
.ezd-paginator .page-switch md-icon {
  cursor: pointer;
  color: #333;
  padding: 6px 0;
}
.ezd-paginator .page-switch md-icon svg {
  fill: #333;
}
.ezd-paginator .page-switch md-icon:hover {
  color: var(--link-color);
}
.ezd-paginator .page-switch md-icon:hover svg {
  fill: var(--link-color);
}
.ezd-paginator .selected {
  color: var(--link-color);
}
.ezd-paginator .dots {
  cursor: default !important;
  color: #333 !important;
}