@import "~styles/colors";

.wysiwyg-wrapper {
	position: relative;

	.upload-file {
		border-bottom: none;

		label {
			position: absolute;
			top: 0;
			width: 44px;
			height: 32px;
			left: 273px;
			z-index: 999;
		}
	}

	.attachments-list {
		margin-top: 15px;

		a {
			color: var(--font-color2);
		}

		md-icon {
			height: 16px;
			width: 16px;
			min-height: 16px;
			min-width: 16px;
		}
	}
}
