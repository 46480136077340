@import "~styles/colors";

.top-menu {
	height: 48px;
	background: #00838f;

	.for-medal {
		color: $white !important;
		font-size: 16px;
		line-height: 20px;
		padding: 14px;
		height: 48px;
		outline: none;
		text-decoration: none;

		&:hover {
			cursor: pointer;
			background: change_color($light-green, $lightness: 20%, $alpha: 0.2);
		}
	}

	& > div {
		margin: 0 auto;
	}

	& :global(.md-menu) {
		height: 48px;
	}

	& :global(.md-menu) > button {
		color: #fff;
		font-size: 16px;
		height: 48px;
	}
}

.menu-toolbar {
	position: relative;
	min-height: 48px;
	z-index: 0;

	md-menu-bar {
		padding-left: 8px;

		button {
			padding: 0 8px;

			md-icon {
				color: $white !important;
				min-height: 18px;
				min-width: 18px;
				height: 18px;
				width: 18px;
			}
		}
	}

	.home-icon, .back-icon {
		color: $white !important;
		padding: 12px;
		margin: 0 !important;
		width: 48px;
		height: 48px;
		outline: none;
	}

	.back-icon {
		margin-right: 18px !important;
	}
}

.overflowing {
	overflow: auto;
	max-height: 400px !important;

	& > md-menu-item {
		min-height: 32px !important;
	}
}

.top-level-item {
	color: #fff;
	font-size: 16px;
	height: 48px;
	margin-left: -5px;

	&:hover {
		background-color: rgba(238, 238, 238, 0.2);
	}
}

.link-item {
	text-decoration: none;
}

.menu-item {
	position: relative;
	margin-right: 30px;
	box-sizing: border-box;
	background: none !important;
	cursor: pointer;

	&:hover, &.active {
		opacity: 1;

		&:after {
			border-bottom: solid 3px var(--menu-item-highlight-color);
		}
	}

	&.active {
		font-weight: 500;
	}

	&:after {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}

.mesh-library {
	position: absolute;
	top: 7px;
	right: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 34px;
	padding: 8px 15px;
	border-radius: 4px;
	background-color: transparentize($white, 0.8);
	color: $white;
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
	z-index: 10;

	&:hover {
		cursor: pointer;
		background-color: $white;
		color: var(--font-color2);

		.link-arrow {
			color: var(--font-color2) !important;
		}
	}
}

.link-arrow {
	position: absolute;
	top: 2px;
	right: 4px;
	min-height: 7px;
	min-width: 8px;
	height: 7px;
	width: 8px;
	color: $white !important;
}
