@import "~styles/colors";

.ezd-paginator {
	padding: 24px 32px;
	filter: hue-rotate(var(--icon-hue-rotate));

	* {
		box-sizing: content-box !important;
	}

	.per-page-switch {
		width: 67px;
		height: 23px;
		padding-right: 20px;

		ezd-select md-menu > div {
			height: 23px;
			padding: 0 10px;
		}
	}

	.page-switch {
		span {
			cursor: pointer;
			padding: 2px 0;
			user-select: none;

			&.first {
				padding-right: 22px;
			}

			&.last {
				padding-left: 22px;
			}

			&:hover {
				color: var(--link-color);
			}
		}

		.last-arrow {
			padding-left: 16px;
		}

		.pages {
			padding: 2px 8px;
		}

		md-icon {
			cursor: pointer;
			color: $black;
			padding: 6px 0;

			svg {
				fill: $black;
			}

			&:hover {
				color: var(--link-color);

				svg {
					fill: var(--link-color);
				}
			}
		}
	}

	.selected {
		color: var(--link-color);
	}

	.dots {
		cursor: default !important;
		color: $black !important;
	}
}
