.calendar {
  width: 234px;
  padding: 0 16px 8px;
  user-select: none;
}

.month {
  height: 48px;
}
.month > span {
  font-size: 15px;
  text-transform: capitalize;
}
.month md-icon {
  margin: 0;
}
.month md-icon:hover {
  background: rgba(102, 102, 102, 0.15);
  cursor: pointer;
}

.week-days {
  font-size: 13px;
  height: 24px;
}
.week-days > div {
  width: 24px;
  height: 24px;
  margin: 8px;
  opacity: 0.4;
}

.day {
  margin: 4px 0;
}
.day > div {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  font-size: 14px;
  border-radius: 24px;
}
.day.single-active > div {
  background: var(--menu-color-01);
}
.day.active {
  background: var(--menu-color-01);
}
.day.active.start:not(.end) {
  border-radius: 24px 0 0 24px;
}
.day.active.end:not(.start) {
  border-radius: 0 24px 24px 0;
}
.day.active.start.end {
  background: none;
}
.day.active.start.end > div {
  background: var(--menu-color-01);
}
.day:hover {
  cursor: pointer;
}
.day:hover > div {
  background: var(--menu-color-01);
}
.day.not-current-month {
  color: rgba(51, 51, 51, 0.4);
}
.day.today > div {
  color: var(--link-color);
  border: 1px solid var(--link-color);
}
.day.today.active > div {
  background: #fff;
}

.reset {
  color: #666;
  font-size: 12px;
  border-bottom: 1px solid rgba(102, 102, 102, 0.2);
  line-height: 16px;
}
.reset:hover {
  cursor: pointer;
}