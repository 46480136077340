@import "~styles/colors";

.calendar {
	width: 340px;
	user-select: none;
}

.date-picker-header {
	height: 40px;
	width: 340px;
	position: relative;
	overflow: hidden;
	background: transparent;
	pointer-events: none;
	cursor: text;
}

.month {
	height: 44px;
	font-size: 14px;
	font-weight: 500;

	& > span {
		font-size: 15px;
		text-transform: capitalize;
	}

	md-icon {
		margin: 0;
		color: $translucent !important;

		&:hover {
			background: transparentize($grey, 0.85);
			cursor: pointer;
		}
	}
}

.week-days {
	background-color: $light-border;
	font-size: 13px;
	height: 40px;
	width: 100%;
}

.day {
	& > div {
		height: 44px;
		width: 44px;
		text-align: center;
		padding: 0;
		border-radius: 50%;

		&:hover {
			background-color: $light-border;
			cursor: pointer;
		}
	}

	&.active {
		& > div {
			background: var(--link-color);
			border: 1px solid var(--link-color);
			color: $white;
			&:hover {
				background: var(--link-color);
			}
		}
	}

	&.not-current-month {
		color: transparentize($black, 0.6);
	}

	&.today {
		& > div {
			color: var(--link-color);
			border: 1px solid var(--link-color);
		}

		&.active {
			& > div {
				color: $white;
			}
		}
	}
}
