@import "~styles/colors";

.ezd-demo-screen-switch {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 35px;
	background-color: $demo-block;
	color: $white;
	font-size: 15px;
}

.main-text {
	a {
		color: $white;
	}
}


.hide-icon {
	position: absolute;
	top: 6px;
	right: 25px;

	svg {
		fill: $white;
	}

	&:hover {
		cursor: pointer;
	}
}
