@import "~styles/colors";
@import "~styles/mixins";

.wrapper {
	min-height: 123px;
	border-top: $light-border 1px solid;
	border-bottom: $light-border 1px solid;

	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;

	.titles {
		z-index: 3;
		background: $light-bg;
		width: 198px;

		.title {
			font-size: 12px;
			font-weight: bold;
			padding: 9px 6px;
			box-sizing: border-box;
		}

		.sub-title {
			font-size: 20px;
			padding: 6px;
		}
	}


	.button {
		display: flex;
		align-items: center;
		border-left: $light-border 1px solid;
		border-right: $light-border 1px solid;
		cursor: pointer;
		width: 32px;

		$box-shadow: 2px 0px 18px -7px rgba(0, 0, 0, 0.35);
		box-shadow: $box-shadow;

		&:last-child {
			box-shadow: $box-shadow;
		}
	}

	.title, .button {
		position: relative;
		z-index: 3;
		background: $light-bg;

		md-icon svg {
			fill: $lighter-grey;
		}
	}

	.content {
		position: relative;
		z-index: 2;
		transition: left .3s;

		> div {
			position: relative;
			min-height: 100%;
		}
	}
}
