.oezd-logo {
	height: 100%;
	width: 100%;

	a {
		color: #9edee5;
		text-decoration: none;
	}

}


