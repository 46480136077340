.calendar {
  width: 340px;
  user-select: none;
}

.date-picker-header {
  height: 40px;
  width: 340px;
  position: relative;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
  cursor: text;
}

.month {
  height: 44px;
  font-size: 14px;
  font-weight: 500;
}
.month > span {
  font-size: 15px;
  text-transform: capitalize;
}
.month md-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.5) !important;
}
.month md-icon:hover {
  background: rgba(102, 102, 102, 0.15);
  cursor: pointer;
}

.week-days {
  background-color: #e6e6e6;
  font-size: 13px;
  height: 40px;
  width: 100%;
}

.day > div {
  height: 44px;
  width: 44px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}
.day > div:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}
.day.active > div {
  background: var(--link-color);
  border: 1px solid var(--link-color);
  color: #fff;
}
.day.active > div:hover {
  background: var(--link-color);
}
.day.not-current-month {
  color: rgba(51, 51, 51, 0.4);
}
.day.today > div {
  color: var(--link-color);
  border: 1px solid var(--link-color);
}
.day.today.active > div {
  color: #fff;
}