section.ezd-tabs {
  box-sizing: border-box;
}
section.ezd-tabs *:not(main.ezd-tabs-content):not(main.ezd-tabs-content *) {
  box-sizing: border-box;
}
section.ezd-tabs header.ezd-tabs-header {
  display: flex;
  height: 64px;
  border-bottom: 1px solid #6b8796;
}
section.ezd-tabs header.ezd-tabs-header .ezd-tabs-item {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
section.ezd-tabs header.ezd-tabs-header .ezd-tabs-item > input[type=radio] {
  display: none;
}
section.ezd-tabs header.ezd-tabs-header .ezd-tabs-item > input[type=radio] + label {
  padding: 8px 24px;
  margin-right: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #f1f1f3;
  color: rgba(51, 51, 51, 0.5);
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}
section.ezd-tabs header.ezd-tabs-header .ezd-tabs-item > input[type=radio]:checked + label {
  margin-bottom: -1px;
  padding: 12px 24px;
  border: 1px solid #6b8796;
  border-bottom-color: #fff;
  background-color: #fff;
  color: #6b8796;
  font-weight: 500;
}
section.ezd-tabs header.ezd-tabs-header .ezd-tabs-item > input[type=radio]:hover:not(:checked) + label {
  background-color: #f9f9fa;
}