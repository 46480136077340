pagination {
  display: flex;
  position: static;
  justify-content: center;
  align-items: center;
}
pagination :global(.md-button.md-icon-button) {
  padding-top: 8px !important;
  margin: 0;
  flex: 0 0 auto;
}
pagination :global(.md-button.md-icon-button) svg {
  fill: #fff;
}
pagination :global(.md-button.md-icon-button):disabled svg {
  fill: #b3b3b3;
}
pagination .page-num {
  font-size: 17px;
  color: #fff;
  text-align: center;
}
pagination .page-num :global(.small-input) {
  width: 20px;
  border: none;
  text-align: center;
  font-size: 1em;
  font-family: "Open Snas", sans-serif;
}