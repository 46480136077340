@import "~styles/colors";

.ezd-switch {
	margin: 0;
}

.ezd-switch {
	.md-thumb {
		width: 12px;
		height: 12px;
		top: 4px;
		left: 4px;
		background-color: $white !important;
	}

	.md-ink-ripple {
		color: $white;
	}

	.md-bar {
		width: 35px;
		height: 18px;
		border-radius: 9px;
		top: 3px;
	}

	&.ezd-switch-default {
		.md-bar {
			background-color: $light-green;
		}
	}

	&.ezd-switch-checkbox {
		.md-bar {
			background-color: #999999;
		}
	}
}

.ezd-switch.ezd-switch-default.md-checked,
.ezd-switch.ezd-switch-checkbox.md-checked {
	.md-thumb {
		background-color: $white;
	}

	.md-bar {
		background-color: $light-green;
	}
}
