.type-selector > md-icon {
  color: #6C8796;
  min-height: 56px;
  min-width: 56px;
  height: 56px;
  width: 56px;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-left-color: #e6e6e6;
}
.type-selector > md-icon:focus, .type-selector > md-icon:active, .type-selector > md-icon:global(.active), .type-selector > md-icon:hover {
  outline: none;
  cursor: pointer;
  border-color: var(--link-color-05) !important;
  box-shadow: 0 0 1px 1px var(--link-color-05);
}
.type-selector .fast {
  border: 1px solid transparent;
  border-left-color: #e6e6e6;
  padding-right: 8px;
  height: 56px;
}
.type-selector .fast:focus, .type-selector .fast:active, .type-selector .fast:global(.active), .type-selector .fast:hover {
  cursor: pointer;
  outline: none;
  border-color: var(--link-color-05) !important;
  box-shadow: 0 0 1px 1px var(--link-color-05);
}
.type-selector .fast > md-icon {
  height: 56px;
  width: 56px;
  padding: 12px;
  box-sizing: border-box;
}
.type-selector .fast ezd-select div:first-of-type {
  background: #fff;
}