@import "~styles/colors";

.ezd-accordion {
	.header {
		cursor: pointer;
		position: relative;

		.expand-column {
			position: absolute;
			top: 9px;
			left: 3px;

			.expand-icon {
				transition: transform .3s ease;

				md-icon {
					color: $grey;

					svg {
						fill: $grey;
					}
				}
			}
		}
	}

	.content {
		position: relative;
		overflow: hidden;
		height: 0px;
	}

	&.opened {
		.content {
			height: auto;
		}

		.expand-icon {
			transform: rotate(180deg);
		}
	}
}
