.wrapper .timepicker {
  border-radius: 2px 0 0 2px;
  border-right: none !important;
}
.wrapper .timepicker > ng-form:first-child {
  border: none;
  height: auto;
}
.wrapper .timepicker > ng-form:first-child input {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.87);
}
.wrapper .datepicker input {
  border-radius: 0 2px 2px 0;
}
.wrapper:hover .datepicker input {
  border-left-color: var(--menu-color);
}