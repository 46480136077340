@import "~styles/colors";

.type-selector {
	& > md-icon {
		color: #6C8796;
		min-height: 56px;
		min-width: 56px;
		height: 56px;
		width: 56px;
		padding: 12px;
		box-sizing: border-box;
		border: 1px solid transparent;
		border-left-color: $light-border;

		&:focus, &:active, &:global(.active), &:hover {
			outline: none;
			cursor: pointer;
			border-color: var(--link-color-05) !important;
			box-shadow: 0 0 1px 1px var(--link-color-05);
		}
	}

	.fast {
		border: 1px solid transparent;
		border-left-color: $light-border;
		padding-right: 8px;
		height: 56px;

		&:focus, &:active, &:global(.active), &:hover {
			cursor: pointer;
			outline: none;
			border-color: var(--link-color-05) !important;
			box-shadow: 0 0 1px 1px var(--link-color-05);
		}

		& > md-icon {
			height: 56px;
			width: 56px;
			padding: 12px;
			box-sizing: border-box;
		}

		ezd-select div:first-of-type {
			background: #fff;
		}
	}
}
