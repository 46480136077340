@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.autocomplete-main-container {
  position: relative;
}
.autocomplete-main-container.disabled {
  background: #dddddd;
  border-color: #ccd6da !important;
  opacity: 0.5 !important;
}
.autocomplete-main-container.disabled * {
  cursor: default !important;
}
.autocomplete-main-container.ezd-select-style .find-icon {
  display: none;
}
.autocomplete-main-container.ezd-select-style:not(.disabled) .autocomplete-container {
  background: linear-gradient(to bottom, #ffffff, #f3f8fb);
}
.autocomplete-main-container.ezd-select-style:not(.disabled) .autocomplete-container:hover {
  border: 1px solid var(--menu-color);
}
.autocomplete-main-container.ezd-select-style:not(.disabled) .autocomplete-container:focus, .autocomplete-main-container.ezd-select-style:not(.disabled) .autocomplete-container:active, .autocomplete-main-container.ezd-select-style:not(.disabled) .autocomplete-container[aria-expanded=true] {
  border: 1px solid var(--menu-color);
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.autocomplete-main-container.ezd-select-style .autocomplete-container {
  min-height: 34px;
  padding-left: 8px;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  border: 1px solid #ccd6da;
  border-radius: 1px;
  outline: none;
}
.autocomplete-main-container.ezd-select-style .autocomplete-container .autocomplete-text-input {
  background: none;
}

.autocomplete-container {
  box-sizing: border-box;
  border: 1px solid #b3b3b3;
  border-radius: 2px;
  cursor: pointer;
  min-height: 32px;
  width: 100%;
  padding: 0 0 0 4px;
  max-height: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}
.autocomplete-container:global(.ng-invalid-required) {
  border: solid 1px #ff4848 !important;
}
.autocomplete-container .placeholder:not(.is-selected-all) {
  color: #b3b3b3;
}

.dropdown-menu {
  position: absolute !important;
  z-index: 100;
  background-color: #fff;
  border-radius: 2px;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  max-height: 200px;
  overflow: auto;
}

.dropdown-menu-item {
  cursor: pointer;
  padding: 4px 8px;
  position: relative;
}
.dropdown-menu-item span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
.dropdown-menu-item.selected {
  background-color: #ddd;
}
.dropdown-menu-item:hover {
  color: var(--link-color);
}
.dropdown-menu-item .deleted-label {
  color: red;
  display: block;
  position: absolute;
  background-color: #fbf4f5;
  padding: 2px 8px;
  border-radius: 8px;
  width: 54px;
  right: 0;
  top: 0;
}
.dropdown-menu-item .hidden-label {
  color: var(--link-color);
  display: block;
  position: absolute;
  background-color: #fbf4f5;
  padding: 2px 8px;
  border-radius: 8px;
  width: 42px;
  right: 0;
  top: 0;
}

.empty-collection {
  padding: 4px 8px;
}
.empty-collection span {
  color: #666;
}

.autocomplete-text-input {
  border: none;
  margin-left: 4px;
}

.autocomplete-chip {
  height: 24px;
  white-space: nowrap;
  background-color: #eee;
  padding: 0 8px;
  margin: 3px 8px 3px 0;
  overflow: hidden;
}
.autocomplete-chip span {
  padding-top: 1px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-input-container {
  flex-wrap: wrap;
  padding-right: 34px;
  max-height: 200px;
  overflow: auto;
}

.delete-chip {
  min-height: 16px;
  min-width: 16px;
  height: 16px;
  width: 16px;
  margin-top: 4px;
  margin-left: 4px;
}
.delete-chip svg {
  fill: #666;
}

.find-icon {
  min-height: 14px;
  min-width: 14px;
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-left: 0;
}

.select-arrow {
  min-height: 0;
  min-width: 0;
  height: 4px;
  width: 8px;
  position: absolute;
  right: 10px;
  top: 14px;
}