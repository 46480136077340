@import "~styles/colors";

.wrapper {
	.timepicker {
		border-radius: 2px 0 0 2px;
		border-right: none !important;

		//.input-time-wrapper
		>ng-form:first-child{
			border: none;
			height: auto;

			input {
				font-size: 15px;
				color: rgba(0,0,0,0.87);
			}
		}
	}

	.datepicker {
		//.md-datepicker-input
		input {
			border-radius: 0 2px 2px 0;
		}
	}

	//.md-datepicker-input
	&:hover .datepicker input {
		border-left-color: var(--menu-color);
	}
}
