@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
assignments-table-header-cell .wrapper {
  height: 100%;
  width: 100px;
  max-width: 100px;
  background: #fafafa;
  border-right: #e6e6e6 1px solid;
}
assignments-table-header-cell .wrapper .content {
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
}
assignments-table-header-cell .wrapper .fast-selection {
  position: relative;
  height: 27px;
  border-top: #e6e6e6 1px solid;
}
assignments-table-header-cell .wrapper .fast-selection .selection-menu-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.fast-selection-menu md-menu-item {
  height: 36px !important;
  min-height: 36px !important;
}