@import "~styles/colors";
@import "~styles/mixins";

.autocomplete-main-container {
	position: relative;

	&.disabled {
		background: $disabled-bg;
		border-color: $disabled-border !important;
		opacity: 0.5 !important;
		* {
			cursor: default !important;
		}
	}

	&.ezd-select-style {
		.find-icon {
			display: none;
		}

		&:not(.disabled) {
			.autocomplete-container {
				background: $blue-gradient;

				&:hover {
					border: 1px solid var(--menu-color);
				}

				&:focus, &:active, &[aria-expanded="true"] {
					border: 1px solid var(--menu-color);
					box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);
				}
			}
		}

		.autocomplete-container {
			min-height: 34px;
			padding-left: 8px;
			font-size: 15px;
			font-weight: 400;
			line-height: 21px;
			border: 1px solid #ccd6da;
			border-radius: 1px;
			outline: none;


			.autocomplete-text-input {
				background: none;
			}
		}
	}
}

.autocomplete-container {
	box-sizing: border-box;
	border: 1px solid $light-grey;
	border-radius: 2px;
	cursor: pointer;
	min-height: 32px;
	width: 100%;
	padding: 0 0 0 4px;
	max-height: 200px;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;

	&:global(.ng-invalid-required) {
		border: solid 1px #ff4848 !important;
	}

	.placeholder:not(.is-selected-all) {
		color: $light-grey;
	}
}

.dropdown-menu {
	position: absolute !important;
	z-index: 100;
	background-color: $white;
	border-radius: 2px;
	border-left: 1px solid $light-border;
	border-bottom: 1px solid $light-border;
	border-right: 1px solid $light-border;
	max-height: 200px;
	overflow: auto;
}

.dropdown-menu-item {
	cursor: pointer;
	padding: 4px 8px;
	position: relative;

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		display: block;
	}

	&.selected {
		background-color: #ddd;
	}

	&:hover {
		color: var(--link-color);
	}

	.deleted-label {
		color: red;
		display: block;
		position: absolute;
		background-color: #fbf4f5;
		padding: 2px 8px;
		border-radius: 8px;
		width: 54px;
		right: 0;
		top: 0;
	}
	.hidden-label {
		color: var(--link-color);
		display: block;
		position: absolute;
		background-color: #fbf4f5;
		padding: 2px 8px;
		border-radius: 8px;
		width: 42px;
		right: 0;
		top: 0;
	}
}

.empty-collection {
	padding: 4px 8px;

	span {
		color: $grey;
	}
}

.autocomplete-text-input {
	border: none;
	margin-left: 4px;
}

.autocomplete-chip {
	height: 24px;
	white-space: nowrap;
	background-color: #eee;
	padding: 0 8px;
	margin: 3px 8px 3px 0;
	overflow: hidden;

	span {
		padding-top: 1px;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.text-input-container {
	flex-wrap: wrap;
	padding-right: 34px;
	max-height: 200px;
	overflow: auto;
}

.delete-chip {
	min-height: 16px;
	min-width: 16px;
	height: 16px;
	width: 16px;
	margin-top: 4px;
	margin-left: 4px;

	svg {
		fill: $grey;
	}
}

.find-icon {
	min-height: 14px;
	min-width: 14px;
	width: 14px;
	height: 14px;
	margin-right: 5px;
	margin-left: 0;
}

.select-arrow {
	min-height: 0;
	min-width: 0;
	height: 4px;
	width: 8px;
	position: absolute;
	right: 10px;
	top: 14px;
}
