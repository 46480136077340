@import "./colors";

@mixin data-table() {
	.content-table-header {
		background: #ebf2f5;
		color: #6c8796;
		text-transform: uppercase;
		font-weight: bold;

		& > div {
			border-top: 2px solid #cfd4d7;
			border-bottom: 2px solid #cfd4d7;
			border-right: 1px solid #cfd4d7;
			padding: 8px;

			&:last-of-type {
				border-right: none;
			}

			& > div {
				border: none;
			}
		}
	}

	.content-table-body {

		& > div {
			border-bottom: 1px solid #cfd4d7;
			border-right: 1px solid #cfd4d7;
			padding: 8px;

			& > input {
				width: 100%
			}

			&:last-of-type {
				border-right: none;
			}

			& > div {
				border: none;
			}
		}

	}
}

@mixin programs-table() {
	padding: 0 24px;

	.table-header {
		font-weight: 600;
		font-size: 12px;
		border-bottom: 1px solid $light-border;
		padding: 6px;
		position: relative;

		md-icon {
			position: absolute;
			top: 11px;
			padding-left: 6px;
		}
	}

	.table-body {
		padding: 16px 0;
		border-bottom: 1px solid $light-border;

		.table-row {
			padding: 8px 0 8px 6px;
			cursor: default;
			min-height: 40px;

			&:hover {
				background-color: $light-bg;

				md-icon {
					display: flex;
				}
			}

			.program-title {
				color: var(--link-color);
				cursor: pointer;
				user-select: none;
			}

			md-icon {
				margin-left: 0;
				margin-right: 8px;
				display: none;
				cursor: pointer;
			}
		}
	}
}

/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
@mixin ezd-button($color:"", $type: "normal") {
	font-size: 16px;
	text-transform: none;
	font-weight: 400;
	height: 40px;
	min-width: 112px;
	letter-spacing: normal;
	padding: 0 16px;
	background-blend-mode: overlay;

	@if ($color != "") {
		md-icon {
			color: $color;
		}

		&:not(:global(.md-raised)) {
			color: $color;
		}

		&:global(.md-raised) {
			background-color: $color;
		}
	}

	&[disabled] {
		background-color: transparentize($light-grey, 0.5);

		&:global(.md-raised) {
			background-color: $light-grey;
		}
	}

	&:hover, &:active, &:focus {
		@if ($color != "") {
			&:global(.md-raised) {
				filter: brightness(85%);
				background-color: $color;

				&[disabled] {
					background-color: $light-grey;
				}
			}
		}
	}

	@if ($type == "small") {
		min-height: 32px;
		line-height: 32px;
		height: 32px;
		font-size: 13px;
		font-weight: 400;

		md-icon {
			min-height: 13px;
			min-width: 13px;
			width: 13px;
			height: 13px;
		}
	}
}

@mixin dropdown($width: 330px, $direction: top) {
	padding: 18px;
	position: absolute;
	width: $width;
	border-radius: 2px;
	background-color: $white;
	box-shadow: -1px 3px 3px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px $grey-border;
	z-index: 99;
	top: calc(100% + 10px);
	left: 50%;
	margin-left: - calc($width / 2);
	box-sizing: border-box;
	cursor: default;

	$beforeSize: 6px;
	&:before, &:after {
		position: absolute;
		left: 50%;
		content: "";
	}

	&:before {
		@include triangle($direction, $white, $beforeSize, 1.2);
		z-index: 4;
		margin-left: -$beforeSize;

		@if($direction == top) {
			top: -7px;
		} @else if($direction == bottom) {
			bottom: -7px;
		}

	}

	&:after {
		$afterSize: $beforeSize + 1;
		@include triangle($direction, $grey-border, $afterSize, 1.2);
		margin-left: -$afterSize;

		@if($direction == top) {
			top: -9px;
		} @else if($direction == bottom) {
			bottom: -9px;
		}
	}

	.dropdown-header {
		margin-bottom: 4px;
		font-size: 15px;
		color: $black;
	}

	.dropdown-toolbar {
		margin-top: 5px;
		.add-button {
			@include ezd-button(var(--color-button-5), "small");
			@include resetMinWidthHeight();
			margin: 0 8px 0 0;
			color: $white;
		}

		.cancel-button {
			@include ezd-button(transparent, "small");
			@include resetMinWidthHeight();
			margin: 0;
			color: $grey !important;
		}
	}
}

/**
	Сбросить значения min-height и min-width
*/
@mixin resetMinWidthHeight() {
	min-height: initial !important;
	min-width: initial !important;
}

/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
@mixin rectSize($width, $height: "") {
	width: $width !important;

	@if($height != "") {
		height: $height !important;
	} @else {
		height: $width !important;
	}
}

@mixin minRectSize($width, $height: "") {
	min-width: $width !important;

	@if($height != "") {
		min-height: $height !important;
	} @else {
		min-height: $width !important;
	}
}

/// Returns the opposite direction of each direction in a list
/// @author Hugo Giraudel
/// @param {List} $directions - List of initial directions
/// @return {List} - List of opposite directions
@function opposite-direction($directions) {
	$opposite-directions: ();
	$direction-map: (
		'top':    'bottom',
		'right':  'left',
		'bottom': 'top',
		'left':   'right',
		'center': 'center',
		'ltr':    'rtl',
		'rtl':    'ltr'
	);

	@each $direction in $directions {
		$direction: to-lower-case($direction);

		@if map-has-key($direction-map, $direction) {
			$opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
		} @else {
			@warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
		}
	}

	@return $opposite-directions;
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
@mixin triangle($direction, $color: currentcolor, $size: 1em, $proportion: 1.5) {
	@if not index(top right bottom left, $direction) {
		@error "Direction must be either `top`, `right`, `bottom` or `left`.";
	}

	width: 0;
	height: 0;
	content: '';
	z-index: 2;
	border-#{opposite-direction($direction)}: ($size * $proportion) solid $color;

	$perpendicular-borders: $size solid transparent;

	@if $direction == top or $direction == bottom {
		border-left:   $perpendicular-borders;
		border-right:  $perpendicular-borders;
	} @else if $direction == right or $direction == left {
		border-bottom: $perpendicular-borders;
		border-top:    $perpendicular-borders;
	}
}

/// Миксин для рисования иконки крестика
@mixin closeBtnBeforeAfterStyles($tooltipColor) {
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: $tooltipColor;
	left: 0;
	top: 0;
}
