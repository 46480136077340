/*РЕЗУЛЬТАТЫ КОНТРОЛЬНЫХ РАБОТО*/
.b-rcw__wrap {
	margin-bottom: 100px;
	padding: 20px 20px;
}

.b-rcw__head {
	margin-bottom: 20px;
}

.b-rcw__head,
.b-rcw-head__table {
	display: block;
	width: 878px;
}

.b-rcw-th__columns {
	display: table-cell;
	vertical-align: top;
	padding: 0px 45px;
	border-right: 1px solid #cfd4d7;
}

.b-rcw-th__columns:nth-child(1) {
	padding-left: 0px;
	min-width: 225px;
}

.b-rcw-th__columns:last-of-type {
	border-right: none;
	width: 100px;
}

.b-rcw-th__l-title,
.b-rcw-th__c-title {
	display: block;
}

.b-rcw-th__c-title span {
	font-size: 13px;
	font-weight: bold;
	color: #a8b8c4;
}

.b-rcw-th__l-title {
	margin-top: 5px;
	line-height: 16px;
}

.b-rcw-th__l-title span {
	font-size: 16px;
	color: #3b3e41;
}

/*-------1-------*/
.b-rcw-bt__head {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
}

.b-rcw-bth__column {
	display: table-cell;
	vertical-align: middle;
	border-right: 2px solid #cfd4d7;
	height: 44px;
}

.b-rcw-bth__column:nth-child(1) {
	width: 207px;
	padding-left: 5px;
}

.b-rcw-bth__column:nth-child(2) {
	width: 87px;
	text-align: center;
}

.b-rcw-bth__column:nth-child(3) {
	width: 573px;
	border-right: none;
	text-align: center;
}

.b-rcw-bth__column span {
	font-size: 11px;
	font-weight: bold;
	color: #6c8796;

}

/*------end 1--------*/

/*--------2------------*/
.b-rcw-bthc__kes-table {
	width: 100%;
	height: 44px;
}

.b-rcw-bthc-kes-t__column {
	display: block;
}

.b-rcw-bthc-kes-t__column:first-of-type {
	border-bottom: 1px solid #cfd4d7;
}

/*---------end 2-----------*/

/*-------3--------*/
.b-rcw-bthc-kes-tc {
	display: table-cell;
	vertical-align: middle;
	width: 115px;
	height: 22px;
	border-right: 2px solid #cfd4d7;
	position: relative;
}

.b-rcw-bthc-kes-tc:last-of-type {
	border-right: none;
}

.b-rcw-bthc-kes__active,
.b-rcw-bthc-kes-tc:hover {
	background: #d7f0ef;
}

.b-rcw-bthc-kes-tc span {
	color: #454856;
	font-size: 13px;
	font-weight: normal;
}

.b-rcw_comments {
	position: absolute;
	width: 219px;
	padding: 10px 10px;
	border-top: 5px solid #39b3ae;
	box-shadow: 0px 0px 5px #cfd4d7;
	background: #fff;
	z-index: 1;
	left: 100%;
	top: 100%;
	line-height: 12px;
	text-align: left;
}

.b-rcw_comment-title,
.b-rcw_comment-item {
	display: block;
	width: 100%;
	line-height: normal;
}

.b-rcw_comment-item span {
	font-size: 14px;
	font-weight: bold;
	color: #0a0a0a;
}

.b-rcw_comment-title {
	font-size: 12px;
	color: #0a0a0a;
}

/*-------end 3---------*/

/*--list students--*/
.b-rcw-bt__body {
	border-bottom: 2px solid #cfd4d7;
}

.b-rcw-btb_container {
	display: block;
	border-bottom: 1px solid #cfd4d7;
}

.b-rcw-btb_container:last-of-type {
	border-bottom: none;
}

.b-rcw-btb__column {
	display: table-cell;
	vertical-align: middle;
	border-right: 2px solid #cfd4d7;
	height: 25px;
}

.b-rcw-btb__column:nth-child(1) {
	width: 207px;
	padding-left: 5px;
}

.b-rcw-btb__column:nth-child(2) {
	width: 87px;
	text-align: center;
}

.b-rcw-btb__column:nth-child(3) {
	width: 573px;
	border-right: none;
}

.b-rcw-btb__column span {
	color: #454856;
	font-size: 13px;
	font-weight: normal;
}

.b-rcw-btb_kes-wrap {
	width: 100%;
}

.b-rcw-btb_kes-column {
	display: table-cell;
	width: 115px;
	height: 25px;
	border-right: 2px solid #cfd4d7;
	position: relative;
}

.b-rcw-btb_kes-column:last-of-type {
	border-right: none;
}

input[type="checkbox"].b-btb_kes_check {
	display: none;
}

input[type="checkbox"].b-btb_kes_check + label {
	cursor: pointer;
}

input[type="checkbox"].b-btb_kes_check + label span {
	display: inline-block;
	width: 113px;
	height: 100%;
	vertical-align: middle;
	cursor: pointer;
}

input[type="checkbox"].b-btb_kes_check + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODYzODY3QjZGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODYzODY3QjdGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4NjM4NjdCNEYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4NjM4NjdCNUYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuirhYcAAAEZSURBVHja7NQ9S0JRHMdxlVxEglwcjBuBSCC4CQpNQTVI6JCBi74EfRU6ubg6Ci4OSS0KRQ8GNvsS7isQBcEHuH4vHEHilPd6lRzOgQ8H7oXfef67DcNwOW0e1w7a4YQcJdstRwH9TPZ/l3OKV4S3DTnHJ65Q3SbkAj0R9I2C3ZAY3hES/Q2GdkLieEMQHaQw/rmx5jT7OJMEXOIFATwijYnsdCpIiA2LrP2/RhfHaOIBs9+O2NygL2j4QBR3eIYPdeSxkN5Y0Y9wi7YY3TwBP7yooQTDymWbiNGfcCICzGUW/wpYn8mqTXGPBgYoW3qAkm9z5DaNbuXt2Cp3blUe91RjdV13FKBpmqqxqsaqGqtqrKqx8rYUYAAMRWAd03EUCAAAAABJRU5ErkJggg==) top no-repeat;
	background-position: 50px -18px;
}

input[type="checkbox"].b-btb_kes_check:checked + label span {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAsCAYAAACDiVOJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODYzODY3QjZGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODYzODY3QjdGMkYyMTFFNEJCRTc5ODkwQ0M5Q0RFN0EiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4NjM4NjdCNEYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4NjM4NjdCNUYyRjIxMUU0QkJFNzk4OTBDQzlDREU3QSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuirhYcAAAEZSURBVHja7NQ9S0JRHMdxlVxEglwcjBuBSCC4CQpNQTVI6JCBi74EfRU6ubg6Ci4OSS0KRQ8GNvsS7isQBcEHuH4vHEHilPd6lRzOgQ8H7oXfef67DcNwOW0e1w7a4YQcJdstRwH9TPZ/l3OKV4S3DTnHJ65Q3SbkAj0R9I2C3ZAY3hES/Q2GdkLieEMQHaQw/rmx5jT7OJMEXOIFATwijYnsdCpIiA2LrP2/RhfHaOIBs9+O2NygL2j4QBR3eIYPdeSxkN5Y0Y9wi7YY3TwBP7yooQTDymWbiNGfcCICzGUW/wpYn8mqTXGPBgYoW3qAkm9z5DaNbuXt2Cp3blUe91RjdV13FKBpmqqxqsaqGqtqrKqx8rYUYAAMRWAd03EUCAAAAABJRU5ErkJggg==) top no-repeat;
	background-position: 50px 2px;
}

/*--END list students--*/
/*END РЕЗУЛЬТАТЫ КОНТРОЛЬНЫХ РАБОТО*/
/*/////////////////////////////////*/
/*ГРАФИК КОНТРОЛЬНЫХ РАБОТО*/
.b-schcw__conteiner {
	margin-bottom: 150px;
}

.b-schcw-conteiner__header {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
	border-right: 1px solid #cfd4d7;
}

.b-schcw-header__column {
	display: table-cell;
	vertical-align: middle;
	position: relative;
	border-right: 2px solid #cfd4d7;
	height: 53px;
}

.b-schcw-header__column:nth-child(1) {
	width: 314px;
}

.b-schcw-header__column:nth-child(2) {
	width: 784px;
	overflow: hidden;
	border-right: none;
}

.b-schcw-header__column:nth-child(1) span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-schcw-month-rotator__body {
	width: 784px;
	overflow: hidden;
	border-right: none;
}

.b-schcw-month-rotator__conteiner {
	width: 10000px;
	position: absolute;
	top: 0;
	/* left: -28px; */
}

.b-schcw-month__item {
	display: inline-block;
	border-right: 1px solid #cfd4d7;
	min-width: 69px;
}

/*.b-schcw-month__item:nth-child(6n-2) {
    border-right  : none;
}*/

.b-schcw-month__item-title {
	padding: 5px 0px;
	border-bottom: 2px solid #cfd4d7;
	text-align: center;
}

.b-schcw-month__item-title span {
	font-size: 14px;
	font-weight: bold;
	color: #6f8498;
}

.b-schcw-month__item-count {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	height: 13px;
	width: 22px;
	border-right: 1px solid #cfd4d7;
}

.b-schcw-month__item-count:last-of-type {
	border-right: none;
}

.b-schcw-month__item-count span {
	font-weight: normal;
	font-size: 10px;
}

.b-schcw-month__type-001 {
	color: var(--link-color);
}

.b-schcw-month__type-002 {
	color: #f1d874;
}

.b-schcw-conteiner__body {
	border-right: 1px solid #cfd4d7;
	position: relative;
}

.b-schcw-body__column {
	display: table-cell;
	vertical-align: middle;
	position: relative;
	border-right: 2px solid #cfd4d7;
	border-bottom: 1px solid #cfd4d7;
	height: 22px;
}

.b-schcw-body__column:nth-child(1) {
	width: 314px;
}

.b-schcw-body__column:nth-child(2) {
	width: 784px;
	overflow: hidden;
	border-right: none;
}

.b-schcw-body__column:nth-child(1) span {
	color: #454856;
	font-size: 11px;
}

.b-schcw__item-count {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	height: 22px;
	width: 22px;
	border-right: 1px solid #cfd4d7;
	position: relative;
	cursor: pointer;
}

.b-schcw__item-count:last-of-type {
	border-right: none;
	/* cursor: pointer; */
}

.b-schcw__item-count:hover {
	background: var(--menu-color-01);
}

.b-schcw__item-count-active {
	background: #ff4c4c;
}

.b-schcw__item-count-active span {
	color: #fff !important;
}

.b-schcw__item-count span {
	font-size: 10px;
	color: #3c5762;
}

span.b-schcw__item-count-type-001 {
	color: var(--color-button-5);
}

.ts-month-rotator__right, .ts-month-rotator__left {
	position: absolute;
	text-align: center;
	width: 13px;
	background: #dbdbde;
	padding: 5px 0px;
	cursor: pointer;
	color: #fff;
}

.ts-month-rotator__left {
	top: 0px;
	left: 0px;
	z-index: 20;
}

.ts-month-rotator__right {
	top: 0px;
	right: 0px;
	z-index: 90;
}

.i-icon-rotate_row-r2 {
	width: 14px;
	height: 20px;
	display: block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAUCAYAAAD/Rn+7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTg1Nzc3NENERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTg1Nzc3NERERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5ODU3Nzc0QUREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5ODU3Nzc0QkREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkxI2MEAAADfSURBVHjaYvz//z/DYAZMDIMcjDqQlg60pKM7LEl1YDQQHwXiSXRw3ESoXdHEOtAJiOcBMSMQX6eDA29A7ZoHtRsVgIoZJKwLxB/+Q0A7mhwtcTvUzg9QN8DlkBXJAPFjqMIlQMxIRwcyQu38D3WDDLoDRYH4KlTBXiBmo6PjYJgNajcIXIG6CSUNsg6i0oUdWxocTFEsiy0NDvpMAsNOQPwTqiGTDo7LhNr1E2o3ijy2cnAfECeBYh+INemQ3jSgdiVB7UYBjHiaW6Dq5zgdqzqsdjGOtgdHHTjAACDAAOCOBAWQkOCzAAAAAElFTkSuQmCC) no-repeat;
	background-position: -28px 0px;
}

.i-icon-rotate_row-l2 {
	width: 14px;
	height: 20px;
	display: block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAUCAYAAAD/Rn+7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTg1Nzc3NENERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTg1Nzc3NERERDBEMTFFNDhBNTZFOEM5RjY4NjVGRjgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5ODU3Nzc0QUREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5ODU3Nzc0QkREMEQxMUU0OEE1NkU4QzlGNjg2NUZGOCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkxI2MEAAADfSURBVHjaYvz//z/DYAZMDIMcjDqQlg60pKM7LEl1YDQQHwXiSXRw3ESoXdHEOtAJiOcBMSMQX6eDA29A7ZoHtRsVgIoZJKwLxB/+Q0A7mhwtcTvUzg9QN8DlkBXJAPFjqMIlQMxIRwcyQu38D3WDDLoDRYH4KlTBXiBmo6PjYJgNajcIXIG6CSUNsg6i0oUdWxocTFEsiy0NDvpMAsNOQPwTqiGTDo7LhNr1E2o3ijy2cnAfECeBYh+INemQ3jSgdiVB7UYBjHiaW6Dq5zgdqzqsdjGOtgdHHTjAACDAAOCOBAWQkOCzAAAAAElFTkSuQmCC) no-repeat;
	background-position: 1px 0px;
}

/*-----context menu-------*/
.i-icon__row-gr {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAANCAIAAADqq+PiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDMxNzlBQzkxRTU3MTFFNTg2NEJDNEMzQjJCM0U4ODMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDMxNzlBQ0ExRTU3MTFFNTg2NEJDNEMzQjJCM0U4ODMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMzE3OUFDNzFFNTcxMUU1ODY0QkM0QzNCMkIzRTg4MyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMzE3OUFDODFFNTcxMUU1ODY0QkM0QzNCMkIzRTg4MyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtezJSAAAACWSURBVHjaYvTfsYUBAzAxYAN4RUU4OEv0DbUEhVBE3/z4fuDZ02hVdXUBASCXRYid49///0DW2TevxTg5o1TUl925xXjo2dNPv38BRYFyr79/1xQUYgSqBcr8+f8PKPr51y93WXluVtalt2+yPP/2FWK0kYiomajYots3bn74gHCDs7QsUB9QCGQb3A3dF8+R5TeAAAMAV7g8efuhkc0AAAAASUVORK5CYII=) no-repeat;
	background-position: 0px 0px;
	filter: hue-rotate(var(--icon-hue-rotate));
	width: 7px;
	height: 13px;
	position: absolute;
	top: 5px;
	left: 15px;
}

.b-schcw__item-count__submenu {
	position: absolute;
	width: 210px;
	background: hsl(var(--hue3), var(--sat3), calc(10% + var(--lgh3)));
	left: 190px;
	top: 5px;
}

.b-schcw__item-count__submenu span {
	font-size: 14px;
	color: #fff;
	cursor: pointer;
}

.b-schcw__item-count__submenu dd {
	padding: 5px 10px;
	border-bottom: 1px solid var(--color-button-5);
}

.b-schcw__item-count__submenu dd:last-of-type {
	border-bottom: none;
}

/*-----end context menu-------*/

/*PRE FOOTER*/
.b-schcw-container__wrap-footer {
	border-top: 3px solid var(--color-button-5);
	background: #faf9fb;
}

.b-schcw-f__title-date {
	background: #fff;
	padding: 5px 20px;
}

.b-schcw-f__title-date span {
	font-weight: bold;
	font-size: 11px;
	color: #454856;
}

.b-schcw-f__conteiner-content {
	padding: 5px 20px;
}

.b-schcw-f-cc__table {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
}

.b-schcw-f-cc__td {
	border-bottom: 2px solid #cfd4d7;
}

.b-schcw-f-cc__td:last-of-type {
	border-bottom: none;
}

.b-schcw-f-cc-td__column {
	display: table-cell;
	padding: 5px 0px;
	vertical-align: middle;
	border-right: 1px solid #cfd4d7;
	line-height: 13px;
}

.b-schcw-f-cc-td__column:nth-child(1) {
	width: 169px;
}

.b-schcw-f-cc-td__column:nth-child(2) {
	border-right: none;
	padding-left: 5px;
	width: 703px;
	vertical-align: top;
}

.b-schcw-f-cc-td__list-column {
	padding: 0px 0px !important;
}

.b-schcw-f-cc-td__column span {
	font-size: 12px;
	color: #454856;
	vertical-align: top;
}

.b-schcw-f-cc__bold {
	font-weight: bold;
}

.b-schcw-f-cc-td__column a {
	font-size: 12px;
	color: var(--color-button-5);
}

.b-schcw-f-cc-td__column a:hover {
	text-decoration: none;
}

.b-schcw-f-cc-td__sub-column {
	padding: 5px 5px;
	border-bottom: 1px solid #cfd4d7;
	vertical-align: top;
}

.b-schcw-f-cc-td__sub-column:last-of-type {
	border-bottom: none;
}

/*END PRE FOOTER*/
/*END ГРАФИК КОНТРОЛЬНЫХ РАБОТО*/

.b-schcw-f-cc-td__column a {
	line-height: 23px;
}
