.calendar {
	padding: 0 27px 8px 27px;
	text-align: center;

	md-icon {
		min-width: 0;
		min-height: 0;
		width: 14px;
		height: 14px;
	}

	:global(.head) {
		border-bottom: 1px solid rgba(238, 238, 238, 0.5);

		:global(.md-icon-button) {
			margin: 0;
		}

		> div {
			width: 35px;
			height: 22px;
			border-right: 1px solid rgba(238, 238, 238, 0.5);
			color: var(--link-color);
			font-size: 13px;
			font-weight: 700;

			&:last-of-type {
				color: #ff5126;
				border-right: none;
			}
		}
	}

	:global(.body) {
		height: 55px;
		border-bottom: 1px solid rgba(238, 238, 238, 0.5);

		&:last-of-type,
		&:last-of-type > div {
			border-bottom: none;
		}

		> div {
			position: relative;
			width: 35px;
			border-right: 1px solid rgba(238, 238, 238, 0.5);

			&:last-of-type:not(:first-of-type) {
				border-right: none;
			}

			:global(.md-button) {
				min-width: 0;
				min-height: 0;
				border-radius: 50%;
				width: 27px;
				height: 27px;
				line-height: 27px;
				margin: 0;
				padding: 0;
				font-size: 17px;
				font-weight: 400;
				color: #546e7a;
			}

			:global(.md-button.active) {
				background: #00838f;
				color: #fff
			}

			:global(.icons-container) {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 13px;
			}

			:global(.b-i-invisible) {
				position: relative;
				left: 0;
				top: 0;
				display: block;
				margin-left: 2px;
				border-radius: 50%;
			}

		}
	}

	:global(.controls) {
		margin-bottom: 29px;
		text-transform: capitalize;
	}

}

.month {
	color: #434343;
	font-size: 17px;
	font-weight: 700;
}

.test-lesson-icon {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 10px;
	font-weight: 500;
	color: #ff5126;
}
