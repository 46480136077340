.dropdown-profile-header {
  color: #216ccb;
  cursor: pointer;
}
.dropdown-profile-header b {
  color: #fff;
  font-weight: bold;
}
.dropdown-profile-header md-icon svg {
  padding: 0 !important;
}

.dropdown-profile-current {
  font-size: 12px;
  color: #999;
  border-bottom: 1px dotted #999;
}

.dropdown-profile-items .md-button {
  display: flex;
}