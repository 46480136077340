.ajax-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  height: 100% !important;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.ajax-loader .loader {
  z-index: 7002;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.3em solid rgba(0, 0, 0, 0.07);
  border-right: 0.3em solid rgba(0, 0, 0, 0.07);
  border-bottom: 0.3em solid rgba(0, 0, 0, 0.07);
  border-left: 0.3em solid #66BB6A;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}