@import "~styles/colors";

.ezd-select {
	position: relative;
	min-width: 100%;
	display: block;

	.origin-field {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 90%;
	}

	md-icon {
		margin: 0 0 0 auto;
		height: 4px;
		width: 8px;
		min-height: 0;
		min-width: 0;
	}

	.ezd-select-header {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		min-height: 32px;

		padding: 0 8px;

		font-size: 15px;
		font-weight: 400;
		line-height: 21px;

		background: $blue-gradient;

		border: 1px solid #ccd6da;
		border-radius: 1px;

		outline: none;

		&:hover {
			border: solid 1px var(--menu-color);
		}

		&:focus, &:active, &[aria-expanded="true"] {
			border: solid 1px var(--menu-color);
			box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);
		}
	}

	.invalid-model {
		border: solid 1px #ff4848;
	}

	.ezd-select-header__line {
		cursor: pointer;
		font-size: 14px;
		height: auto;
		width: auto;

		background: none;
		border: none;

		outline: none;

		& > md-icon {
			display: none;
		}

		.placeholder {
			color: #00acc1;
		}

		.origin-field {
			border-bottom: 1px dashed #ccd6da;
		}

		&:hover {
			border: none;
		}

		&:focus, &:active, &[aria-expanded="true"] {
			border: none;
			box-shadow: none;
		}
	}

	.ezd-select-header-disabled {
		@extend .ezd-select-header;
		cursor: default;
		user-select: none;

		background: #dddddd;
		border-color: #ccd6da;
		opacity: 0.5;

		&:focus, &:active, &:hover {
			border: 1px solid #ccd6da;
			box-shadow: none;
		}
	}

	.placeholder {
		color: $light-grey;
	}
}

.ezd-select-options-list {
	border: 1px solid #ccd6da;
	border-radius: 1px;
	max-width: 400px;
	min-width: 200px;
	min-height: 32px;

	md-menu-item {
		text-overflow: ellipsis;
		align-items: center;

		cursor: pointer;

		min-height: 28px;
		height: 28px;
		font-size: 15px;
		font-weight: 400;

		overflow: hidden;
		white-space: nowrap;

		transition: none !important;
		transition-delay: 0ms !important;

		&:global(.selected) {
			background: var(--menu-color-02);
			color: #4e7281;
		}

		&:hover {
			color: $white;
			background: var(--menu-color);
		}

		& > * {
			padding: 0 8px;
		}
	}
}

