.filter-toggle {
  border-left: 1px solid #e6e6e6;
  cursor: pointer;
  user-select: none;
}
.filter-toggle md-icon {
  padding: 16px;
  outline: none;
  color: #b3b3b3;
}
.filter-toggle md-icon:global(.active) {
  color: var(--light-gradient-start);
}