@import "~styles/colors";

.filter-toggle {
  border-left: 1px solid $light-border;
  cursor: pointer;
	user-select: none;

  md-icon {
	padding: 16px;
	outline: none;
	color: $light-grey;

	&:global(.active) {
	  color: $light-gradient-start;
	}
  }
}
