@import "~styles/colors";
@import "~styles/mixins";

.wrapper {
	box-sizing: border-box;
	height: 48px;
	border-bottom: $light-border 1px solid;
	overflow: hidden;
	position: relative;

	.student-info {
		position: relative;
		z-index: 2;
		background: $white;

		padding: 9px 6px;
		width: 232px;
		height: 100%;
		border-right: $light-border 1px solid;

		display: flex;
		align-items: center;
		justify-content: space-between;

		.student-name {
			color: var(--link-color)
		}

		.student-class-unit {
			color: $light-grey;
			text-align: right;
			width: 61px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.groups-assignments-wrapper {
		position: relative;
		height: 100%;

		> div {
			height: 100%;
			position: absolute;
			z-index: 1;
			transition: left .3s;

			.groups-assignment {
				height: 100%;
				width: 100px;
				max-width: 100px;
				border-right: $light-border 1px solid;

				&.has-changes {
					background-color: rgba(255, 243, 0, 0.1);
				}

				&.is-disabled {
					background-color: $super-light-grey;
				}
			}
		}
	}

	.spacer {
		position: absolute;
		background: $white;
		border-left: $light-border 1px solid;
		right: 0;
		width: 34px;
		height: 100%;
		z-index: 2;
	}
}

