@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.wrapper {
  width: 470px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
}
.wrapper md-dialog-content {
  padding: 20px;
}
.wrapper md-dialog-content h2, .wrapper md-dialog-content h3 {
  text-align: center;
}
.wrapper md-dialog-content h2 {
  margin: 15px;
  font-size: 25px;
  font-weight: normal;
}
.wrapper md-dialog-content h3 {
  margin-bottom: 25px;
}
.wrapper md-dialog-content .students-list {
  border-radius: 5px;
  border: solid 1px #e6e6e6;
  padding: 20px;
  max-height: 50vh;
  overflow: auto;
}
.wrapper md-dialog-content .students-list .student .student-name {
  color: var(--link-color);
  margin: 3px 0;
  display: inline-block;
}
.wrapper md-dialog-content .students-list .student .student-class-unit {
  color: #b3b3b3;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
}
.wrapper md-dialog-content .toolbar .cancel-button {
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  height: 40px;
  min-width: 112px;
  letter-spacing: normal;
  padding: 0 16px;
  background-blend-mode: overlay;
  min-height: 32px;
  line-height: 32px;
  height: 32px;
  font-size: 13px;
  font-weight: 400;
  min-height: initial !important;
  min-width: initial !important;
  margin-top: 25px;
  color: #333 !important;
}
.wrapper md-dialog-content .toolbar .cancel-button md-icon {
  color: transparent;
}
.wrapper md-dialog-content .toolbar .cancel-button:not(:global(.md-raised)) {
  color: transparent;
}
.wrapper md-dialog-content .toolbar .cancel-button:global(.md-raised) {
  background-color: transparent;
}
.wrapper md-dialog-content .toolbar .cancel-button[disabled] {
  background-color: rgba(179, 179, 179, 0.5);
}
.wrapper md-dialog-content .toolbar .cancel-button[disabled]:global(.md-raised) {
  background-color: #b3b3b3;
}
.wrapper md-dialog-content .toolbar .cancel-button:hover:global(.md-raised), .wrapper md-dialog-content .toolbar .cancel-button:active:global(.md-raised), .wrapper md-dialog-content .toolbar .cancel-button:focus:global(.md-raised) {
  filter: brightness(85%);
  background-color: transparent;
}
.wrapper md-dialog-content .toolbar .cancel-button:hover:global(.md-raised)[disabled], .wrapper md-dialog-content .toolbar .cancel-button:active:global(.md-raised)[disabled], .wrapper md-dialog-content .toolbar .cancel-button:focus:global(.md-raised)[disabled] {
  background-color: #b3b3b3;
}
.wrapper md-dialog-content .toolbar .cancel-button md-icon {
  min-height: 13px;
  min-width: 13px;
  width: 13px;
  height: 13px;
}

.close-btn {
  position: absolute;
  display: block;
  margin-left: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
  top: 19px;
  right: 10px;
}
.close-btn:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #666;
  left: 0;
  top: 0;
  transform: rotate(45deg);
}
.close-btn:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #666;
  left: 0;
  top: 0;
  transform: rotate(-45deg);
}