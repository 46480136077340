@import "~styles/colors";
@import "~styles/mixins";

.wrapper {
	width: 470px;
	background-color: $white;
	font-size: 14px;
	color: $black;
	box-sizing: border-box;

	md-dialog-content {
		padding: 20px;

		h2, h3 {
			text-align: center;
		}

		h2 {
			margin: 15px;
			font-size: 25px;
			font-weight: normal;
		}

		h3 {
			margin-bottom: 25px;
		}

		.students-list {
			border-radius: 5px;
			border: solid 1px $light-border;
			padding: 20px;
			max-height: 50vh;
			overflow: auto;

			.student {
				.student-name {
					color: var(--link-color);
					margin: 3px 0;
					display: inline-block;
				}

				.student-class-unit {
					color: $light-grey;
					text-align: right;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-left: 10px;
				}
			}
		}

		.toolbar {
			.cancel-button {
				@include ezd-button(transparent, "small");
				@include resetMinWidthHeight();
				margin-top: 25px;
				color: $black !important;
			}
		}
	}
}

.close-btn {
	$size: 20px;
	position: absolute;
	display: block;
	margin-left: auto;
	width: $size;
	height: $size;
	cursor: pointer;
	top: 19px;
	right: 10px;

	&:before {
		@include closeBtnBeforeAfterStyles($grey);
		transform: rotate(45deg);
	}

	&:after {
		@include closeBtnBeforeAfterStyles($grey);
		transform: rotate(-45deg);
	}
}
