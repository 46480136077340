/*АДМИНИСТРИРОВАНИЕ ШКОЛЫ*/
.b-report-progress__thead {
	border-top: 2px solid #cfd4d7;
	border-bottom: 2px solid #cfd4d7;
}

.b-report-progress-student-deleted {
	color: red !important;
}

.b-report-progress__thead-column,
.b-report-progress__item-column {
	display: table-cell;
	padding: 5px 5px;
	border-right: 1px solid #cfd4d7;
	vertical-align: middle;
}

.b-report-progress__thead-column span {
	font-size: 12px;
	font-weight: bold;
	color: #6c8796;
}

.b-report-progress__thead-column:nth-child(1) {
	width: 520px;
}

.b-report-progress__thead-column:nth-child(2) {
	width: 150px;
	border-right: none;
}

.b-report-progress__item-conteiner {
	border-bottom: 1px solid #cfd4d7;
}

.b-report-progress__item-column:nth-child(1) {
	width: 520px;
}

.b-report-progress__item-column:nth-child(2) {
	width: 150px;
	border-right: none;
}

.b-report-progress__item-column span {
	color: #747a80;
	font-size: 12px;
}

.b-report-progress__item-column a {
	color: #39b3ae;
	font-size: 12px;
}

.b-report-progress__item-column a:hover {
	text-decoration: none;
}

.date-period {
	border: 1px solid;
	float: left;
	margin: 7px;
	padding: 5px;
}

.date-period-active {
	background-color: #b0e0e6;
	font-weight: bold;
}

/* PROGRESS REPORT DETAILS */
.prd-column-inner {
	border-right: 1px solid #cfd4d7;
	box-sizing: border-box;
	display: table-cell;
	padding: 3px;
}

.prd-header, .prd-row {
	color: #6c8796;
}

.prd-header-column, .prd-column {
	border-right: 1px solid #cfd4d7;
	border-top: 2px solid #cfd4d7;
	display: table-cell;
	height: 100%;
}

.prd-header-column:nth-child(1) span, .prd-month-name-wrap span {
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
}

.prd-header-column:nth-child(1), .prd-column:nth-child(1) {
	border-left: 2px solid #cfd4d7;
	border-right: 1px solid #cfd4d7;
	max-width: 132px;
	min-width: 132px;
	padding-left: 5px;
	width: 132px;
	vertical-align: middle;
}

.prd-inner-bottom-row .mark {
	margin: 2px 4px;
	width: 14px;
	height: 17px;
	display: inline-block;
}

.prd-inner-final-mark .mark {
	margin: 0 !important;
}

.prd-header-column:nth-child(2), .prd-column:nth-child(2) {
	border-right: 1px solid #cfd4d7;
	width: 975px;
	min-width: 975px;
	box-sizing: border-box;
}

.prd-header-month {
	border-right: 1px solid #cfd4d7;
	box-sizing: border-box;
	display: table-cell;
	height: 60px;
}

.prd-month-name-wrap {
	height: 25px;
	margin-top: 12px;
	text-align: center;
}

.prd-row:nth-last-child(1) {
	border-bottom: 2px solid #cfd4d7;
}

/* Добавка для печатной версии успеваемости ученика */
.prd-column-all-grades {
	border-right: 2px solid #cfd4d7;
	box-sizing: border-box;
	display: table-cell;
	padding: 3px;
}

.prd-inner-period-name {
	display: table-cell;
	width: 100%;
	border-bottom: 1px solid #cfd4d7;
	padding: 3px;
	word-break: break-all;
}

.prd-inner-avg {
	display: table-cell;
	min-width: 30px;
	width: 30px;
	max-width: 30px;
	border-bottom: 1px solid #cfd4d7;
	border-left: 1px solid #cfd4d7;
	padding: 3px;
}

.prd-inner-all-marks {
	display: table-cell;
	width: 100%;
	padding: 3px;
}

.prd-inner-final-mark {
	display: table-cell;
	min-width: 30px;
	width: 30px;
	max-width: 30px;
	border-left: 1px solid #cfd4d7;
	padding: 3px;
}

.prd-inner-top-row {
	line-height: 13px;
}

.prd-inner-bottom-row {
	line-height: 13px;
}

.print-container {
	display: none;
}

.prd-column-year {
	border-bottom: 1px solid #cfd4d7;
	height: 33px;
	width: 100%;
}

/* Конец для печатной версии успеваемости ученика */
/* END PROGRESS REPORT DETAILS */

/* TEACHERS REPORT */
.rt-header-column, .rt-column, .rt-column-class {
	border-right: 1px solid #cfd4d7;
	border-top: 2px solid #cfd4d7;
	display: table-cell;
	height: 100%;
	padding: 5px;
}

.rt-header-column span {
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
}

.rt-column-class {
	width: 100px;
}

.rt-header-column:nth-child(1) {
	vertical-align: middle;
	width: 60px;
	min-width: 60px;
	max-width: 60px;
	overflow: hidden;
}

.rt-header-column:nth-child(2), .rt-column:nth-child(1) {
	width: 350px;
	min-width: 350px;
	max-width: 350px;

}

.rt-header-column:nth-child(n+3), .rt-column:nth-child(n+2) {
	width: 100px;
	min-width: 100px;
	max-width: 100px;
}

.rt-subject-wrap {
	display: table-cell;
	min-width: 805px;
}

/*
.rt-header-column:nth-child(4){

}

.rt-header-column:nth-child(5){

}
*/
.rt-header-column:nth-child(6), .rt-column:nth-child(6) {
	border-right: none;
}

.rt-group-wrap {
	width: 350px;
	display: table-cell;
	padding: 5px;

}

.rt-marks {
	border-left: 1px solid #cfd4d7;
	display: table-cell;
	padding: 5px;
	width: 100px;
	vertical-align: middle;
}

.rt-subject {
	border-top: 2px solid #cfd4d7;
}

.prd-header:nth-last-child(1) {
	border-bottom: 2px solid #cfd4d7;
}

/* END TEACHERS REPORT */

/**  УЧИТЕЛЯ РАСШИРЕННЫЙ  **/

.t-ext-table {
	width: 100%;
	border: 1px solid #cfd4d7;
	border-spacing: 0;
	color: #6c8796;
}

.t-ext-header-row-for-fixed {
	width: 876px;
}

.t-ext-table-cell, .t-ext-table-cell-inner {
	border-bottom: 1px solid #cfd4d7;
	position: relative;
	border-right: 1px solid #cfd4d7;
	text-align: center;
	vertical-align: middle;
	overflow: hidden;
}

.main-cell:nth-child(1), .main-cell:nth-child(2) {
	width: 50px;
	text-align: center;
}

.main-cell:nth-child(1) span, .main-cell:nth-child(2) span, .t-ext-vertical {
	display: block;
	position: absolute;
	transform: rotate(-90deg);
	transform-origin: 0 0 0;
}

.t-ext-vertical {
	left: 12px;
	top: 140px;
	width: 130px;
	height: 36px;
}

.t-ext-table-inner {
	border: 0;
}

.main-cell:nth-child(3) {
	min-width: 760px;
	border-right: 0;
}

.t-ext-table-inner {
	table-layout: fixed;
	height: 100%;
	border-spacing: 0;
}

.t-ext-table-inner-row:nth-last-child(1) .t-ext-table-cell-inner {
	border-bottom: 0;
}

.t-ext-table-row .t-ext-table-cell-inner:nth-last-child(1) {
	border-right: 0;
}

.t-ext-table-cell-inner:nth-child(7) {
	width: 100px;
	min-width: 100px;
	max-width: 100px;
}

.t-ext-table-cell-inner:nth-child(8) {
	width: 100px;
	min-width: 100px;
	max-width: 100px;
}

.t-ext-table-cell-inner:nth-child(9) {
	width: 100px;
	min-width: 100px;
	max-width: 100px;
}

.t-ext-table-cell-inner:nth-child(10) {
	width: 100px;
	min-width: 100px;
	max-width: 100px;
}

.t-ext-table-row:nth-last-child(1) .main-cell {
	border: 0;
}

.t-ext-table-row:nth-last-child(1) .main-cell:nth-child(1) {
	border-right: 1px solid #cfd4d7;
}

.t-ext-table-row:nth-last-child(1) .main-cell:nth-child(2) {
	border-right: 1px solid #cfd4d7;
}

.t-ext-header-row, .t-ext-table-cell-inner-header {
	/*height: 130px;*/
	font-weight: bold;
}

.t-ext-mark-wrapper {
	width: 180px;
}

.t-ext-table-inner-row .t-ext-table-cell-inner:nth-child(3) {
	width: 180px;
}

.t-ext-table-cell-mark {
	box-sizing: border-box;
	width: 45px !important;
}

.t-ext-table-cell-mark-name {
	width: 180px;
}

.t-ext-mark-wrapper-header {
	line-height: 12px;
	height: 45px;
	border-bottom: 1px solid #cfd4d7 !important;
}

.t-ext-no-border {
	border-bottom: 0 none;
}

/**  КОНЕЦ УЧИТЕЛЯ РАСШИРЕННЫЙ  **/

.report-all-grades-table {
	width: 1100px !important;
}

.prd-column-all-grades.year {
	width: 60px;
	padding: 0 1px !important;
	text-align: center;
}


/**  НАЧАЛО НЕУДОВЛЕТВОРИТЕЛЬНЫЕ ОЦЕНКИ  **/

.report-bd table {
	border: 1px solid #cfd4d7;
	border-top: none;
	width: 100%;
	font-size: 12px;
	line-height: 13px;
	color: #454856;
}

.report-bd td:nth-child(1) {
	width: 90px;
	text-align: center;
}

.report-bd td:nth-child(2) ol {
	padding-left: 20px;
}

.report-bd td {
	border: 1px solid #cfd4d7;
	padding: 5px;
}

/**  КОНЕЦ НЕУДОВЛЕТВОРИТЕЛЬНЫЕ ОЦЕНКИ  **/

/** НАЧАЛО АКАДЕМИЧЕСКИЕ ЗАДОЛЖЕННОСТИ **/

.report-ad-main-table {
	border: 1px solid #cfd4d7;
	font-size: 14px;
	line-height: 13px;
	color: #454856;
}

.report-ad-main-table th {
	border-bottom: none;
}

.report-ad-row-cell {
	border: 1px solid #cfd4d7;
	padding: 5px;
}

.report-ad-zero-col {
	min-width: 52px;
	width: 52px;
	max-width: 52px;
}

.report-ad-first-col {
	min-width: 280px;
	width: 280px;
	max-width: 280px;
}

.report-ad-second-col {
	min-width: 230px;
	width: 230px;
	max-width: 240px;
}

.report-ad-third-col {
	min-width: 210px;
	width: 210px;
	max-width: 210px;
}

.report-ad-fourth-col {
	min-width: 110px;
	width: 110px;
	max-width: 110px;
}

.report-ad-first-col-with-class-name {
	min-width: 270px;
	width: 270px;
	max-width: 270px;
}

.report-ad-second-col-with-class-name {
	min-width: 200px;
	width: 200px;
	max-width: 200px;
}

.report-ad-third-col-with-class-name {
	min-width: 180px;
	width: 180px;
	max-width: 180px;
}

.report-ad-fourth-col-with-class-name {
	min-width: 110px;
	width: 110px;
	max-width: 110px;
}


/** КОНЕЦ АКАДЕМИЧЕСКИЕ ЗАДОЛЖЕННОСТИ **/

/** НАЧАЛА ПРОВЕРКА ЖУРНАЛА **/
.report-jc-table {
	border-color: #cfd4d7;
	font-size: 14px;
	line-height: 13px;
	color: #454856;
	border-collapse: collapse;
	width: 860px
}

.report-jc-table td, .report-jc-table th {
	border-color: #cfd4d7;
	padding: 5px;
}

.report-jc-table .comment-col {
	width: 150px;
}

.report-jc-table .teachers-col {
	width: 150px;
}

.report-jc-table .for-teacher-col {
	width: 100px;
}
/** КОНЕЦ ПРОВЕРКА ЖУРНАЛА **/


/** PRINTING STYLES **/
@media print {
	header.header,
	div.b-left__sidebar,
	div.b-right__nav_sidebar,
	.b-events__right_nav,
	footer.footer, .date-period {
		display: none;
	}

	.date-period-active {
		display: block;
	}

	.b-right__sidebar, .b-container__wrap, .row-1-1, main {
		width: 100% !important;
		margin: 0;
		padding: 0;
	}

	.b_sj-table__head-fixtop {
		position: relative !important;
	}

    .report-cw .b-matrix-table__body {
	    height: 100%;
    }

	.report-cw .b-matrix-table__cell-checked {
		-webkit-print-color-adjust: exact;
	}

}
/** END PRINTING STYLES **/
