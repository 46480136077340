.ezd-search-input {
  position: relative;
  min-width: 90px;
}
.ezd-search-input .ezd-search-input-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  height: 24px;
  padding: 4px 8px;
  border-radius: 2px;
  border: 1px solid #b3b3b3;
  outline: none;
}
.ezd-search-input .ezd-search-input-header input {
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  border: none;
  background: transparent;
}
.ezd-search-input .ezd-search-input-header:focus, .ezd-search-input .ezd-search-input-header:active, .ezd-search-input .ezd-search-input-header:global(.active), .ezd-search-input .ezd-search-input-header:hover {
  border: solid 1px var(--menu-color);
  box-shadow: 0 0 1px 1px var(--menu-color-02);
}
.ezd-search-input .ezd-search-input-header md-icon {
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
}
.ezd-search-input .ezd-search-input-header md-icon[find] {
  margin: 0 8px 0 0;
}
.ezd-search-input .ezd-search-input-header md-icon[clear] {
  margin: 0 0 0 8px;
}
.ezd-search-input .ezd-search-input-header md-icon[clear]:hover {
  cursor: pointer;
}
.ezd-search-input .ezd-search-input-header md-icon[clear] svg {
  fill: currentColor;
}