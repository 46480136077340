@import "~styles/colors";
@import "~styles/mixins";

.map-popup {
	box-sizing: border-box;

	& * {
		box-sizing: border-box;
	}

	header {
		color: $white;
		height: 32px;
		background: $light-gradient;
		font-size: 15px;
		padding-left: 16px;

		& > button.popup__close-btn {
			padding: 0 6px;

			md-icon {
				color: $white;
			}
		}
	}

	main.map-popup-main {
		.popup__content {
			.content__map-viewport {
				height: 300px;
				background-color: $light-grey;
			}

			.content__map-address {
				padding: 32px;

				p.address__hint {
					color: $grey;
				}

				.address__content {
					.content__title {
						min-width: 40px;
						margin-right: 32px;
						color: $grey;
					}

					.content {
						.btn__wrapper {
							position: relative;
							width: 52px;

							button:global(.md-button).content__remove-btn {
								position: absolute;
								left: 6px;
								top: 50%;
								min-width: 40px;
								max-height: 40px;
								padding: 0 6px;
								transform: translateY(-50%);

								md-icon {
									width: 10px;
									height: 10px;
									min-width: 10px;
									min-height: 10px;
									color: $orange;
								}
							}
						}
					}
				}
			}
		}

		.buttons__row {
			padding: 14px 24px;
			border-top: 1px solid $light-border;

			button.btn-save {
				@include ezd-button($orange);
				width: 120px;
				height: 32px;
				color: $white;
			}
			button.btn-cancel {
				@include ezd-button($grey);
				width: 120px;
				height: 32px;
			}
		}
	}
}
