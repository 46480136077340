@import "~styles/colors";

$light-back: #f5f5f5;

.ezd-square-radio-buttons-container {
	display: flex;
	flex-wrap: nowrap;
	background-color: $light-back;
	border-radius: 3px;
	height: 36px;

	.ezd-square-radio-button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 9px 18px;
		border-radius: 3px;

		&:hover {
			cursor: pointer;
		}

		span {
			color: $black;
			font-size: 15px;
		}
	}

	.is-active {
		background-color: var(--color-button-5);

		span {
			color: $white;
		}
	}
}
