//ezd-popup-notification {
//	position: absolute;
//}
.popup_notification {
	display: flex;
	position: absolute;
	width: 300px;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, .1);
	border: 1px solid #ccd6da;
}
.popup_arrow_top, .popup_arrow_bottom {
	position: absolute;
	height: 0;
	width: 0;
	z-index: 70;
	left: 50%;
	margin-left: -5px;
	border: 10px solid transparent;
	border-top: 0;
	border-bottom-color: #fff;
}

.popup_arrow_top {
	top: -8px;
}

.popup_arrow_bottom {
	top: 100%;
	transform: rotate(180deg);
}

.popup_notification_content {
	display: flex;
	width: 300px;
	flex-direction: column;
	margin: 18px;
}


