@import "~styles/mixins";
@import "~styles/colors";

.insert-table-dialog-wrapper {
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	padding: 0 24px;

	.dialog-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 40px;
		font-size: 18px;
		color: $black;
		padding: 12px 0;
		-webkit-border-top-left-radius: 6px;
		-webkit-border-top-right-radius: 6px;
		-moz-border-radius-topleft: 6px;
		-moz-border-radius-topright: 6px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;

		.close-icon {
			background: url("images/icons/removeNew.svg") 0 0 no-repeat;
			background-size: cover;
			width: 15px;
			height: 15px;
			cursor: pointer;
		}
	}

	>div {
		margin-bottom: 15px;

		.param-title {
			font-size: 14px;
			color: $grey;
			width: 150px;
		}

		.input-field {
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
	}



	.form-actions {
		margin: 15px 0;

		.button {
			@include ezd-button($orange-red, "small");
			color: $white;
			margin: 0;
		}
	}
}
