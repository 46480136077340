@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.ezd-confirm {
  width: 400px;
}
.ezd-confirm header {
  padding: 5px 13px;
}
.ezd-confirm header span {
  color: #666;
}
.ezd-confirm header md-icon {
  color: #666;
  cursor: pointer;
  margin: 0;
}
.ezd-confirm header md-icon > svg {
  fill: #666;
}
.ezd-confirm main .main-icon {
  padding: 35px;
}
.ezd-confirm main .main-icon img {
  max-height: 100px;
  max-width: 100px;
  filter: hue-rotate(var(--icon-hue-rotate));
}
.ezd-confirm main .main-header {
  padding: 0 56px;
  padding-bottom: 15px;
  font-size: 24px;
  text-align: center;
}
.ezd-confirm main .main-text {
  padding: 0 56px;
  text-align: center;
}
.ezd-confirm main .main-text .admin-row {
  display: flex;
  text-align: left;
  padding-top: 15px;
}
.ezd-confirm main .controls {
  padding: 32px 0;
}
.ezd-confirm main .controls :global(.md-button).confirm {
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  height: 40px;
  min-width: 112px;
  letter-spacing: normal;
  padding: 0 16px;
  background-blend-mode: overlay;
  color: #fff;
  min-height: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
}
.ezd-confirm main .controls :global(.md-button).confirm md-icon {
  color: #fd6a2a;
}
.ezd-confirm main .controls :global(.md-button).confirm:not(:global(.md-raised)) {
  color: #fd6a2a;
}
.ezd-confirm main .controls :global(.md-button).confirm:global(.md-raised) {
  background-color: #fd6a2a;
}
.ezd-confirm main .controls :global(.md-button).confirm[disabled] {
  background-color: rgba(179, 179, 179, 0.5);
}
.ezd-confirm main .controls :global(.md-button).confirm[disabled]:global(.md-raised) {
  background-color: #b3b3b3;
}
.ezd-confirm main .controls :global(.md-button).confirm:hover:global(.md-raised), .ezd-confirm main .controls :global(.md-button).confirm:active:global(.md-raised), .ezd-confirm main .controls :global(.md-button).confirm:focus:global(.md-raised) {
  filter: brightness(85%);
  background-color: #fd6a2a;
}
.ezd-confirm main .controls :global(.md-button).confirm:hover:global(.md-raised)[disabled], .ezd-confirm main .controls :global(.md-button).confirm:active:global(.md-raised)[disabled], .ezd-confirm main .controls :global(.md-button).confirm:focus:global(.md-raised)[disabled] {
  background-color: #b3b3b3;
}
.ezd-confirm main .controls :global(.md-button).reject {
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  height: 40px;
  min-width: 112px;
  letter-spacing: normal;
  padding: 0 16px;
  background-blend-mode: overlay;
  color: #333;
  min-height: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  box-shadow: none;
}
.ezd-confirm main .controls :global(.md-button).reject md-icon {
  color: #fff;
}
.ezd-confirm main .controls :global(.md-button).reject:not(:global(.md-raised)) {
  color: #fff;
}
.ezd-confirm main .controls :global(.md-button).reject:global(.md-raised) {
  background-color: #fff;
}
.ezd-confirm main .controls :global(.md-button).reject[disabled] {
  background-color: rgba(179, 179, 179, 0.5);
}
.ezd-confirm main .controls :global(.md-button).reject[disabled]:global(.md-raised) {
  background-color: #b3b3b3;
}
.ezd-confirm main .controls :global(.md-button).reject:hover:global(.md-raised), .ezd-confirm main .controls :global(.md-button).reject:active:global(.md-raised), .ezd-confirm main .controls :global(.md-button).reject:focus:global(.md-raised) {
  filter: brightness(85%);
  background-color: #fff;
}
.ezd-confirm main .controls :global(.md-button).reject:hover:global(.md-raised)[disabled], .ezd-confirm main .controls :global(.md-button).reject:active:global(.md-raised)[disabled], .ezd-confirm main .controls :global(.md-button).reject:focus:global(.md-raised)[disabled] {
  background-color: #b3b3b3;
}
.ezd-confirm main .controls .btn-yellow {
  width: 100%;
  display: inline-block;
}
.ezd-confirm main .controls .btn-yellow label, .ezd-confirm main .controls .btn-yellow span {
  width: 100%;
  display: inline-block;
}