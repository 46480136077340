left-menu bdi {
  height: 43px;
  min-height: 43px;
}
left-menu md-icon {
  width: 16px;
  height: 16px;
  min-width: 0;
  min-height: 0;
}

menu-button {
  height: 43px;
  min-height: 43px;
}
menu-button .md-button {
  width: 100%;
  min-width: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  font-weight: bold;
  text-transform: none;
  color: #fff;
}
menu-button .md-button.active {
  background: var(--menu-color) !important;
}
menu-button .md-button:hover:not(:active) {
  background: var(--menu-color-05) !important;
}

.left-menu {
  max-width: 128px;
  background: var(--side-bar-color2);
}
.left-menu section .title {
  display: block;
  font-size: 12px;
  padding: 8px;
  color: #fff;
}
.left-menu section a.left-menu__link {
  display: block;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 2px;
  background-color: #4c626c;
  color: #fff;
  text-decoration: none;
}
.left-menu section a.left-menu__link.left-menu__link-active {
  background-color: var(--link-color);
  color: #333;
}