@import "~styles/colors";
@import "~styles/mixins";

assignments-table-header-cell {
	.wrapper {
		height: 100%;
		width: 100px;
		max-width: 100px;
		background: $light-bg;
		border-right: $light-border 1px solid;

		.content {
			height: 100%;
			padding: 8px;
			box-sizing: border-box;
		}

		.fast-selection {
			position: relative;
			height: 27px;
			border-top: $light-border 1px solid;

			.selection-menu-mask {
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
}

.fast-selection-menu {
	md-menu-item {
		height: 36px !important;
		min-height: 36px !important;
	}
}

