@charset "UTF-8";
/**
	Если применить на md-button с классом md-raised то color будет применен на фон, иначе на цвет текста
	Только для использования в локальных стилях
 */
/**
	Сбросить значения min-height и min-width
*/
/**
	Установить значения width и height
	если не передан $height, оба параметра принимают значение первого аргумента $width
*/
.editor__wrapper {
  padding: 16px;
  min-height: 300px;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}
.editor__wrapper .image__wrapper > img {
  width: auto;
  height: 100%;
}
.editor__wrapper .buttons__wrapper {
  margin-top: 16px;
}
.editor__wrapper .buttons__wrapper button.btn-save {
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  height: 40px;
  min-width: 112px;
  letter-spacing: normal;
  padding: 0 16px;
  background-blend-mode: overlay;
  width: 120px;
  height: 32px;
  color: #fff;
}
.editor__wrapper .buttons__wrapper button.btn-save md-icon {
  color: #fd6a2a;
}
.editor__wrapper .buttons__wrapper button.btn-save:not(:global(.md-raised)) {
  color: #fd6a2a;
}
.editor__wrapper .buttons__wrapper button.btn-save:global(.md-raised) {
  background-color: #fd6a2a;
}
.editor__wrapper .buttons__wrapper button.btn-save[disabled] {
  background-color: rgba(179, 179, 179, 0.5);
}
.editor__wrapper .buttons__wrapper button.btn-save[disabled]:global(.md-raised) {
  background-color: #b3b3b3;
}
.editor__wrapper .buttons__wrapper button.btn-save:hover:global(.md-raised), .editor__wrapper .buttons__wrapper button.btn-save:active:global(.md-raised), .editor__wrapper .buttons__wrapper button.btn-save:focus:global(.md-raised) {
  filter: brightness(85%);
  background-color: #fd6a2a;
}
.editor__wrapper .buttons__wrapper button.btn-save:hover:global(.md-raised)[disabled], .editor__wrapper .buttons__wrapper button.btn-save:active:global(.md-raised)[disabled], .editor__wrapper .buttons__wrapper button.btn-save:focus:global(.md-raised)[disabled] {
  background-color: #b3b3b3;
}
.editor__wrapper .buttons__wrapper button.btn-cancel {
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  height: 40px;
  min-width: 112px;
  letter-spacing: normal;
  padding: 0 16px;
  background-blend-mode: overlay;
  width: 120px;
  height: 32px;
}
.editor__wrapper .buttons__wrapper button.btn-cancel md-icon {
  color: #666;
}
.editor__wrapper .buttons__wrapper button.btn-cancel:not(:global(.md-raised)) {
  color: #666;
}
.editor__wrapper .buttons__wrapper button.btn-cancel:global(.md-raised) {
  background-color: #666;
}
.editor__wrapper .buttons__wrapper button.btn-cancel[disabled] {
  background-color: rgba(179, 179, 179, 0.5);
}
.editor__wrapper .buttons__wrapper button.btn-cancel[disabled]:global(.md-raised) {
  background-color: #b3b3b3;
}
.editor__wrapper .buttons__wrapper button.btn-cancel:hover:global(.md-raised), .editor__wrapper .buttons__wrapper button.btn-cancel:active:global(.md-raised), .editor__wrapper .buttons__wrapper button.btn-cancel:focus:global(.md-raised) {
  filter: brightness(85%);
  background-color: #666;
}
.editor__wrapper .buttons__wrapper button.btn-cancel:hover:global(.md-raised)[disabled], .editor__wrapper .buttons__wrapper button.btn-cancel:active:global(.md-raised)[disabled], .editor__wrapper .buttons__wrapper button.btn-cancel:focus:global(.md-raised)[disabled] {
  background-color: #b3b3b3;
}