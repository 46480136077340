.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #F3F1ED;
  height: 32px;
  color: #666;
  font-size: 12px;
  padding: 0 24px;
}
.breadcrumbs > span {
  flex: 1;
}
.breadcrumbs a {
  color: #666;
}
.breadcrumbs .help-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.breadcrumbs .help-link::after {
  content: "";
  background: url("images/icons/question_mark.svg") no-repeat;
  width: 14px;
  height: 14px;
  margin-left: 8px;
}