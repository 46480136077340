@import "~styles/colors";

.ezd-autocomplete {
	position: relative;
	min-width: 90px;

	.ezd-autocomplete-header {
		display: flex;
		flex-direction: row;
		align-items: center;

		background: $white;
		height: 24px;

		padding: 4px 8px;

		border-radius: 2px;
		border: 1px solid $disabled-border;
		box-shadow: inset 0 1px 2px 0 rgba(57, 103, 109, 0.2);

		outline: none;

		&.disabled {
			background: #ddd;
		}

		&.invalid-model {
			border: solid 1px #ff4848;
		}

		input {
			width: 100%;
			font-size: 15px;
			font-weight: 400;
			line-height: 21px;

			border: none;
			background: transparent;
		}

		&:not(.disabled) {
			&:focus, &:active, &:global(.active), &:hover {
				border: solid 1px var(--menu-color);
				box-shadow: 0 0 1px 1px var(--menu-color-02);
			}
		}


		md-icon {
			height: 16px;
			width: 16px;
			min-height: 16px;
			min-width: 16px;

			&[find] {
				margin: 0 8px 0 0;
			}

			&[clear] {
				margin: 0 0 0 8px;

				&:hover {
					cursor: pointer;
				}

				svg {
					fill: currentColor;
				}
			}
		}
	}

	.ezd-autocomplete-options-list {
		position: absolute;
		top: 100%;
		right: 0;
		padding: 8px 0;
		z-index: 2;
		border: 1px solid $light-border;

		width: 100%;

		background: $white;

		min-height: 150px;
		max-height: 400px;
		overflow: auto;

		:global(.item) {
			display: flex;
			align-items: center;

			cursor: pointer;

			min-height: 24px;
			font-size: 14px;
			font-weight: 400;
			padding: 0 8px;
			margin: 8px 0;

			&:hover {
				background: $light-bg;
			}
		}

	}
}

