.ezd-multiple-select .ezd-multiple-select__search {
  position: relative;
  width: 100%;
}
.ezd-multiple-select .ezd-multiple-select__search > label {
  position: absolute;
  top: 50%;
  left: 12px;
  display: block;
  width: 16px;
  height: 16px;
  background-image: url("./icons/serch_icon.svg");
  background-repeat: no-repeat;
  transform: translateY(-50%);
}
.ezd-multiple-select .ezd-multiple-select__search input:global(.ezd-input) {
  width: 100%;
  padding-left: 40px;
}
.ezd-multiple-select .ezd-multiple-select__list-wrapper {
  max-height: 610px;
  min-height: 250px;
  margin-top: -1px;
  padding: 16px;
  border: 1px solid #cccccc;
  border-top: none;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ezd-multiple-select .ezd-multiple-select__list-wrapper .ezd-multiple-select__list {
  height: 100%;
}
.ezd-multiple-select .ezd-multiple-select__list-wrapper .ezd-multiple-select__list .repeated-item {
  box-sizing: border-box;
  margin-bottom: 10px;
}
.ezd-multiple-select .ezd-multiple-select__list-wrapper .ezd-multiple-select__list::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}
.ezd-multiple-select .ezd-multiple-select__list-wrapper .ezd-multiple-select__list ezd-checkbox {
  padding: 0;
}
.ezd-multiple-select .ezd-multiple-select__list-wrapper .ezd-multiple-select__list ezd-checkbox > div {
  display: block;
}
.ezd-multiple-select .ezd-multiple-select__list-wrapper .ezd-multiple-select__list ezd-checkbox > div input[type=checkbox] + label span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ezd-multiple-select .ezd-multiple-select__list-wrapper .ezd-multiple-select__list ezd-checkbox:not(:first-child) > div {
  margin-top: 8px;
}
.ezd-multiple-select .ezd-multiple-select__list-wrapper .ezd-multiple-select__list ezd-checkbox label {
  font-size: 12px;
}

.check-all {
  padding-left: 17px;
  padding-top: 8px;
  padding-right: 17px;
  margin-bottom: -40px;
  z-index: 1;
}
.check-all ezd-checkbox {
  padding: 0;
  font-size: 12px;
}