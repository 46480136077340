.right-sidebar {
    position: absolute;
    top: 0;
    right: -170px;
    /*-webkit-transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);*/
    /*transition:         all 600ms cubic-bezier(0.23, 1, 0.32, 1);*/
    width: 200px;
}

.right-sidebar-wrapper.open .right-sidebar {
    right : 5px;
}

.right-sidebar-wrapper {
    position: relative;
    height: 100%;
    min-height: 100%;
    width: 30px;
    background-color: #fafafa;
    padding: 0 5px;
}

.right-sidebar-wrapper.open {
    width: 200px;
}

.toggle-button {
    cursor: pointer;
    margin: 20px 0;
}

.right-sidebar-wrapper.open,
.md-toolbar-tools {
    background: #546f7a !important;
    color: #fff;
}

.md-toolbar-tools {
    padding: 20px 5px;
}

.md-toolbar-tools md-icon {
    cursor: pointer;
}

.title {
    font-size: 15px;
    color: #d9e0e3;
}

.right-sidebar md-input-container {
    width: 187px;
}