@import "~styles/colors";

.ezd-search-input {
	position: relative;
	min-width: 90px;

	.ezd-search-input-header {
		display: flex;
		flex-direction: row;
		align-items: center;

		background: $white;
		height: 24px;

		padding: 4px 8px;

		border-radius: 2px;
		border: 1px solid #b3b3b3;

		outline: none;

		input {
			width: 100%;
			font-size: 15px;
			font-weight: 400;
			line-height: 21px;

			border: none;
			background: transparent;
		}

		&:focus, &:active, &:global(.active), &:hover {
			border: solid 1px var(--menu-color);
			box-shadow: 0 0 1px 1px var(--menu-color-02);
		}

		md-icon {
			height: 16px;
			width: 16px;
			min-height: 16px;
			min-width: 16px;

			&[find] {
				margin: 0 8px 0 0;
			}

			&[clear] {
				margin: 0 0 0 8px;

				&:hover {
					cursor: pointer;
				}

				svg {
					fill: currentColor;
				}
			}
		}
	}
}

