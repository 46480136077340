@charset "UTF-8";
top-menu md-toolbar.md-header-theme:not(.md-menu-toolbar) {
  background-color: var(--menu-color);
}
top-menu md-menu-content._md-menu-bar-menu.md-dense .md-menu > .md-button {
  background: rgba(238, 238, 238, 0.1) !important;
  height: 100%;
  padding: 0 16px 0 24px;
}
top-menu md-menu-content._md-menu-bar-menu.md-dense .md-menu > .md-button span {
  color: #5f7278;
}

.menu-link {
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
}

.md-ink-ripple {
  padding-top: 0 !important;
}

md-menu-item > .md-button span,
md-menu-item ._md-nested-menu span {
  line-height: 32px;
  font-size: 14px;
}

.md-menu-bar-menu.md-dense.md-header-theme {
  padding: 0;
}
.md-menu-bar-menu.md-dense.md-header-theme md-menu-item .md-button {
  padding: 0 16px 0 24px;
}

md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
  content: "\203A";
  transform: none;
  font-size: 24px;
  line-height: 32px;
}